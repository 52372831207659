import { toggleConfirmBox } from "lib/store/slice";
import { useDispatch, useSelector } from "react-redux";

let func = function () {};
let id = null;

export default function useConfirmBox() {
  const confirmBox = useSelector((state) => state.generalReducer.confirmBox);
  const dispatch = useDispatch();
  function closeConfirmBox() {
    dispatch(toggleConfirmBox({ close: true }));
    func = function () {};
  }

  function openConfirmBox(callback, content = "", itemId = null) {
    dispatch(toggleConfirmBox({ confirmBox: { content, show: true } }));
    func = callback;
    id = itemId;
  }

  return {
    open: confirmBox.show,
    openConfirmBox,
    closeConfirmBox,
    onConfirm: func,
    content: confirmBox.content,
    id,
  };
}
