import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getData } from "lib/fetcher";
const { useQuery } = require("react-query");

const DeliveryBox = ({ open, closeBox, deliveryMan, setDeliveryMan }) => {
  const { data: deliMenData, isLoading: deliMenLoading } = useQuery(
    "delivery-men",
    () => getData(`/admin-app/delivery-men`),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Modal
      open={open}
      onClose={closeBox}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",

          width: "100%",
          maxWidth: "50rem",
          maxHeight: "90vh",
          overflow: "hidden",
          outline: "none",
          borderRadius: "0.5rem",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <MDTypography id="Are you sure ?" color="dark" variant="h4">
          Please Select delivery Man
        </MDTypography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "20px",
            maxHeight: "400px",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {deliMenLoading ? (
            <MDTypography id="Are you sure ?" color="dark" variant="h4">
              Loading ...
            </MDTypography>
          ) : (
            <>
              {deliMenData?.result?.data.map((item) => {
                return (
                  <Box
                    sx={{
                      width: "20%",
                      paddingY: 2,
                      paddingX: 2,

                      minWidth: "150px",
                      position: "relative",
                    }}
                    onClick={() => {
                      setDeliveryMan((el) => {
                        if (el === item.id) {
                          return null;
                        }
                        return item.id;
                      });
                    }}
                  >
                    {deliveryMan === item.id && (
                      <CheckCircleIcon
                        sx={{
                          color: "#F59613",
                          fontSize: "30px",
                          position: "absolute",
                          top: 10,
                          right: 20,
                        }}
                      />
                    )}

                    <Box
                      sx={{
                        borderRadius: "20px",
                        overflow: "hidden",

                        width: "100%",
                        height: "150px",
                        border: deliveryMan === item.id ? "2px solid #F59613" : "1px solid #ccc",
                      }}
                    >
                      <Box sx={{ width: "100%", height: "80%", backgroundColor: "black" }}>
                        <img
                          style={{ width: "100%", height: "100", display: "block" }}
                          src="https://www.pngitem.com/pimgs/m/4-42408_vector-art-design-men-fashion-vector-art-illustration.png"
                        />
                      </Box>

                      <div
                        style={{
                          height: "20%",
                          fontSize: "15px",
                          backgroundColor: item.status === "DELIVERY_IS_FREE" ? "green" : "red",
                          color: "white",
                        }}
                      >
                        {item.status === "DELIVERY_IS_FREE" ? "free" : "busy"}
                      </div>
                    </Box>
                    <Typography sx={{ fontSize: "15px" }}>
                      {item.id} - {item.name}
                    </Typography>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
        <Box sx={{ marginTop: "50px" }}>
          <button
            style={{
              backgroundColor: "#ccc",
              border: "none",
              padding: "10px 20px",
              width: "30%",
              color: "white",
              borderRadius: "5px",

              cursor: "pointer",
            }}
            onClick={() => {
              setDeliveryMan(null);
              closeBox();
            }}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "#F59613",
              border: "none",
              padding: "10px 20px",
              width: "30%",
              color: "white",
              borderRadius: "5px",
              cursor: "pointer",
              marginLeft: "20px",
            }}
            onClick={closeBox}
          >
            Confirm
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeliveryBox;
