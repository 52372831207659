import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Avatar from "assets/images/team-4.jpg";
import MDAvatar from "components/MDAvatar";
// Data
import MDButton from "components/MDButton";
import {
  Box,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { getCurrentDate } from "lib/helper";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import { deleteData } from "lib/fetcher";
import { useState } from "react";
import { formatDate } from "lib/helper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ChangeMuiDate } from "lib/helper";
import { useTranslation } from "react-i18next";
import MDInput from "components/MDInput";
import MDSearch from "components/MDSearch";

function OfficeOrderList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();
  const [selectedDate, setSelectedDate] = useState(null);
  const [status, setStatus] = useState("PENDING");
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [orderFrom, setOrderFrom] = useState("all");
  const [paymentMethod, setPaymentMethod] = useState("all");
  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };
  const { data, isLoading, isError } = useQuery(
    ["give-way-orders", { selectedDate, status, page, searchValue, orderFrom, paymentMethod }],
    () =>
      getData(
        `/admin-app/give-way-orders?date=${
          selectedDate ? selectedDate : getCurrentDate()
        }&filter=${status}&page=${page ? page : 1}${searchValue ? "&search=" + searchValue : ""}${
          orderFrom !== "all" ? "&order_from_id=" + orderFrom : ""
        }${paymentMethod !== "all" ? "&payment_method_id=" + paymentMethod : ""}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: orderFromData, isLoading: orderFromLoading } = useQuery(
    "order-froms",
    () => getData(`/admin-app/order-froms`),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log(data);

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("admin-app/give-way-orders/" + id);
      queryClient.invalidateQueries("give-way-orders");
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this give way.', id);
  };

  const handleDateChange = (e) => {
    setSelectedDate(ChangeMuiDate(e));
  };
  const handleChange = (event, value) => {
    console.log(value);
    setPage(value);
  };
  // case  = 5;
  // case DELIVERY_PICKING_UP = 6;
  // case DELIVERY_DONE = 7;

  const ORDER_STATUS = [
    { value: "PENDING", label: "Pending" },
    { value: "DELIVERY_ACCEPTED", label: "Delivery Acceted" },
    { value: "DELIVERY_DONE", label: "Delivery Done" },
    { value: "PAYMENT_UNDONE", label: "Payment Undone" },
    { value: "PAYMENT_DONE", label: "Payment Done" },
    { value: "PAYMENT_ERROR", label: "Payment Error" },
  ];
  const columns = [
    { Header: "order id", accessor: "order_id", align: "left" },
    { Header: "delivery price", accessor: "delivery_price", align: "left" },
    { Header: "order from", accessor: "order_from", align: "left" },
    { Header: "delivery man", accessor: "delivery_man_id", align: "center" },
    { Header: "pickup name", accessor: "pick_up_name", align: "center" },
    { Header: "drop off name", accessor: "drop_off_name", align: "left" },
    { Header: "Order Date", accessor: "order_date", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const { data: paymentMethodData, isLoading: paymentMethodLoading } = useQuery(
    "payment-methods",
    () => getData(`/admin-app/payment-methods`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const rows = [];

  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const DeliMan = ({ name, phone }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {name && <MDAvatar src={Avatar} name={name} size="sm" />}

      <MDBox ml={2} lineHeight={1}>
        <MDTypography
          display="block"
          style={{
            color: !name ? "red" : "black",
          }}
          variant="button"
          fontWeight="medium"
        >
          {name || "No Delivery Man"}
        </MDTypography>
        <MDTypography variant="caption">{phone}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        variant="gradient"
        style={{ marginRight: "2px", marginLeft: "2px" }}
        color="info"
        iconOnly
        onClick={() => {
          navigate(`/office-order/${value?.id}`);
        }}
      >
        <VisibilityIcon />
      </MDButton>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          navigate(`/office-order/update`, { state: value });
        }}
        iconOnly
      >
        <Icon>{t("edit")}</Icon>
      </MDButton>
      {/* <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          navigate(`/office-order/update`, { state: { ...value, isCopy: true } });
        }}
        iconOnly
      >
        <Icon>{t("copy")}</Icon>
      </MDButton> */}

      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="error"
        iconOnly
        onClick={() => {
          handleDelete(value.id);
        }}
      >
        <Icon>{t("delete")}</Icon>
      </MDButton>
    </MDBox>
  );

  data?.result?.data.map((value) => {
    rows.push({
      order_id: <TableRowField title={value.order_number} />,
      delivery_price: <TableRowField title={"MMK " + value.delivery_price} />,
      order_from: <TableRowField title={value.order_from?.name} />,
      delivery_man_id: (
        <DeliMan name={value.delivery_man?.name} phone={value.delivery_man?.phone} />
      ),
      pick_up_name: <TableRowField title={value.pick_up_name} />,
      drop_off_name: <TableRowField title={value.drop_off_name} />,
      order_date: <TableRowField title={formatDate(value.order_date)} />,
      action: <ActionField value={value} />,
    });
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="light"
              >
                <MDTypography variant="h6" color="white">
                  {t("office_orders")}
                </MDTypography>
                <NavLink key={"giveway-form"} to={"/office-order/create"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;{t("add")}
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                <MDBox mb={2} display="flex" alignItems="center" justifyContent="space-between">
                  <MDBox display="flex" alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        sx={{ m: 2 }}
                        value={selectedDate ? selectedDate : getCurrentDate()}
                        label={t("date")}
                        inputFormat="YYYY-MM-DD"
                        onChange={handleDateChange}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ mx: 2 }} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                    <Box>
                      <MDBox display="flex" alignItems="center">
                        <FormControl>
                          <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                          <Select
                            value={status}
                            onChange={handleOptionChange}
                            sx={{ p: 1 }}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="restaurant_menu_category_id"
                            label="Menu Category"
                          >
                            {ORDER_STATUS.map((option) => (
                              <MenuItem value={option.value} key={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </MDBox>
                    </Box>
                    <Box>
                      <MDBox sx={{ mx: 2 }} display="flex" alignItems="center">
                        <FormControl>
                          <InputLabel id="demo-simple-select-helper-label">Order From</InputLabel>
                          <Select
                            sx={{ p: 1 }}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="order_from"
                            label="Order From"
                            value={orderFrom}
                            onChange={(e) => setOrderFrom(e.target.value)}
                          >
                            <MenuItem value="all">All Order Form</MenuItem>
                            {orderFromData?.result?.data.map((option) => (
                              <MenuItem value={option.id} key={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </MDBox>
                    </Box>

                    <MDBox display="flex" alignItems="center">
                      <FormControl>
                        <InputLabel id="demo-simple-select-helper-label">
                          {"Payment Method"}
                        </InputLabel>
                        <Select
                          sx={{ p: 1 }}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="payment_method"
                          label="Payment Method"
                          value={paymentMethod}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                          <MenuItem value="all">All Payment Method</MenuItem>
                          {paymentMethodData?.result?.data.map((option) => (
                            <MenuItem key={option.id} value={option.id}>{option.provider}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </MDBox>
                  <Box sx={{ p: 2 }}>
                    <MDBox display="flex" alignItems="end">
                      <MDSearch setSearch={setSearchValue} />
                    </MDBox>
                  </Box>
                </MDBox>
                {!isError && (
                  <DataTable
                    table={{
                      columns,
                      rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, float: "right" }}>
          <Pagination
            color="info"
            count={data?.result?.pagination?.last_page}
            onChange={handleChange}
            defaultPage={page}
            variant="outlined"
            sx={{ my: "20px", ml: "auto" }}
          />
        </Box>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OfficeOrderList;
