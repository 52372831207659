import { getCurrentDate } from "lib/helper";
import React from "react";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Button, ButtonGroup, TextField, Typography } from "@mui/material";
const MDDate = ({
  monthFilter,
  setMonthFilter,
  month,
  handleChangeMonthFilter,
  date,
  handleChangeDate,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      <Box sx={{ display: "flex", flexFlow: "row", alignItems: "end" }}>
        <ButtonGroup
          variant="outlined"
          sx={{
            width: "fit-content",

            mr: "20px",
          }}
          aria-label="outlined button group"
        >
          <Button
            sx={{
              borderColor: "primary.main",
              backgroundColor: monthFilter ? "white" : "primary.main",
              color: monthFilter ? "primary.main" : "white",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            onClick={() => setMonthFilter(false)}
          >
            Daily
          </Button>
          <Button
            sx={{
              borderColor: "primary.main",
              backgroundColor: monthFilter ? "primary.main" : "white",
              color: monthFilter ? "white" : "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            onClick={() => setMonthFilter(true)}
          >
            Monthly
          </Button>
        </ButtonGroup>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {monthFilter ? (
            <DatePicker
              views={["year", "month"]}
              label="Select Month"
              minDate={new Date("2012-03-01")}
              maxDate={new Date("2025-06-01")}
              value={month}
              onChange={handleChangeMonthFilter}
              renderInput={(params) => <TextField {...params} sx={{ mx: 2 }} size="small" />}
            />
          ) : (
            <DatePicker
              value={date ? date : getCurrentDate()}
              label="Select Date"
              inputFormat="YYYY-MM-DD"
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} sx={{ mx: 2 }} size="small" />}
            />
          )}
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default MDDate;
