import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAlertBox from "lib/hooks/useAlertBox";
import { Icon, IconButton, Stack, styled } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "lib/firebase";
const bannerIDs = ["banner-1", "banner-2", "banner-3"];
const BannerContainer = styled("figure")`
  width: 100%;
  aspect-ratio: 16 / 6;
  border: solid 1px #606060;
  border-style: dashed;
  padding-block: 1rem;
  padding-inline: 7rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    aspect-ratio: 16 / 6;
    object-fit: contain;
  }
  .buttons {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

export default function Banners() {
  const { openAlertBox } = useAlertBox();
  const [banners, setBanners] = useState([]);

  async function getBanners() {
    try {
      const q = query(collection(db, "banners"), orderBy("order", "asc"));
      const res = await getDocs(q);
      const arr = [];
      res.forEach((doc) => arr.push(doc.data()));
      setBanners(arr);
    } catch (error) {
      openAlertBox("error", error.message || "Unexpected error");
    }
  }
  useEffect(() => {
    getBanners();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Stack gap={"1rem"}>
          {bannerIDs.map((id, index) => (
            <Banner
              bannerID={id}
              key={id}
              order={index + 1}
              banner={banners[index]}
              refetch={() => getBanners()}
            />
          ))}
        </Stack>
      </MDBox>
    </DashboardLayout>
  );
}

function Banner({ bannerID, banner, refetch, order }) {
  const { openAlertBox } = useAlertBox();
  const [tempImage, setTempImage] = useState(null);

  async function doBannerUpload() {
    try {
      const imgRef = ref(storage, `banners/${bannerID}`);
      await uploadBytes(imgRef, tempImage);
      const url = await getDownloadURL(imgRef);
      return url;
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }
  async function uploadBanner() {
    try {
      const url = await doBannerUpload();
      const body = {
        url,
        id: bannerID,
        order,
      };
      await setDoc(doc(db, "banners", bannerID), body);
      openAlertBox("success", "Banner uploaded successfully");
      setTempImage(null);
      refetch();
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }

  async function deleteBanner() {
    try {
      await deleteDoc(doc(db, "banners", bannerID));
      openAlertBox("success", "Banner deleted successfully");
      setTempImage(null);
      refetch();
    } catch (error) {
      openAlertBox("error", error.message || "Deleting failed");
    }
  }
  return (
    <BannerContainer>
      <label htmlFor={bannerID}>
        {tempImage ? (
          <img src={URL.createObjectURL(tempImage)} alt="" />
        ) : banner ? (
          <img src={banner?.url} alt="" />
        ) : (
          "Upload banner"
        )}
        <input
          id={bannerID}
          type="file"
          accept="image/*"
          hidden
          onChange={(e) => setTempImage(e.target.files[0])}
        />
      </label>
      <div className="buttons">
        {!tempImage && banner ? (
          <IconButton color="error" onClick={deleteBanner}>
            <DeleteIcon />
          </IconButton>
        ) : tempImage ? (
          <>
            <IconButton color="warning" onClick={() => setTempImage(null)}>
              <CloseIcon />
            </IconButton>
            <IconButton color="success" onClick={uploadBanner}>
              <CheckIcon />
            </IconButton>
          </>
        ) : (
          ""
        )}
      </div>
    </BannerContainer>
  );
}
