import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Autocomplete, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { postData } from "lib/fetcher";
import { getErrorMessage } from "lib/helper";
import useAlertBox from "lib/hooks/useAlertBox";
import { getData } from "lib/fetcher";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const initalData = {
  from_place_id: "",
  to_place_id: "",
  price: "",
};
function PlaceAndPriceForm() {
  const { state } = useLocation();
  console.log(state);
  const [data, setData] = React.useState(state ? state : initalData);
  console.log("initial data", data);
  const navigate = useNavigate();
  const { openAlertBox } = useAlertBox();
  const [page, setPage] = useState(1);
  // const [placesOption, setPlacesOption] = React.useState([]);
  // const { data: wardData } = useQuery(
  //   ["wards", page],
  //   () => getData(`/admin-app/wards?page=${page}`),
  //   {
  //     refetchOnWindowFocus: false,
  //     onSuccess: (data) => {
  //       setWard((prev) => {
  //         return [...prev, ...data?.result?.data];
  //       });
  //       if (data?.result?.pagination?.last_page !== page) {
  //         setPage((prev) => prev + 1);
  //       }
  //     },
  //   }
  // );
  const [places, setPlaces] = useState([]);
  const { data: g, isLoading: placesLoading } = useQuery(
    ["places", page],
    () => getData(`/admin-app/places?page=${page}`),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setPlaces((prev) => {
          return [...prev, ...data?.result?.data];
        });
        if (data?.result?.pagination?.last_page !== page) {
          setPage((prev) => prev + 1);
        }
      },
    }
  );

  const placesOption = places?.map((el) => ({
    label: el.name,
    value: el.id,
  }));
  console.log(placesOption);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (e) => {
      e.preventDefault();
      let formData = new FormData();
      formData.append("from_place_id", +data.from_place_id);
      formData.append("to_place_id", +data.to_place_id);
      formData.append("price", +data.price);
      let res;
      console.log(Object.fromEntries(formData));
      console.log(data);
      if (state?.id) {
        formData.append("_method", "put");
        console.log("updating...");
        console.log(Object.fromEntries(formData));

        res = await postData(`admin-app/place-and-prices/${state?.id}`, formData);
        return res;
      } else {
        res = await postData("admin-app/place-and-prices/", formData);
      }
      return res;
    },
    onSuccess: () => {
      openAlertBox(
        "success",
        "Success",
        `${state?.id ? "Updated Successfully" : "A new place and price has successfully created"}`
      );
      navigate("/place-and-prices");
    },
    onError: (error) => {
      openAlertBox("error", "Error", getErrorMessage(error.message));
    },
  });

  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ p: 3, mb: 2 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state ? `${t("pap")} ${t("edit")}` : `${t("pap")} ${t("add")}`}
        </Typography>
        <Grid container spacing={3} component="form" onSubmit={mutate}>
          <Grid item xs={4} sx={{ p: 2 }}>
            <MDBox display="flex" alignItems="center" mb={2}>
              {placesOption ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={placesOption}
                  value={
                    placesOption[placesOption.findIndex((p) => p.value === data.from_place_id)]
                  }
                  onChange={(e, newValue) => {
                    setData((prev) => {
                      return { ...prev, from_place_id: newValue.value };
                    });
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label={t("from")} />}
                />
              ) : (
                <></>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              {placesOption ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={placesOption}
                  value={placesOption[placesOption.findIndex((p) => p.value === data.to_place_id)]}
                  onChange={(e, newValue) => {
                    setData((prev) => {
                      return { ...prev, to_place_id: newValue.value };
                    });
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label={t("to")} />}
                />
              ) : (
                <></>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="price"
                defaultValue={data.price}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, price: e.target.value };
                  })
                }
                type="number"
                label={t("Price")}
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sx={{ p: 2 }}>
            <MDBox sx={{ width: 300, float: "left" }} mb={1}>
              <MDButton
                type="submit"
                // disabled={isLoading}
                variant="gradient"
                color="primary"
                fullWidth
              >
                {isLoading ? "Submiting..." : t("submit")}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default PlaceAndPriceForm;
