import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { getData } from "lib/fetcher";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import DeliveryManMarker from "./DeliveryManMarker";
import RestaurantMarker from "./RestaurantMarker";
import RestaurantIcon from "@mui/icons-material/Restaurant";
function MapContainer(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC-FyYPjARri7Anb6C7zHktw4VyClJBY1A",
  });
  const {
    data: men,
    isLoading,
    error,
    isError,
  } = useQuery("delivery-men", () => getData("admin-app/delivery-men"), {
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  });
  const [mapRef, setMapRef] = useState(null);
  const {
    data: places,
    isLoading: isPlacesLoading,
    isError: isPlacesError,
  } = useQuery("places", () => getData(`/admin-app/places`), {
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  });
  const [currentLocation, setCurrentLocation] = useState({
    lat: 22.957076330056516,
    lng: 97.7614830516957,
  });
  const onLoad = React.useCallback(
    function callback(map) {
      setMapRef(map);
      const bounds = new window.google.maps.LatLngBounds();
      places?.result?.data?.forEach(({ latitude, longitude }) =>
        bounds.extend({ lat: latitude, lng: longitude })
      );

      map.fitBounds(bounds);
    },
    [places?.result?.data]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMapRef(null);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState({});

  const handleMarkerClick = (id, lat, lng, address, phone) => {
    setInfoWindowData({ id, address, phone });
    setIsOpen(true);
  };

  return (
    <DashboardLayout>
      <div style={{ height: "90vh", width: "100%" }}>
        {places?.result?.data?.length > 0 && men?.result?.data?.length > 0 && isLoaded && (
          <GoogleMap
            mapContainerStyle={{ height: "100%", width: "100%" }}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {places?.result?.data?.length > 0
              ? places?.result?.data?.map((index, place) => (
                  <div key={index}>
                    <Marker
                      position={{ lat: place?.latitude, lng: place?.longitude }}
                      icon={"/restaurant.svg  "}
                      onClick={() => {
                        handleMarkerClick(
                          place.id,
                          place?.latitude,
                          place?.longitude,
                          place?.name,
                          place?.detail
                        );
                      }}
                    >
                      {isOpen && infoWindowData?.id === place.id && (
                        <InfoWindow
                          onCloseClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          <div>
                            <h3>{infoWindowData.address}</h3>
                            <p> {infoWindowData?.phone}</p>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  </div>
                  // <RestaurantMarker lat={place?.latitude} lng={place?.longitude} place={place} />
                ))
              : ""}
            {men?.result?.data?.length > 0
              ? men?.result?.data?.map((man) => (
                  <Marker
                    position={{ lat: man?.latitude, lng: man?.longitude }}
                    icon={"/delivery.svg"}
                    onClick={() => {
                      handleMarkerClick(
                        man.id,
                        man?.latitude,
                        man?.longitude,
                        man?.name,
                        man?.phone
                      );
                    }}
                  >
                    {isOpen && infoWindowData?.id === man.id && (
                      <InfoWindow
                        onCloseClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <div>
                          <h3>{infoWindowData.address}</h3>
                          <p>Phone: {infoWindowData?.phone}</p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))
              : ""}
          </GoogleMap>
        )}
      </div>
    </DashboardLayout>
  );
}

export default MapContainer;
{
}
// </GoogleMap>
