export const getCurrentDate = () => {
  let d = new Date();
  return [
    d.getFullYear(),
    ("0" + (d.getMonth() + 1)).slice(-2),
    ("0" + d.getDate()).slice(-2),
  ].join("-");
};
export function convertToPhoneDigit(inputString) {
  let result = inputString;
  if (/^09/.test(inputString)) {
    result = inputString.replace(/^09/, "959");
    console.log(result);
    return result;
  }
  return result;
}
export const getPreviousDate = () => {
  var today = new Date();
  var yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  var year = yesterday.getFullYear();
  var month = yesterday.getMonth() + 1;
  var date = yesterday.getDate();

  var formattedMonth = month < 10 ? "0" + month : month;
  var formattedDate = date < 10 ? "0" + date : date;
  return year + "-" + formattedMonth + "-" + formattedDate;
};
export function getErrorMessage(error) {
  const err = JSON.parse(error);
  return err.message;
}

export const formatDate = (date) => {
  let d = new Date(date);
  return [
    d.getFullYear(),
    ("0" + (d.getMonth() + 1)).slice(-2),
    ("0" + d.getDate()).slice(-2),
  ].join("-");
};
export const ChangeMuiDate = (e) => {
  let date = new Date(e.$d);
  let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
  let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  let payload = year + "-" + month + "-" + day;
  return payload;
};
