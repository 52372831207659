// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { postData, getData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentDate } from "lib/helper";
import input from "assets/theme/components/form/input";
import { format } from "date-fns";
import { formatDate } from "lib/helper";

const initalData = {
  delivery_men_id: "",
  usage_for: "",
  date: "",
  value: "",
  reason: "",
};
// case PHONE_BILL = 0;
// case PRE_ADVANCE = 1;
// case GAS = 2;
// case FINE = 3;
// case BONUS = 4;
const USAGE_FOR = [
  {
    key: "Phone Bill",
    value: "PHONE_BILL",
  },
  {
    key: "Pre Advance",
    value: "PRE_ADVANCE",
  },
  {
    key: "Gas",
    value: "GAS",
  },
  {
    key: "Fine",
    value: "FINE",
  },
  {
    key: "Bonus",
    value: "BONUS",
  },
];

const DeliveryManUsageForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log(state);
  const { id } = useParams();
  const { openAlertBox } = useAlertBox();
  const queryClient = useQueryClient();
  const [dateData, setDateData] = useState("");
  const [stateDeliveryMan, setStateDeliveryMan] = useState("");
  const [stateUsageFor, setStateUsageFor] = useState("");

  const [inputValue, setInputValue] = useState(state ? state : initalData);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const { data: deliveries } = useQuery("delivery-men", () => getData("admin-app/delivery-men"), {
    refetchOnWindowFocus: false,
  });
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) =>
      postData(`admin-app/delivery-man-usage/${state?.id}`, {
        ...body,
        _method: "PUT",
      }),
    onSuccess: () => {
      openAlertBox("success", " Success", "successfully ");
      queryClient.invalidateQueries(`delivery-men-usage`);
      navigate(`/delivery-men-usage/${id}`);
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postData(`admin-app/delivery-man-usage`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "successfully ");
      queryClient.invalidateQueries(`delivery-men-usage`);
      navigate(`/delivery-men-usage/${id}`);
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  //   const { mutate, isLoading: updateLoading } = useMutation({
  //     mutationFn: async (body) => {
  //       if (state?.id) {
  //         postData(`admin-app/delivery-man-usage/${state?.id}`, { ...body, _method: "put" });
  //       } else {
  //         // console.log("bd", { ...body })
  //         postData("admin-app/delivery-man-usage", { ...body });
  //       }
  //     },

  //     onSuccess: () => {
  //       openAlertBox("success", " Success", "You have successfully create the Recommends");
  //       queryClient.invalidateQueries("delivery-men");
  //       navigate("/delivery-men-usage");
  //     },
  //     onError: (error) => {
  //       console.log(error, "error");
  //       openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
  //     },
  //   });]
  console.log(inputValue.date.includes(" "), "state?.date.includes(inputValue.date)");
  console.log(inputValue.date.length == 0);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.date === "") {
      inputValue.date = getCurrentDate();
    }
    if (state?.id) {
      updateMutate({
        delivery_men_id: id,
        usage_for: inputValue.usage_for ?? stateUsageFor,
        date: formatDate(inputValue.date) + " 00:00:00",
        value: inputValue.value,
        reason: inputValue.reason,
      });
    } else {
      createMutate({
        delivery_men_id: id,
        usage_for: inputValue.usage_for ?? stateUsageFor,
        date: formatDate(inputValue.date) + " 00:00:00",
        value: inputValue.value,
        reason: inputValue.reason,
      });
    }
  };

  const handleChangeStartDate = (e) => {
    let dates = new Date(e.$d);
    setDateData(dates);
    if (state) {
      let dates = new Date(e.$d);
      let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(dates);
      let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(dates);
      let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(dates);

      let date = new Date();
      let hour = new Intl.DateTimeFormat("en", { hour: "2-digit", hour12: false }).format(date);
      let h = parseInt(hour) < 10 ? `0${hour}` : hour;

      let minute = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(date);
      let m = parseInt(minute) < 10 ? `0${minute}` : minute;

      let second = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(date);
      let s = parseInt(second) < 10 ? `0${second}` : second;

      let payload = year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;
      // console.log("state", payload)
      setInputValue({ ...inputValue, date: payload });
    } else {
      let dates = new Date(e.$d);
      let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(dates);
      let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(dates);
      let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(dates);

      let date = new Date();
      let hour = new Intl.DateTimeFormat("en", { hour: "2-digit", hour12: false }).format(date);
      let h = parseInt(hour) < 10 ? `0${hour}` : hour;
      let minute = new Intl.DateTimeFormat("en", { minute: "2-digit", hour12: true }).format(date);
      let m = parseInt(minute) < 10 ? `0${minute}` : minute;
      let second = new Intl.DateTimeFormat("en", { second: "2-digit", hour12: false }).format(date);
      let s = parseInt(second) < 10 ? `0${second}` : second;

      let payload = year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;
      // console.log("not state", payload)

      setInputValue({ ...inputValue, date: payload });
    }
  };

  const handleChangeDelivery = (event) => {
    setInputValue({ ...inputValue, delivery_men_id: event.target.value });
  };

  const handleChangeUsage = (event) => {
    setInputValue({ ...inputValue, usage_for: event.target.value });
  };

  useEffect(() => {
    setStateDeliveryMan(state?.delivery_man?.id);
    setStateUsageFor(state?.usage_for);
  }, [state]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid
          container
          spacing={6}
          sx={{ display: "flex" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6}>
            <MDBox mb={2}>
              {/* <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label">Select Delivery Man</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inputValue.delivery_men_id ?? stateDeliveryMan}
                                    label="Delivery Man"
                                    onChange={handleChangeDelivery}
                                    sx={{ padding: "13px" }}
                                >
                                    {
                                        !state &&
                                        deliveries?.result?.data?.map((rest) => (
                                            <MenuItem required key={rest.id} value={rest?.id}>{rest?.name}</MenuItem>
                                        ))
                                    }
                                    {
                                        state && <MenuItem required key={state?.delivery_man?.id} value={state?.delivery_man?.id}>{state?.delivery_man?.name}</MenuItem>

                                    }
                                </Select>
                            </FormControl> */}
            </MDBox>

            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Usage Types</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputValue.usage_for ?? stateUsageFor}
                  label="Usage Types"
                  onChange={handleChangeUsage}
                  sx={{ padding: "13px" }}
                >
                  {!state &&
                    USAGE_FOR.map((usage) => {
                      return (
                        <MenuItem required key={usage.key} value={usage.value}>
                          {usage.value}
                        </MenuItem>
                      );
                    })}
                  {state && (
                    <MenuItem required key={state?.usage_for} value={state?.usage_for}>
                      {state?.usage_for}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="YYYY-MM-DD"
                  value={dateData === "" ? state?.date : dateData}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ mr: 2, minWidth: "200px" }} size="small" />
                  )}
                />
                <MDInput
                  value={inputValue.value}
                  onChange={inputChangeHandler}
                  type="text"
                  label="Value"
                  name="value"
                  fullWidth
                  required
                />
              </LocalizationProvider>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDInput
                value={inputValue.reason}
                onChange={inputChangeHandler}
                type="text"
                label="Reason"
                name="reason"
                fullWidth
                required
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                // disabled={isLoading}
                onClick={handleSubmit}
                variant="gradient"
                color="info"
                fullWidth
              >
                {state ? "Updated" : "Create"}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default DeliveryManUsageForm;
