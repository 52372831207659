import { styled } from "@mui/material";

import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";

const Marker = styled("button")`
  position: relative;
  translate: -50% -100%;
  width: 3rem;
  height: 3rem;
  background-color: ${({ theme, isFree }) =>
    isFree ? theme.palette.success.main : theme.palette.error.main};
  color: white;
  border: none;
  border-radius: 50%;
  margin-bottom: 4rem;
  display: grid;
  place-items: center;
  z-index: 2;
  :hover .info {
    opacity: 1;
  }
`;

const Indicator = styled("div")`
  background-color: ${({ theme, isFree }) =>
    isFree ? theme.palette.success.main : theme.palette.error.main};
  position: absolute;
  width: 1rem;
  height: 1rem;
  rotate: 45deg;
  left: 50%;
  translate: -50%;
  bottom: -0.35rem;
  z-index: -1;
`;

const Info = styled("div")`
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 0.5rem);
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  z-index: -1;
  color: black;
  min-width: 10rem;
  border-radius: 5px;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  white-space: nowrap;
  h6 {
    font-size: 1.05rem;
  }
  p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
`;

const DeliveryManMarker = ({ man }) => {
  return (
    <Marker>
      <Info className="info">
        <h6>{man?.name}</h6>
        <p>Phone: {man?.phone}</p>
      </Info>
      <DirectionsBikeIcon
        isFree={man?.status === "DELIVERY_IS_FREE"}
        sx={{ transform: "scale(1.5)" }}
      />
      <Indicator isFree={man?.status === "DELIVERY_IS_FREE"} />
    </Marker>
  );
};

export default DeliveryManMarker;
