// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
// import MDInput from "components/MDInput";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MDButton from "components/MDButton";
import { useNavigate, useLocation, NavLink, useParams } from "react-router-dom";
// import { useMutation } from "react-query";
// import { getData, updateData } from "lib/fetcher";
import MDBadge from "components/MDBadge";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";
import { getData } from "lib/fetcher";
import MDDate from "components/MDDate";

const DeliveryManDetail = () => {
  const [date, setDate] = useState(getCurrentDate());
  const [month, setMonth] = useState(getCurrentDate());
  const [paymentMethod, setPaymentMethod] = useState("all");
  const [monthFilter, setMonthFilter] = useState(false);
  const handleChangeMonthFilter = (newValue) => {
    setMonth(ChangeMuiDate(newValue));
    console.log(ChangeMuiDate(newValue), "newValue");
  };
  const handleChangeDate = (newValue) => {
    setDate(ChangeMuiDate(newValue));
  };
  const { data: paymentMethodData, isLoading: paymentMethodLoading } = useQuery(
    "payment-methods",
    () => getData(`/admin-app/payment-methods`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log(useLocation());
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #fff",
    borderRadius: "20px",
    boxShadow: 10,
    p: 4,
  };
  const { data: DeliveryIncome } = useQuery(
    ["daily-income", monthFilter, month, date, paymentMethod],
    () =>
      getData(
        `/admin-app/office-income-report?date=${monthFilter ? month : date
        }&request_report=DELIVERY_INCOME_TOTAL&filter_time=${monthFilter ? "MONTHLY" : "DAILY"}
        ${paymentMethod !== "all" ? `&payment_method_id=${paymentMethod}` : ""
        }&id_for=DELIVERY_MAN&id=${state?.id}
        `
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState();
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCardClick = (w) => {
    setModalData(w);
    setOpen(true);
  };
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <MDDate
          monthFilter={monthFilter}
          setMonthFilter={setMonthFilter}
          handleChangeMonthFilter={handleChangeMonthFilter}
          handleChangeDate={handleChangeDate}
          date={date}
          month={month}
        />
        <MDBox display="flex" alignItems="center">
          <FormControl sx={{ mx: 2 }}>
            <InputLabel id="demo-simple-select-helper-label">{"Payment Method"}</InputLabel>
            <Select
              sx={{ p: 1.5 }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="payment_method"
              label="Payment Method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <MenuItem value="all">All Payment Method</MenuItem>
              {paymentMethodData?.result?.data.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.provider}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      </Box>
      <MDBox pt={6} pb={3}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ width: "150px" }}>
                Types
              </Typography>
              <Typography variant="h6" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h6" sx={{ width: "250px" }}>
                {modalData?.type}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ width: "150px" }}>
                Order Number
              </Typography>
              <Typography variant="h6" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h6" sx={{ width: "250px" }}>
                {modalData?.order_number}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ width: "150px" }}>
                Order Date
              </Typography>
              <Typography variant="h6" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h6" sx={{ width: "250px" }}>
                {modalData?.order_date}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#171716",
                  color: "white !important",
                  marginTop: "10px",
                  ":hover": { backgroundColor: "#171716", color: "white !important" },
                }}
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Card sx={{ p: 3, backgroundColor: "#f0f7f7" }}>
              <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 100, height: 100, marginBottom: "15px" }}
                />
              </Stack>

              <Grid container spacing={6}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("Name")}{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px" }}>
                      {state?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("Phone")}{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px" }}>
                      +{state?.phone}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("other_phones")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px" }}>
                      {state?.phones[0]}, {state?.phones[1]}, {state?.phones[2]}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("Email")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px" }}>
                      {state?.email}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("status")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px" }}>
                      {<MDBadge badgeContent={state?.status} variant="contained" container />}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("bs")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px" }}>
                      {state?.base_salary}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("ws")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px" }}>
                      {state?.salary_by_way}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={7} sx={{ p: 3 }}>
            <Card sx={{ p: 3, mb: 3, backgroundColor: "#f0f7f7" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
              >
                <Typography variant="h5" sx={{ mb: 4 }}>
                  Way Money
                </Typography>
                <Typography variant="h5" sx={{ mb: 4 }}>
                  -
                </Typography>
                <Typography variant="h5" sx={{ mb: 4 }}>
                  {DeliveryIncome?.result} MMK
                </Typography>
              </Box>
            </Card>
            <Card sx={{ p: 3, marginTop: "10px", backgroundColor: "#f0f7f7" }}>
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                textAlign="center"
              >
                <Typography variant="h5" sx={{ mb: 4 }}>
                  {t("usage")}
                </Typography>
                {/* <NavLink key={"delivery-men-usage"} to={`/delivery-men-usage`}> */}
                {/* <NavLink key={"delivery-men-usage"} to={navigate(`/delivery-men-usage`, { state: state })}> */}
                <MDButton
                  sx={{ mb: 4 }}
                  variant="gradient"
                  color="dark"
                  onClick={() => navigate(`/delivery-men-usage/${state?.id}`)}
                >
                  {t("all_usage")}
                </MDButton>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "100px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("petro")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px", color: "red" }}>
                      {state?.petroleum_usage} MMK
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "100px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("pb")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px", color: "green" }}>
                      {state?.phone_bill_usage} MMK
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginBottom: "20px", marginLeft: "100px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ width: "150px" }}>
                      {t("pa")}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "250px", color: "red" }}>
                      {state?.pre_advance_usage} MMK
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>

            <Card sx={{ p: 3, marginTop: "10px", backgroundColor: "#f0f7f7" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
              >
                <Typography variant="h5" sx={{ mb: 4 }}>
                  {t("rwr")}
                </Typography>
                <Button
                  key={"way-records"}
                  onClick={() => {
                    navigate(`/way-records/${state?.id}`);
                  }}
                >
                  <MDButton sx={{ mb: 4 }} variant="gradient" color="dark">
                    {t("all_records")}
                  </MDButton>
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  {state?.recent_way_records?.map((way) => (
                    <Card
                      key={way?.id}
                      sx={{ margin: "10px", cursor: "pointer", backgroundColor: "#ebf5ee" }}
                      onClick={() => handleCardClick(way)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" sx={{ width: "150px" }}>
                          {way?.type}
                        </Typography>
                        <Typography variant="h6" sx={{ width: "30px" }}>
                          -
                        </Typography>
                        <Typography variant="h6" sx={{ width: "250px" }}>
                          {way?.order_date}
                        </Typography>
                        <ArrowForwardIosIcon
                          color="white"
                          sx={{
                            backgroundColor: "green",
                            borderRadius: "50px",
                            fontSize: "15px !important",
                          }}
                        />
                      </Box>
                    </Card>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default DeliveryManDetail;
