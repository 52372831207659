import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import classes from "./ImageUpload.module.css";
export default function UploadImage({ setUploadImage, title, height, width, mHeight, defaultImg }) {
  const [showimg, setShowImg] = useState(defaultImg);
  console.log("showimg", showimg);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (
      acceptedFiles[0].type === "image/jpg" ||
      acceptedFiles[0].type === "image/jpeg" ||
      acceptedFiles[0].type === "image/png"
    ) {
      const MAX_FILE_SIZE = 1000000;
      console.log("acceptedFiles", acceptedFiles[0].size);
      if (acceptedFiles[0].size > MAX_FILE_SIZE) {
        alert("This is only accept images! Thanks");
        return;
      }
      setUploadImage(acceptedFiles[0]);
      console.log(URL.createObjectURL(acceptedFiles[0]));
      setShowImg(URL.createObjectURL(acceptedFiles[0]));
    } else {
      console.log("khtsacceptedFiles", acceptedFiles[0].type);
      alert("This is only accept images! Thanks");
      return;
    }
  }, []);
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: height,
    // padding: "120px",
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "#525f7f",
    borderStyle: "dashed",
    backgroundColor: "transparent",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
console.log(defaultImg);
  return (
    <>
      <div {...getRootProps({ style })}>
        <input accept="image/png, image/gif, image/jpeg" {...getInputProps()} />
        {showimg === "" || showimg === null ? (
          <>
            <p className={classes["title"]}>{title}</p>
            {/* <img className={classes["banner"]} src={orlogo} alt="" /> */}
          </>
        ) : (
          <img
            className={classes["banner"]}
            style={{
              width: "95%",
              height: mHeight ? "auto" : "140px",
              maxHeight: mHeight,
              overflowY: "scroll",
            }}
            src={showimg}
            alt=""
          />
        )}
        {/* <img className={classes["banner"]} src={showimg} alt="" /> */}
      </div>
    </>
  );
}
