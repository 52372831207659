import { Box, Button, Modal, Stack, Typography, useTheme } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { postData } from "lib/fetcher";
import { getErrorMessage } from "lib/helper";
import useAlertBox from "lib/hooks/useAlertBox";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

export default function UserForm({ open, close, refetch, type, data }) {
  const theme = useTheme();

  const { openAlertBox } = useAlertBox();
  const { mutate, isLoading } = useMutation({
    mutationFn: async (e) => {
      e.preventDefault();
      const body = new FormData();
      body.append("name", e.target.name.value);
      body.append("email", e.target.email.value);
      body.append("phone", e.target.phone.value);
      if (type === "create") body.append("password", e.target.password.value);
      if (type === "edit") body.append("_method", "PUT");
      const res = await postData("admin-app/users/" + (type === "edit" ? data.id : ""), body);
      return res;
    },
    onSuccess: () => {
      refetch();
      if (type === "create")
        openAlertBox("success", "Success", "A new user has successfully created");
      else openAlertBox("success", "Success", `User id ${data.id} has successfully edited`);
      close();
    },
    onError: (error) => {
      openAlertBox("error", "Error", getErrorMessage(error.message));
    },
  });

  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          minHeight: "15rem",
          width: "100%",
          maxWidth: "40rem",
          outline: "none",
          borderRadius: "0.5rem",
          padding: "1rem",
          textAlign: "center",
        }}
        component="form"
        onSubmit={mutate}
      >
        <Typography
          textAlign="left"
          component="h4"
          fontSize="1.1rem"
          color="info"
          fontWeight={600}
          sx={{ marginBottom: "1rem", color: theme.palette.info.main }}
        >
          {type === "create" ? "Create New User" : "Edit User < " + data?.name + " >"}
        </Typography>
        <Stack direction="row" gap={2}>
          <MDInput type="text" name="name" label={t("Name")} fullWidth defaultValue={data?.name} />
          <MDInput
            type="email"
            name="email"
            label={t("Email")}
            fullWidth
            defaultValue={data?.email}
          />
        </Stack>
        <Stack direction="row" gap={2} sx={{ marginTop: "1rem" }}>
          <MDInput
            type="text"
            name="phone"
            label={t("Phone")}
            fullWidth
            defaultValue={data?.phone}
          />
          <MDInput
            type="password"
            name="password"
            label={t("password")}
            fullWidth
            disabled={type === "edit"}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "2rem" }}>
          <Button onClick={close} variant="text" size="medium" color="success">
            {t("cancel")}
          </Button>
          <MDButton
            type="submit"
            disabled={isLoading}
            variant="contained"
            color="info"
            size="medium"
          >
            {isLoading ? "Saving..." : t("submit")}
          </MDButton>
        </Stack>
      </Box>
    </Modal>
  );
}
