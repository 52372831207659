import { useQuery, useQueryClient } from "react-query";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import ControlPointIcon from "@mui/icons-material/ControlPoint";

import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";

import { getData, deleteData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import { getErrorMessage } from "lib/helper";
import useConfirmBox from "lib/hooks/useConfirmBox";
import { TableData, columns } from "./data";
import UserForm from "components/users/UserForm";
import { useState } from "react";
import Pagination from "components/Utils/Pagination";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Users() {
  const { search } = useLocation();
  const [params] = useSearchParams();
  const pageParam = params.get("page");
  const [openForm, setOpenForm] = useState({ open: false, data: {}, type: "create" });
  const queryClient = useQueryClient();

  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();
  const { isLoading, isError, data } = useQuery(
    ["users", pageParam],
    () => getData("admin-app/users" + search),
    {
      onError: (error) => {
        openAlertBox("error", "Error", getErrorMessage(error.message));
      },
      refetchOnWindowFocus: false,
    }
  );

  async function handleUserDeletion(id, name) {
    try {
      await deleteData("admin-app/users/" + id);
      queryClient.invalidateQueries("users");
      openAlertBox(
        "success",
        "Successfully deleted",
        "The user named " + name + " was successfully deleted"
      );
    } catch (error) {
      openAlertBox("error", "Error", getErrorMessage(error.message));
    }
  }

  async function handleUserBan(id, name) {
    try {
      await deleteData("admin-app/users/" + id);
      queryClient.invalidateQueries("users");
      openAlertBox("success", "Banned", "The user named " + name + " was successfully banned");
    } catch (error) {
      openAlertBox("error", "Error", getErrorMessage(error.message));
    }
  }

  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <UserForm
        open={openForm.open}
        data={openForm.data}
        type={openForm.type}
        close={() => setOpenForm({ open: false, data: "null", type: "create" })}
        refetch={() => queryClient.invalidateQueries("users")}
      />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="light"
              >
                <MDTypography variant="h6" color="white">
                  {t("Users")}
                </MDTypography>
                <MDButton
                  onClick={() => setOpenForm({ open: true, data: null, type: "create" })}
                  startIcon={<ControlPointIcon />}
                  variant="gradient"
                  color="dark"
                >
                  {t("add")}
                </MDButton>
              </MDBox>
              <MDBox pt={2}>
                {!isError && (
                  <DataTable
                    table={{
                      columns,
                      rows: isLoading
                        ? TableLoadingSkeleton(columns)
                        : TableData(
                            data?.result?.data,
                            (data) => setOpenForm({ open: true, data, type: "edit" }),
                            (id, name) =>
                              openConfirmBox(
                                () => handleUserDeletion(id, name),
                                'Click "Confirm" to proceed User deletion'
                              ),
                            (id, name) =>
                              openConfirmBox(
                                () => handleUserBan(id, name),
                                `Click "Confirm" to ban ${name}`
                              )
                          ),
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
              {!isError && data?.result && (
                <Pagination totalPageCount={data.result?.pagination?.last_page} />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Users;
