import RestaurantIcon from "@mui/icons-material/Restaurant";
import { styled } from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
const CustomMarker = styled("button")`
  position: relative;
  translate: -50% -100%;
  width: 3rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.palette.warning.main};
  color: white;
  border: none;
  border-radius: 50%;
  margin-bottom: 4rem;
  display: grid;
  place-items: center;
  z-index: 10;
  :hover .info {
    opacity: 1;
  }
`;

const Indicator = styled("div")`
  background-color: ${({ theme }) => theme.palette.warning.main};
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  rotate: 45deg;
  left: 50%;
  translate: -50%;
  bottom: -0.35rem;
  z-index: -1;
`;

const Info = styled("div")`
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 0.5rem);
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  color: black;
  min-width: 10rem;
  border-radius: 5px;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  h6 {
    font-size: 1.05rem;
    white-space: nowrap;
  }
  p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
`;

const RestaurantMarker = ({ place }) => {
  return (
    <CustomMarker>
      <Info className="info">
        <h6>{place?.name}</h6>
        <p>{place?.detail}</p>
      </Info>
      <RestaurantIcon sx={{ transform: "scale(1.5)" }} />
      <Indicator />
    </CustomMarker>
  );
};

export default RestaurantMarker;
