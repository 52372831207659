import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState, useTransition } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { getData } from "lib/fetcher";
import { getCurrentDate } from "lib/helper";
import useAlertBox from "lib/hooks/useAlertBox";
import DeliveryBox from "components/Utils/DeliveryBox";
import { convertToPhoneDigit } from "lib/helper";
import { postImageData } from "lib/fetcher";
import { deleteData } from "lib/fetcher";
import useConfirmBox from "lib/hooks/useConfirmBox";
import { useTranslation } from "react-i18next";
const initalData = {
  delivery_man_id: "",
  delivery_price: "",
  drop_off_address: "",
  drop_off_name: "",
  drop_off_note: "",
  drop_off_phone: "",
  second_drop_off_address: "",
  second_drop_off_name: "",
  second_drop_off_note: "",
  second_drop_off_phone: "",

  third_pick_up_address: "",
  third_pick_up_name: "",
  third_pick_up_phone: "",
  third_pick_up_note: "",

  order_date: "",
  order_from: "",
  payment_method: "",

  pick_up_address: "",
  pick_up_name: "",
  pick_up_note: "",
  pick_up_phone: "",

  second_pick_up_address: "",
  second_pick_up_name: "",
  second_pick_up_note: "",
  second_pick_up_phone: "",

  third_drop_off_address: "",
  third_drop_off_name: "",
  third_drop_off_note: "",
  third_drop_off_phone: "",
};
function GiveWayForm() {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const { openAlertBox } = useAlertBox();
  const closeBox = () => {
    setOpen(false);
  };
  const [images, setImages] = useState([]);
  // useEffect(() => {
  //   if (state?.id) {
  //     state.images?.map((image) => {
  //       setImages((prev) => [...prev, image.image_url]);
  //     });
  //   }
  // }, [state]);

  console.log(state);
  const [deliveryMan, setDeliveryMan] = React.useState(state?.delivery_man?.id);
  const [data, setData] = React.useState(
    state
      ? {
          ...state,
          pick_up_phone: state.pick_up_phone ? state.pick_up_phone : "",
          second_pick_up_phone: state.second_pick_up_phone ? state.second_pick_up_phone : "",
          third_pick_up_phone: state.third_pick_up_phone ? state.third_pick_up_phone : "",
          drop_off_phone: state.drop_off_phone ? state.drop_off_phone : "",
          second_drop_off_phone: state.second_drop_off_phone ? state.second_drop_off_phone : "",
          third_drop_off_phone: state.third_drop_off_phone ? state.third_drop_off_phone : "",
        }
      : initalData
  );
  const { openConfirmBox } = useConfirmBox();
  const navigate = useNavigate();
  const DeleteApiHandler = async (id) => {
    try {
      await deleteData(
        "admin-app/delete-photo?id=" + state.images.filter((image) => image.image_url === id)[0].id
      );
      queryClient.invalidateQueries("give-way-orders");
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
    } catch (error) {
      openAlertBox("error", " Failed", error.message);
    }
  };
  const { data: paymentMethodData, isLoading: paymentMethodLoading } = useQuery(
    "payment-methods",
    () => getData(`/admin-app/payment-methods`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: deliMenData, isLoading: deliMenLoading } = useQuery(
    "delivery-men",
    () => getData(`/admin-app/delivery-men`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: orderFromData, isLoading: orderFromLoading } = useQuery(
    "order-froms",
    () => getData(`/admin-app/order-froms`),
    {
      refetchOnWindowFocus: false,
    }
  );

  console.log("order from ", orderFromData);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (e) => {
      e.preventDefault();
      console.log(images);
      // let formData = new FormData();
      // formData.append("delivery_price", data.delivery_price);
      // formData.append("order_from_id", data.order_from);
      // formData.append("delivery_man_id", deliveryMan);
      // formData.append("pick_up_name", data.pick_up_name);
      // formData.append("pick_up_phone", data.pick_up_phone);
      // formData.append("pick_up_address", data.pick_up_address);
      // formData.append("pick_up_note", data.pick_up_note);
      // formData.append("drop_off_name", data.drop_off_name);
      // formData.append("drop_off_address", data.drop_off_address);
      // formData.append("drop_off_phone", data.drop_off_phone);
      // formData.append("drop_off_note", data.drop_off_note);
      // formData.append("payment_method_id", data.payment_method);
      // formData.append("order_date", getCurrentDate());

      // let formData = {
      //   delivery_price: data.delivery_price,
      //   order_from_id: data.order_from?.id || data.order_from,
      //   delivery_man_id: deliveryMan,
      //   pick_up_name: data.pick_up_name,
      //   pick_up_phone: data.pick_up_phone ? convertToPhoneDigit(data.pick_up_phone) : "",
      //   pick_up_address: data.pick_up_address,
      //   pick_up_note: data.pick_up_note,
      //   second_pick_up_name: data.second_pick_up_name,
      //   second_pick_up_phone: data.second_pick_up_phone
      //     ? convertToPhoneDigit(data.second_pick_up_phone)
      //     : "",
      //   second_pick_up_address: data.second_pick_up_address,
      //   second_pick_up_note: data.second_pick_up_note,
      //   third_pick_up_name: data.third_pick_up_name,
      //   third_pick_up_phone: data.third_pick_up_phone
      //     ? convertToPhoneDigit(data.third_pick_up_phone)
      //     : "",
      //   third_pick_up_address: data.third_pick_up_address,
      //   third_pick_up_note: data.third_pick_up_note,
      //   drop_off_name: data.drop_off_name,
      //   drop_off_address: data.drop_off_address,
      //   drop_off_phone: data.drop_off_phone ? convertToPhoneDigit(data.drop_off_phone) : "",
      //   drop_off_note: data.drop_off_note,
      //   second_drop_off_name: data.second_drop_off_name,
      //   second_drop_off_phone: data.second_drop_off_phone
      //     ? convertToPhoneDigit(data.second_drop_off_phone)
      //     : "",
      //   second_drop_off_address: data.second_drop_off_address,
      //   second_drop_off_note: data.second_drop_off_note,
      //   third_drop_off_name: data.third_drop_off_name,
      //   third_drop_off_phone: data.third_drop_off_phone
      //     ? convertToPhoneDigit(data.third_drop_off_phone)
      //     : "",
      //   third_drop_off_address: data.third_drop_off_address,
      //   third_drop_off_note: data.third_drop_off_note,
      //   payment_method_id: data.payment_method?.id || data.payment_method,
      //   order_date: getCurrentDate(),
      //   number_of_way: data.number_of_way,
      // };

      const formData = new FormData();
      formData.append("delivery_price", data.delivery_price);
      console.log(data.order_from, "data.order_from?.id || data.order_from");
      formData.append("order_from_id", data.order_from?.id || data.order_from);
      formData.append("delivery_man_id", deliveryMan);
      formData.append("pick_up_name", data.pick_up_name);
      formData.append(
        "pick_up_phone",
        data.pick_up_phone ? convertToPhoneDigit(data.pick_up_phone) : ""
      );
      formData.append("pick_up_address", data.pick_up_address);
      formData.append("pick_up_note", data.pick_up_note);
      formData.append("second_pick_up_name", data.second_pick_up_name);
      formData.append(
        "second_pick_up_phone",
        data.second_pick_up_phone ? convertToPhoneDigit(data.second_pick_up_phone) : ""
      );
      formData.append("second_pick_up_address", data.second_pick_up_address);
      formData.append("second_pick_up_note", data.second_pick_up_note);
      formData.append("third_pick_up_name", data.third_pick_up_name);
      formData.append(
        "third_pick_up_phone",
        data.third_pick_up_phone ? convertToPhoneDigit(data.third_pick_up_phone) : ""
      );
      formData.append("third_pick_up_address", data.third_pick_up_address);
      formData.append("third_pick_up_note", data.third_pick_up_note);
      formData.append("drop_off_name", data.drop_off_name);
      formData.append("drop_off_address", data.drop_off_address);
      formData.append(
        "drop_off_phone",
        data.drop_off_phone ? convertToPhoneDigit(data.drop_off_phone) : ""
      );
      formData.append("drop_off_note", data.drop_off_note);
      formData.append("second_drop_off_name", data.second_drop_off_name);
      formData.append(
        "second_drop_off_phone",
        data.second_drop_off_phone ? convertToPhoneDigit(data.second_drop_off_phone) : ""
      );
      formData.append("second_drop_off_address", data.second_drop_off_address);
      formData.append("second_drop_off_note", data.second_drop_off_note);
      formData.append("third_drop_off_name", data.third_drop_off_name);
      formData.append(
        "third_drop_off_phone",
        data.third_drop_off_phone ? convertToPhoneDigit(data.third_drop_off_phone) : ""
      );
      formData.append("third_drop_off_address", data.third_drop_off_address);
      formData.append("third_drop_off_note", data.third_drop_off_note);
      formData.append("payment_method_id", data.payment_method?.id || data.payment_method);
      formData.append("order_date", getCurrentDate());
      formData.append("number_of_way", data.number_of_way);

      for (let i = 0; i < images.length; i++) {
        formData.append("image[]", images[i]);
      }

      let res;
      if (state?.id && !state?.isCopy) {
        console.log("updating...");
        // formData.append("_method", "put");
        // console.log(Object.fromEntries(formData));
        formData.append("_method", "put");
        res = await postImageData(`admin-app/give-way-orders/${state?.id}`, formData);
        return res;
      } else {
        res = await postImageData("admin-app/give-way-orders", formData);
      }
      return res;
    },
    onSuccess: () => {
      openAlertBox("success", "Success", `${state?.id ? " Successfully" : "success"}`);
      navigate("/office-order");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  const handleImageChange = (event) => {
    const fileArray = Array.from(event.target.files);
    const imageArray = fileArray.map((file) => file);

    setImages((prevImages) => [...prevImages, ...imageArray]);
  };

  const handleImageRemove = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ p: 3, mb: 2 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {t("Office Order")}
        </Typography>
        <Grid container spacing={3} component="form" onSubmit={mutate}>
          <Grid item xs={4} sx={{ p: 2 }}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">{t("Payment Method")}</InputLabel>
                <Select
                  sx={{ p: 1.5 }}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="payment_method"
                  defaultValue={data?.payment_method?.id}
                  label="Payment Method"
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, payment_method: e.target.value };
                    })
                  }
                >
                  {paymentMethodData?.result?.data.map((option) => (
                    <MenuItem value={option.id}>{option.provider}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="delivery_price"
                defaultValue={data.delivery_price}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, delivery_price: e.target.value };
                  })
                }
                type="text"
                label={t("Delivery Price")}
                fullWidth
              />
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={2}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">{t("Order From")}</InputLabel>
                <Select
                  sx={{ p: 1.5 }}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="order_from"
                  label="Order From"
                  defaultValue={data?.order_from?.id || ""}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, order_from: e.target.value };
                    })
                  }
                >
                  {orderFromData?.result?.data.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">{t("way")}</InputLabel>
                <Select
                  sx={{ p: 1.5 }}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="order_from"
                  label="Order From"
                  defaultValue={data?.number_of_way || ""}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, number_of_way: e.target.value };
                    })
                  }
                >
                  {[...Array(3)].map((_, index) => (
                    <MenuItem value={index + 1} key={index}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>
            <MDBox display="" mb={2}>
              <Typography>ID: {deliveryMan}</Typography>
              <MDBox sx={{ width: "100%" }} mb={1}>
                <MDButton
                  type="button"
                  onClick={() => setOpen(true)}
                  variant="gradient"
                  color="dark"
                  fullWidth
                >
                  + {t("select_deli")}
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={4} sx={{ p: 2 }} component="form" onSubmit={mutate}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="pick_up_name"
                defaultValue={data.pick_up_name}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, pick_up_name: e.target.value };
                  })
                }
                type="text"
                label={t("pick_up_name")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="pick_up_phone"
                defaultValue={data.pick_up_phone}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, pick_up_phone: e.target.value };
                  })
                }
                type="text"
                label={t("pick_up_phone")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="pick_up_address"
                multiline
                rows={3}
                defaultValue={data.pick_up_address}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, pick_up_address: e.target.value };
                  })
                }
                type="text"
                label={t("pick_up_address")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="pick_up_note"
                multiline
                rows={3}
                defaultValue={data.pick_up_note}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, pick_up_note: e.target.value };
                  })
                }
                type="text"
                label={t("pick_up_note")}
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={4} sx={{ p: 2 }} component="form" onSubmit={mutate}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="drop_off_name"
                defaultValue={data.drop_off_name}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, drop_off_name: e.target.value };
                  })
                }
                type="text"
                label={t("drop_off_name")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="drop_off_phone"
                defaultValue={data.drop_off_phone}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, drop_off_phone: e.target.value };
                  })
                }
                type="text"
                label={t("drop_off_phone")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="drop_off_address"
                multiline
                rows={3}
                defaultValue={data.drop_off_address}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, drop_off_address: e.target.value };
                  })
                }
                type="text"
                label={t("drop_off_address")}
                fullWidth
              />
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="drop_off_note"
                multiline
                rows={3}
                defaultValue={data.drop_off_note}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, drop_off_note: e.target.value };
                  })
                }
                type="text"
                label={t("drop_off_note")}
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={4} sx={{ p: 2 }}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="second_pick_up_name"
                defaultValue={data.second_pick_up_name}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_pick_up_name: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("pick_up_name")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="second_pick_up_phone"
                defaultValue={data.second_pick_up_phone}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_pick_up_phone: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("pick_up_phone")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="second_pick_up_address"
                multiline
                rows={3}
                defaultValue={data.second_pick_up_address}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_pick_up_address: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("pick_up_address")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="dro"
                multiline
                rows={3}
                defaultValue={data.second_pick_up_note}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_pick_up_note: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("pick_up_note")}
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={4} sx={{ p: 2 }} component="form" onSubmit={mutate}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="second_drop_off_name"
                defaultValue={data.second_drop_off_name}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_drop_off_name: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("drop_off_name")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="second_drop_off_phone"
                defaultValue={data.second_drop_off_phone}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_drop_off_phone: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("drop_off_phone")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="second_drop_off_address"
                multiline
                rows={3}
                defaultValue={data.second_drop_off_address}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_drop_off_address: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("drop_off_address")}
                fullWidth
              />
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="second_drop_off_note"
                multiline
                rows={3}
                defaultValue={data.second_drop_off_note}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, second_drop_off_note: e.target.value };
                  })
                }
                type="text"
                label={t("second") + " " + t("drop_off_note")}
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={4} sx={{ p: 2 }}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_pick_up_name"
                defaultValue={data.third_pick_up_name}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_pick_up_name: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("pick_up_name")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_pick_up_phone"
                defaultValue={data.third_pick_up_phone}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_pick_up_phone: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("pick_up_phone")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_pick_up_address"
                multiline
                rows={3}
                defaultValue={data.third_pick_up_address}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_pick_up_address: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("pick_up_address")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_pick_up_note"
                multiline
                rows={3}
                defaultValue={data.third_pick_up_note}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_pick_up_note: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("pick_up_note")}
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={4} sx={{ p: 2 }} component="form" onSubmit={mutate}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_drop_off_name"
                defaultValue={data.third_drop_off_name}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_drop_off_name: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("drop_off_name")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_drop_off_phone"
                defaultValue={data.third_drop_off_phone}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_drop_off_phone: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("drop_off_phone")}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_drop_off_address"
                multiline
                rows={3}
                defaultValue={data.third_drop_off_address}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_drop_off_address: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("drop_off_address")}
                fullWidth
              />
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="third_drop_off_note"
                multiline
                rows={3}
                defaultValue={data.third_drop_off_note}
                onChange={(e) =>
                  setData((prev) => {
                    return { ...prev, third_drop_off_note: e.target.value };
                  })
                }
                type="text"
                label={t("third") + " " + t("drop_off_note")}
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={8} sx={{ p: 2 }} component="form">
            <div>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="contained-button-file">
                    <MDButton variant="contained" color="primary" component="span">
                      {t("add_image")}
                    </MDButton>
                  </label>
                </Grid>
                {images.map((image, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <div style={{ position: "relative" }}>
                      <img
                        src={typeof image === "string" ? image : URL.createObjectURL(image)}
                        alt={`Image ${index + 1}`}
                        width="100%"
                      />
                      <MDButton
                        variant="gradient"
                        color="error"
                        iconOnly
                        onClick={() => {
                          // if (state?.id) {
                          //   openConfirmBox(
                          //     DeleteApiHandler,
                          //     'Clik "Confirm" to delete this photo',
                          //     image
                          //   );
                          // } else {
                          handleImageRemove(index);
                          // }
                        }}
                        style={{ position: "absolute", top: 5, right: 5, color: "white" }}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sx={{ p: 2 }}>
            <MDBox sx={{ width: 300, float: "right" }} mb={1}>
              <MDButton
                type="submit"
                // disabled={isLoading}
                variant="gradient"
                color="primary"
                fullWidth
              >
                {t("submit")}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
      <DeliveryBox
        open={open}
        deliMenData={deliMenData}
        setDeliveryMan={setDeliveryMan}
        deliveryMan={deliveryMan}
        closeBox={closeBox}
      />
      <Footer />
    </DashboardLayout>
  );
}

// const DeliveryBox = ({ open, closeBox, deliMenData, deliveryMan, setDeliveryMan }) => {
//   return (
//     <Modal
//       open={open}
//       onClose={closeBox}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//       sx={{ display: "grid", placeItems: "center" }}
//     >
//       <Box
//         sx={{
//           backgroundColor: "#fff",
//           height: "100%",
//           width: "100%",
//           maxWidth: "50rem",
//           outline: "none",
//           borderRadius: "0.5rem",
//           padding: "1rem",
//           textAlign: "center",
//         }}
//       >
//         <MDTypography id="Are you sure ?" color="dark" variant="h4">
//           Please Select delivery Man
//         </MDTypography>
//         <Box
//           sx={{
//             display: "flex",
//             flexWrap: "wrap",
//             marginTop: "20px",
//           }}
//         >
//           {deliMenData?.result?.data.map((item) => {
//             return (
//               <Box
//                 sx={{
//                   width: "20%",
//                   paddingY: 2,
//                   paddingX: 2,
//                   position: "relative",

//                   cursor: item.status === "DELIVERY_IS_FREE" ? "pointer" : "not-allowed",
//                 }}
//                 height={150}
//                 onClick={() => {
//                   if (item.status === "DELIVERY_IS_FREE") {
//                     setDeliveryMan((el) => {
//                       if (el === item.id) {
//                         return null;
//                       }
//                       closeBox();

//                       return item.id;
//                     });
//                   }
//                 }}
//               >
//                 {deliveryMan === item.id && (
//                   <CheckCircleIcon
//                     sx={{
//                       color: "#F59613",
//                       fontSize: "30px",
//                       position: "absolute",
//                       top: 10,
//                       right: 20,
//                     }}
//                   />
//                 )}

//                 <Box
//                   sx={{
//                     borderRadius: "20px",
//                     overflow: "hidden",

//                     width: "100%",
//                     height: "100%",
//                     border: deliveryMan === item.id ? "2px solid #F59613" : "1px solid #ccc",
//                   }}
//                 >
//                   <Box sx={{ width: "100%", height: "80%", backgroundColor: "black" }}>
//                     <img
//                       style={{ width: "100%", height: "100%", display: "block" }}
//                       src="https://www.pngitem.com/pimgs/m/4-42408_vector-art-design-men-fashion-vector-art-illustration.png"
//                     />
//                   </Box>

//                   <div
//                     style={{
//                       height: "20%",
//                       fontSize: "15px",
//                       backgroundColor: item.status === "DELIVERY_IS_FREE" ? "green" : "red",
//                       color: "white",
//                     }}
//                   >
//                     {item.status === "DELIVERY_IS_FREE" ? "free" : "busy"}
//                   </div>
//                 </Box>
//                 <Typography sx={{ fontSize: "15px" }}>{item.name}</Typography>
//               </Box>
//             );
//           })}
//         </Box>
//       </Box>
//     </Modal>
//   );
// };
export default GiveWayForm;
