// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import {
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import MDDate from "components/MDDate";
import { useEffect, useState } from "react";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";
import MDSearch from "components/MDSearch";
import { useTranslation } from "react-i18next";

function DeliveryMan() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();
  const [search, setSearch] = useState("");
  const [date, setDate] = useState(getCurrentDate());
  const [month, setMonth] = useState(getCurrentDate());
  const [paymentMethod, setPaymentMethod] = useState("all");
  const [monthFilter, setMonthFilter] = useState(false);

  const handleChangeMonthFilter = (newValue) => {
    setMonth(ChangeMuiDate(newValue));
    console.log(ChangeMuiDate(newValue), "newValue");
  };

  const handleChangeDate = (newValue) => {
    setDate(ChangeMuiDate(newValue));
  };


  const {
    data: deliveries,
    error,
    isError,
    isSuccess,
  } = useQuery("delivery-men", () => getData("admin-app/delivery-men"), {
    refetchOnWindowFocus: false,
  });

  const { data: deliveryManWayReport } = useQuery(
    ["delivery-men-way", monthFilter, month, date, search, paymentMethod],
    () =>
      getData(
        `/admin-app/get-delivery-man-way-report?&date=${monthFilter ? month : date}&filter_time=${monthFilter ? "MONTHLY" : "DAILY"
        }${search ? `&search=${search}` : ""}
        ${paymentMethod !== "all" ? `&payment_method_id=${paymentMethod}` : ""}
        `
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: paymentMethodData } = useQuery(
    "payment-methods",
    () => getData(`/admin-app/payment-methods`),
    {
      refetchOnWindowFocus: false,
    }
  );


  const columns = [
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Phone", accessor: "phone", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [];

  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const ActionField = ({ valueId }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="info"
        onClick={() => {
          navigate(`/delivery-men-detail`, { state: valueId });
        }}
        iconOnly
      >
        <VisibilityIcon />
      </MDButton>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          navigate(`/delivery-men-edit`, { state: valueId });
        }}
        iconOnly
      >
        <Icon>edit</Icon>
      </MDButton>
      <MDBox mr={1}>
        <MDButton
          variant="gradient"
          onClick={() => {
            handleDelete(valueId?.id);
          }}
          color="error"
          iconOnly
        >
          <Icon>delete</Icon>
        </MDButton>
      </MDBox>
    </MDBox>
  );

  deliveries?.result?.data?.map((value) => {
    rows.push({
      name: <TableRowField align="left" title={value.name} />,
      email: <TableRowField align="left" title={value.email} />,
      phone: <TableRowField align="left" title={value.phone} />,
      action: <ActionField align="left" valueId={value} />,
    });
  });

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("admin-app/delivery-men/" + id);
      openAlertBox("success", "Delete Success", "You have successfully deleted the Delivery Man");
      queryClient.invalidateQueries("delivery-men");
      queryClient.invalidateQueries("delivery-men-way");
    } catch (error) {
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this Delivery', id);
  };

  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MDSearch search={search} setSearch={setSearch} />
          <MDBox display="flex" alignItems="center">
            <FormControl sx={{ mx: 2, width: 200 }}>
              <InputLabel id="demo-simple-select-helper-label">{"Payment Method"}</InputLabel>
              <Select
                sx={{ p: 1.5 }}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="payment_method"
                label="Payment Method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <MenuItem key="all" value="all">All Payment Method</MenuItem>
                {paymentMethodData?.result?.data.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.provider}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </Box>

        <MDDate
          monthFilter={monthFilter}
          setMonthFilter={setMonthFilter}
          handleChangeMonthFilter={handleChangeMonthFilter}
          handleChangeDate={handleChangeDate}
          date={date}
          month={month}
        />
      </Box>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("all_way")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {deliveryManWayReport?.result?.all_way ?? '-'}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("fo_way")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {deliveryManWayReport?.result?.food_order_way ?? 0}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("gw_deli")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {deliveryManWayReport?.result?.give_way ?? 0}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("pd_way")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {deliveryManWayReport?.result?.parcel_delivery_way ?? 0}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: 2,
              mt: 5,
              width: "100%",
            }}
          >
            <NavLink key={"restaruant-category-form"} to={"/delivery-men-form"}>
              <MDButton variant="gradient" color="dark">
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;{t("add")}
              </MDButton>
            </NavLink>
          </Box>
          <Grid item xs={12}>
            <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid container spacing={4}>
                {
                  deliveryManWayReport?.result?.each_delivery_man_data?.map((item, index) => (
                    <Grid key={index} item xs={4}>
                      <Card sx={{ border: "1px solid #eee", height: "100%" }}>
                        <CardContent
                          sx={{ marginBottom: 0, mt: 2, p: 0, mr: 2, paddingLeft: "25px" }}
                        >
                          <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                            {item.delivery_man_name}
                          </Typography>

                          {/* <Typography variant="h5" sx={{ fontSize: "15px", mb: 1 }}>
                            {item.email}
                          </Typography>
                          <Typography variant="h5" sx={{ fontSize: "15px", mb: 1 }}>
                            {item.phone}
                          </Typography> */}
                          <Divider
                            component="div"
                            sx={{ borderColor: "black", background: "black" }}
                          />
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h5" sx={{ fontSize: "15px" }}>
                              {t("total_way")}
                            </Typography>
                            <Typography variant="h5" sx={{ fontSize: "15px", mr: 2 }}>
                              {item?.total_way}
                            </Typography>
                          </Box>
                          <Divider
                            component="div"
                            sx={{ borderColor: "black", background: "black" }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              // border: "1px solid black",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "15px", textAlign: "center" }}
                              >
                                {t("fo_way")}
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "15px", textAlign: "center" }}
                              >
                                {item?.food_order_way}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "15px", textAlign: "center" }}
                              >
                                {t("gw_deli")}
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "15px", textAlign: "center" }}
                              >
                                {item?.parcel_delivery_way}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "15px", textAlign: "center" }}
                              >
                                {t("pd_way")}
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "15px", textAlign: "center" }}
                              >
                                {item?.give_way}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider
                            component="div"
                            sx={{ borderColor: "black", background: "black" }}
                          />
                        </CardContent>
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDButton
                            style={{ marginRight: "2px", marginLeft: "2px" }}
                            variant="gradient"
                            color="info"
                            onClick={() => {
                              navigate(`/delivery-men-detail`, {
                                state: deliveries?.result?.data.find(
                                  (value) => value.id === item.id
                                ),
                              });
                            }}
                            iconOnly
                          >
                            <VisibilityIcon />
                          </MDButton>
                          <MDButton
                            style={{ marginRight: "2px", marginLeft: "2px" }}
                            variant="gradient"
                            color="warning"
                            onClick={() => {
                              navigate(`/delivery-men-edit`, {
                                state: deliveries?.result?.data.find(
                                  (value) => value.id === item.id
                                ),
                              });
                            }}
                            iconOnly
                          >
                            <Icon>edit</Icon>
                          </MDButton>
                          <MDBox mr={1}>
                            <MDButton
                              variant="gradient"
                              onClick={() => {
                                handleDelete(item?.id);
                              }}
                              color="error"
                              iconOnly
                            >
                              <Icon>delete</Icon>
                            </MDButton>
                          </MDBox>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </MDBox>
            {/* <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  Delivery Man Table
                </MDTypography>
                <NavLink key={"delivery-men-form"} to={"/delivery-men-form"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox> */}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeliveryMan;
