// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
// import dayjs from 'dayjs';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";

const initalData = {
  name: "",
  is_percentage: 0,
  discount_price: "",
  limit: "",
  code: "",
  is_available: 0,
  start_date: "",
  end_date: "",
};

const PromoCodeForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log(state);
  const { openAlertBox } = useAlertBox();
  const queryClient = useQueryClient();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [inputValue, setInputValue] = useState(state ? state : initalData);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const { mutate, isLoading: updateLoading } = useMutation({
    mutationFn: async (body) => {
      console.log(body);
      if (state?.id) {
        // console.log("updated", body)
        postData(`admin-app/promo-codes/${state?.id}`, { ...body, _method: "put" });
      } else {
        postData("admin-app/promo-codes", { ...body });
      }
    },
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the PromoCode");
      queryClient.invalidateQueries("promocode");
      navigate("/promo-code");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
    },
  });

  const handleSubmit = (e) => {
    // console.log("inputValue", inputValue)

    e.preventDefault();
    console.log(inputValue);
    if (
      inputValue.is_percentage ||
      (inputValue.is_percentage === 1 && parseInt(inputValue.discount_price) > 100)
    ) {
      openAlertBox("error", "Discount Percentage must be in range of 0 to 100!");
    } else if (inputValue.start_date !== "" && inputValue.end_date !== "") {
      mutate({
        name: inputValue.name,
        is_percentage: inputValue.is_percentage,
        discount_price: inputValue.discount_price,
        limit: inputValue.limit,
        code: inputValue.code,
        is_available: inputValue.is_available,
        start_date: inputValue.start_date,
        end_date: inputValue.end_date,
      });
    } else {
      openAlertBox("error", "Please set both start date and end date!");
    }
  };

  const handleChangeStartDate = (e) => {
    let dates = new Date(e.$d);
    // setStartDate(dates);
    if (state) {
      let dates = new Date(e.$d);
      const payload = format(new Date(dates), "yyyy-MM-dd HH:mm:ss");

      setInputValue({ ...inputValue, start_date: payload });
    } else {
      let dates = new Date(e.$d);
      const payload = format(new Date(dates), "yyyy-MM-dd HH:mm:ss");

      setInputValue({ ...inputValue, start_date: payload });
    }
  };

  const handleChangeEndDate = (e) => {
    let dates = new Date(e.$d);
    // setEndDate(dates);
    if (state) {
      let dates = new Date(e.$d);
      const payload = format(new Date(dates), "yyyy-MM-dd HH:mm:ss");
      setInputValue({ ...inputValue, end_date: payload });
    } else {
      let dates = new Date(e.$d);
      const payload = format(new Date(dates), "yyyy-MM-dd HH:mm:ss");
      setInputValue({ ...inputValue, end_date: payload });
    }
  };

  const handleCheckInput = (event) => {
    const { name, checked } = event.target;
    // console.log(name, "checked");
    setInputValue({ ...inputValue, [name]: checked ? 1 : 0 });
  };
  // console.log("inputValue", state?.start_date[0])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid
          container
          spacing={6}
          sx={{ display: "flex" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={6}
            boxShadow={3}
            borderRadius={5}
            padding={5}
            component="form"
            onSubmit={handleSubmit}
          >
            <Typography variant="captions">Crate Promo Code</Typography>
            <MDBox my={2}>
              <MDInput
                value={inputValue.name}
                onChange={inputChangeHandler}
                type="text"
                label="Name"
                name="name"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControlLabel
                value="top"
                control={
                  <Switch
                    checked={inputValue?.is_percentage}
                    onChange={handleCheckInput}
                    name="is_percentage"
                  />
                }
                label="Percentage"
              />
              <MDInput
                value={inputValue.discount_price}
                onChange={inputChangeHandler}
                type="number"
                
                label={`Discount ${inputValue?.is_percentage ? "Percentage (0 - 100)" : "Price"}`}
                name="discount_price"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={inputValue.limit}
                onChange={inputChangeHandler}
                type="number"
                label="Limit"
                name="limit"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={inputValue.code}
                onChange={inputChangeHandler}
                type="text"
                label="Code"
                name="code"
                fullWidth
                required
              />
            </MDBox>
            <MDBox sx={{ display: "flex", marginBottom: "20px" }}>
              <FormControlLabel
                value="top"
                color="warning"
                control={
                  <Switch
                    color="warning"
                    checked={inputValue?.is_available}
                    onChange={handleCheckInput}
                    name="is_available"
                  />
                }
                label="Available"
              />
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  required
                  label="Start Date"
                  inputFormat="YYYY-MM-DD"
                  value={inputValue.start_date}
                  onChange={(e) => handleChangeStartDate(e)}
                  renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} size="small" />}
                />

                <DesktopDatePicker
                  required
                  label="End Date"
                  inputFormat="YYYY-MM-DD"
                  value={inputValue.end_date}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} size="small" />}
                />
              </LocalizationProvider>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                // disabled={isLoading}
                type="submit"
                variant="gradient"
                color="primary"
                fullWidth
              >
                {state ? "Update" : "Create"}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

export default PromoCodeForm;
