/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import noti from "../../assets/audio/noti.mp3";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
import MailIcon from "@mui/icons-material/Mail";
// Material Dashboard 2 React context
import LogoutIcon from "@mui/icons-material/Logout";
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import { Badge, Box, Typography, styled } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { collection, doc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import logo from "../../assets/images/logos/myenda-logo.png";
import { db } from "lib/firebase";
import { useQuery } from "react-query";
import { getCurrentDate } from "lib/helper";
import { getData } from "lib/fetcher";

const ChildrenRoutes = styled("div")`
  max-height: ${({ isOpened }) => (isOpened ? "100%" : "0")};
  overflow: hidden;
  transition: max-height ease 300ms;
  padding-left: 0.5rem;
  width: 100%;
`;
const ChildRoute = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  color: white;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  max-width: 11rem;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "rgba(255, 255, 255, 0.2)"};
`;
function SidenavCollapse({ icon, name, hasNested, nested }) {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [active, setActive] = useState(false);
  const [firstMount, setFirstMount] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [chat, setChat] = useState(0);
  function checkActive(route) {
    let isMatch = pathname.includes(route);
    return isMatch;
  }
  useEffect(() => {
    if (nested?.length > 0) {
      let isChildrenMatch = nested.some((route) => pathname.includes(route.route));
      setActive(isChildrenMatch);
    }
  }, [pathname]);
  // const playSong = () => {
  //   console.log("play");
  //   const audio = new Audio(noti);
  //   audio.play();
  // };
  const { data, isLoading, error, isError, refetch } = useQuery(["deli-leave", pathname], () =>
    getData("admin-app/delivery-man-leaves?month=" + getCurrentDate() + "&status=PENDING")
  );

  return (
    <>
      <ListItem component="li" onClick={() => (hasNested ? setIsOpened(!isOpened) : false)}>
        <MDBox
          sx={(theme) =>
            collapseItem(theme, {
              active: isOpened || active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {name === "Chat" || name === "ချတ်" ? (
            <Badge
              badgeContent={chat}
              sx={{
                mx: 1,
              }}
              color="primary"
            >
              <MailIcon color="white" />
            </Badge>
          ) : name === "Riders" ? (
            <Badge
              badgeContent={data?.result?.data?.length}
              sx={{
                mx: 1,
                py: 1,
              }}
              color="primary"
            >
              <DirectionsBikeIcon color="white" />
            </Badge>
          ) : (
            <ListItemIcon
              sx={(theme) =>
                collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
              }
            >
              {icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={t(name)}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          <Box>
            <Typography variant="body2" color="white"></Typography>
          </Box>
          {hasNested && (
            <>{isOpened ? <Icon>keyboard_arrow_down</Icon> : <Icon>keyboard_arrow_right</Icon>}</>
          )}
        </MDBox>
      </ListItem>
      <ChildrenRoutes isOpened={isOpened}>
        {nested.map((route) => (
          <ChildRoute key={route.route} active={checkActive(route.route) ? "true" : undefined}   to={route.route}>
            {route.name === "Leaves" ? (
              <Badge
                badgeContent={data?.result?.data?.length}
                sx={{
                  mx: 1,
                  py: 1,
                }}
                color="primary"
              >
                <LogoutIcon color="white" />
              </Badge>
            ) : (
              <ListItemIcon
                sx={(theme) =>
                  collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                }
              >
                {route.icon}
              </ListItemIcon>
            )}

            <ListItemText
              primary={t(route.name)}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active,
                })
              }
            />
          </ChildRoute>
        ))}
      </ChildrenRoutes>
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  hasNested: false,
  nested: [],
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  hasNested: PropTypes.bool,
  nested: PropTypes.array,
};

export default SidenavCollapse;
