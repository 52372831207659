import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import MDButton from "components/MDButton";
import {
  Box,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import { useState } from "react";
import { formatDate } from "lib/helper";
function OfficeOrderDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();
  const [selectedDate, setSelectedDate] = useState(null);
  const [status, setStatus] = useState("PENDING");
  console.log("params", params);
  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };
  const { data, isLoading, error, isError } = useQuery(
    ["office-order", params.id],
    () => getData(`/admin-app/give-way-orders/${params.id}`),
    {
      refetchOnWindowFocus: false,
    }
  );

  console.log(data);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ marginBottom: "20px" }}>
            Office ORDER Detail
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Card
                sx={{ p: 3, backgroundColor: "#12AB6810", boxShadow: "none", minHeight: "30rem" }}
              >
                <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                  ORDER INFO
                </Typography>

                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Delivery Man
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.delivery_man.name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Date
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.order_date}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Complete Date
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.done_date_time}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Complete Date
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.done_date_time}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Total Duration
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.duration}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Process
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.order_status}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Order Form
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.order_from?.name}
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{ p: 3, backgroundColor: "#12AB6810", boxShadow: "none", minHeight: "30rem" }}
              >
                <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                  PICKUP INFO
                </Typography>

                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Name
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.pick_up_name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Phone
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.pick_up_phone}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Address
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.pick_up_address}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Note
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.pick_up_note}
                  </Typography>
                </Box>
                {data?.result?.second_pick_up_name && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Second Name
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.second_pick_up_name}
                    </Typography>
                  </Box>
                )}
                {data?.result?.second_pick_up_phone !== 0 && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Second Phone
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.second_pick_up_phone}
                    </Typography>
                  </Box>
                )}
                {data?.result?.second_pick_up_address && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Second Address
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.second_pick_up_address}
                    </Typography>
                  </Box>
                )}
                {data?.result?.second_pick_up_note && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Second Note
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.second_pick_up_note}
                    </Typography>
                  </Box>
                )}
                {data?.result?.third_pick_up_name && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Third Name
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.third_pick_up_name}
                    </Typography>
                  </Box>
                )}
                {data?.result?.third_pick_up_phone !== 0 && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Third Phone
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.third_pick_up_phone}
                    </Typography>
                  </Box>
                )}
                {/* {data?.result?.third_pick_up_address && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Third Address
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.third_pick_up_address}
                    </Typography>
                  </Box>
                )} */}
                {/* {data?.result?.third_pick_up_note && (
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Third Note
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {data?.result?.third_pick_up_note}
                    </Typography>
                  </Box>
                )} */}
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{ p: 3, backgroundColor: "#12AB6810", boxShadow: "none", minHeight: "30rem" }}
              >
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  DROPOFF INFO
                </Typography>

                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Name
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.drop_off_name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Phone
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.drop_off_phone}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Address
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.drop_off_address}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    Note
                  </Typography>
                  <Typography variant="h6" sx={{ width: "30px" }}>
                    -
                  </Typography>
                  <Typography variant="h6" sx={{ width: "200px" }}>
                    {data?.result?.drop_off_note}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
          {data?.result?.images.length > 0 && (
            <Grid item xs={12} sx={{ py: 2 }}>
              <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                images
              </Typography>
              <Grid container spacing={4}>
                {data?.result?.images.map((image, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <div style={{ position: "relative" }}>
                      <img
                        src={image.image_url}
                        style={{ width: "300px" }}
                        alt={`Image ${index + 1}`}
                        width="100%"
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sx={{ py: 2 }}>
            <MDBox sx={{ width: 300, float: "left" }} mb={1}>
              {/* <MDButton
                type="submit"
                // disabled={isLoading}
                variant="gradient"
                color="light"
                fullWidth
              >
                {isLoading ? "Loading..." : "Cancel"}
              </MDButton> */}
            </MDBox>
            {/* <MDBox sx={{ width: 300, float: "right" }} mb={1}>
              <MDButton
                type="submit"
                // disabled={isLoading}
                color="success"
                fullWidth
              >
                {isLoading ? "Submiting..." : "Order Complete"}
              </MDButton>
            </MDBox> */}
          </Grid>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OfficeOrderDetail;
