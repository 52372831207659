import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAlertBox from "lib/hooks/useAlertBox";
import { FormControlLabel, IconButton, Stack, Switch, styled } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "lib/firebase";
import MDInput from "components/MDInput";
import { BorderColor } from "@mui/icons-material";

const SectionContainer = styled("form")`
  width: 100%;
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  padding-block: 2rem;
`;

const Background = styled("figure")`
  width: 90%;
  aspect-ratio: 16 / 7;
  border: solid 1px #606060;
  border-style: dashed;
  padding-block: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    aspect-ratio: 16 / 6;
    object-fit: contain;
  }
`;

const Qr = styled(Background)`
  max-width: 8rem;
  margin-top: 1rem;
  aspect-ratio: 1 / 1;
  padding: 0rem;
  font-size: 1rem;
  text-align: center;
  img {
    aspect-ratio: 1 / 1;
  }
`;

const Info = styled("div")`
  position: relative;

  width: 100%;
  .buttons {
    position: absolute;
    bottom: 0rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

export default function Sections() {
  const { openAlertBox } = useAlertBox();
  const [sections, setSections] = useState([]);

  async function getsections() {
    try {
      const q = query(collection(db, "ads"));
      const res = await getDocs(q);
      const arr = [];
      res.forEach((doc) => arr.push(doc.data()));
      console.log(arr);
      setSections(arr);
    } catch (error) {
      openAlertBox("error", error.message || "Unexpected error");
    }
  }
  useEffect(() => {
    getsections();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Stack gap={"1rem"}>
          <Section section={sections[0]} refetch={() => getsections()} />
        </Stack>
      </MDBox>
    </DashboardLayout>
  );
}

function Section({ section, refetch, order }) {
  const { openAlertBox } = useAlertBox();
  const [tempImage, setTempImage] = useState(null);
  const [duration, setDuration] = useState();
  const [active, setActive] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDuration(section?.duration);
    setActive(section?.is_active);
  }, [section]);

  async function doSectionImageUpload(path, image) {
    try {
      const imgRef = ref(storage, path);
      await uploadBytes(imgRef, image);
      const url = await getDownloadURL(imgRef);
      return url;
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }
  async function uploadsection() {
    try {
      let image_url = section.image_url;
      if (tempImage) {
        image_url = await doSectionImageUpload("ads/", tempImage);
      }

      const body = {
        image_url,
        duration: +duration,
        is_active: active,
        id: "1",
      };
      console.log(body);
      await setDoc(doc(db, "ads", "1"), body);
      openAlertBox("success", "Section uploaded successfully");
      reset();
      refetch();
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }

  function reset() {
    setDuration(null);
    setActive(null);
    setTempImage(null);
    setDisabled(true);
  }

  return (
    <SectionContainer>
      <Background>
        <label>
          {tempImage ? (
            <img src={URL.createObjectURL(tempImage)} alt="" />
          ) : section?.image_url ? (
            <img src={section?.image_url} alt="" />
          ) : (
            "Upload background"
          )}
          <input
            type="file"
            accept="image/*"
            disabled={disabled}
            hidden
            onChange={(e) => setTempImage(e.target.files[0])}
          />
        </label>
      </Background>
      <Info>
        <Stack gap={2}>
          <MDInput
            type="number"
            name="duration"
            label="duration"
            required
            disabled={disabled}
            fullWidth
            value={duration || 0}
            onChange={(e) => setDuration(e.target.value)}
          />
          {disabled ? (
            <p>{active ? "Active" : "Inactive"}</p>
          ) : (
            <FormControlLabel
              value="bottom"
              control={
                <Switch
                  checked={active}
                  onChange={(e) => {
                    setActive(e.target.checked);
                  }}
                  name="active"
                />
              }
              label="active"
            />
          )}
        </Stack>

        <div className="buttons">
          {disabled ? (
            <IconButton color="warning" onClick={() => setDisabled(false)}>
              <BorderColor />
            </IconButton>
          ) : (
            <>
              <IconButton color="warning" onClick={reset}>
                <CloseIcon />
              </IconButton>
              <IconButton color="success" onClick={uploadsection}>
                <CheckIcon />
              </IconButton>
            </>
          )}
        </div>
      </Info>
    </SectionContainer>
  );
}
