import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  FormControl,
  Grid,
  InputLabel,
  Box,
  Typography,
  MenuItem,
  Select,
  Stack,
  Button,
  Modal,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import { convertToPhoneDigit } from "lib/helper";
import { Timestamp, collection, doc, setDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { getData } from "lib/fetcher";
import { useTranslation } from "react-i18next";

const DeliveryManForm = () => {
  const navigate = useNavigate();
  const { openAlertBox } = useAlertBox();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const theme = useTheme();
  useEffect(() => {
    setPhone1(state?.phones[0]);
    setPhone2(state?.phones[1]);
    setPhone3(state?.phones[2]);
  }, [state]);
  console.log(state);
  const [inputValue, setInputValue] = useState(
    state
      ? state
      : {
          name: "",
          email: "",
          phone: "",
          password: "",
          longitude: "",
          latitude: "",
          office_delivery_man: true,
        }
  );
  const [open, setOpen] = useState(false);
  const { mutate: passwordMutate, isLoading: passwordLoading } = useMutation({
    mutationFn: (body) => postData(`admin-app/reset-password`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the password");
      setOpen(false);
    },

    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const [inputPassword, setInputPassword] = useState({
    password: "",
    password_confirmation: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;

    setInputPassword((current) => {
      return {
        ...current,
        [name]: value,
      };
    });
  };
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setInputValue({ ...inputValue, [name]: value });
  };
  const { mutate: updateImageMutate, isLoading: updateImageLoading } = useMutation({
    mutationFn: (body) => postData(`admin-app/delivery-men`, body),
    onSuccess: async (data) => {
      const chatCollectionRef = collection(db, "admin_chat");
      await setDoc(doc(chatCollectionRef, "deli_" + data?.result?.id), {
        chat_with: "deli",
        id: "deli_" + data?.result?.id,
        is_admin_read: true,
        is_user_read: false,
        lastMessage: "",
        lastMessageFrom: "",
        lastMessageTime: "",
        profile_image: "",
        uid: data?.result?.id,
        username: data?.result?.name,
        timestamp: Timestamp.now(),
      });
      openAlertBox("success", " Success", " successfully ");
      queryClient.invalidateQueries("delivery-man");
      navigate("/delivery-men");
    },
    onError: async (error) => {
      if (error.validation[0] === "The phone has already been taken.") {
        let phone = inputValue.phone.toString();
        if (phone?.startsWith("0")) phone = "95" + phone?.substring(1);
        await getData(`admin-app/delivery-men-restore/${phone}`);
        openAlertBox("success", "Phone number restored", "Please re-submit again");
      } else
        openAlertBox(
          "error",
          " Something went wrong",
          error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
        );
    },
  });
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postData(`admin-app/delivery-men/${state?.id}`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", " successfully ");
      queryClient.invalidateQueries("delivery-man");
      navigate("/delivery-men");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  // const { mutate, isLoading: updateLoading } = useMutation({
  //   mutationFn: (body) => {
  //     postData(`admin-app/delivery-men`, { ...body })
  //   },
  //   onSuccess: () => {
  //     openAlertBox("success", " Success", "You have successfully create the Delivery Man");
  //     queryClient.invalidateQueries("");
  //     navigate("/delivery-men");
  //   },
  //   onError: (error) => {
  //     console.log("errpr==", error);
  //     // openAlertBox(
  //     //   "error",
  //     //   " Something went wrong",
  //     //   error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
  //     // );
  //   },
  // });

  const handleSubmit = (e) => {
    e.preventDefault();
    const phones = [phone1, phone2, phone3];

    if (state) {
      updateMutate({
        ...inputValue,
        phone: convertToPhoneDigit(inputValue?.phone?.toString()),
        longitude: inputValue.longitude.toString(),
        latitude: inputValue.latitude.toString(),
        phones: phones.map((phone) => {
          return convertToPhoneDigit(phone?.toString());
        }),
        office_delivery_man: true,
        city_id: 1,
        _method: "PUT",
      });
    } else {
      updateImageMutate({
        ...inputValue,
        phone: convertToPhoneDigit(inputValue?.phone?.toString()),
        longitude: inputValue.longitude.toString(),
        latitude: inputValue.latitude.toString(),
        phones: phones.map((phone) => {
          return convertToPhoneDigit(phone?.toString());
        }),
        city_id: 1,
      });
    }
  };
  const handleResetPassword = (e) => {
    e.preventDefault();
    if (inputPassword.password !== inputPassword.password_confirmation) {
      openAlertBox("error", "Something went wrong", "Password and confirm password not match");
    } else {
      passwordMutate({
        id: state.id,
        password: inputPassword.password,
        password_confirmation: inputPassword.password_confirmation,
        login_app: "DELIVERY_APP",
      });
    }
  };
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {state && (
          <MDButton
            style={{ marginRight: "2px", float: "right", marginLeft: "2px" }}
            variant="gradient"
            color="warning"
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("password")} {t("reset")}
          </MDButton>
        )}
        <Grid
          container
          spacing={6}
          sx={{ display: "flex" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} sx={{ p: 3 }}>
            <MDBox mb={2}>
              <MDInput
                value={inputValue.name}
                onChange={inputChangeHandler}
                type="text"
                label={t("Name")}
                name="name"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={inputValue.email}
                onChange={inputChangeHandler}
                type="text"
                label={t("Email")}
                name="email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={inputValue.phone.toString()}
                onChange={inputChangeHandler}
                type="text"
                label={t("Phone")}
                name="phone"
                fullWidth
              />
            </MDBox>
            {!state && (
              <MDBox mb={2}>
                <MDInput
                  value={inputValue.password}
                  onChange={inputChangeHandler}
                  type="password"
                  label={t("Password")}
                  name="password"
                  fullWidth
                />
                <MDBox mb={2}></MDBox>
              </MDBox>
            )}
            {state && (
              <MDBox mb={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Procress</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputValue?.status}
                    label="Restaurants"
                    name="status"
                    onChange={inputChangeHandler}
                    sx={{ padding: "13px" }}
                  >
                    <MenuItem value="WORK_ON_PROCESS">WORK_ON_PROCESS</MenuItem>
                    <MenuItem value="DELIVERY_IS_FREE">DELIVERY_IS_FREE</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}
            <MDBox mb={2}>
              <MDInput
                value={phone1}
                onChange={(e) => setPhone1(e.target.value)}
                type="text"
                label={`${t("first")} ${t("Phone")}`}
                name="phone1"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={phone2}
                onChange={(e) => setPhone2(e.target.value)}
                type="text"
                label={`${t("second")} ${t("Phone")}`}
                name="phone2"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={phone3}
                onChange={(e) => setPhone3(e.target.value)}
                type="text"
                label={`${t("third")} ${t("Phone")}`}
                name="phone3"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                // disabled={isLoading}
                onClick={handleSubmit}
                variant="gradient"
                color="primary"
                fullWidth
              >
                {state ? t("edit") : t("add")}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              minHeight: "15rem",
              width: "100%",
              maxWidth: "40rem",
              outline: "none",
              borderRadius: "0.5rem",
              padding: "1rem",
              textAlign: "center",
            }}
            component="form"
            onSubmit={handleResetPassword}
          >
            <Typography
              textAlign="left"
              component="h4"
              fontSize="1.1rem"
              color="info"
              fontWeight={600}
              sx={{ marginBottom: "1rem", color: theme.palette.info.main }}
            >
              Change password
            </Typography>

            <MDInput
              type="password"
              name="password"
              label="Password"
              fullWidth
              onChange={handleInput}
              sx={{ marginBottom: "1rem" }}
            />
            <MDInput
              type="password"
              name="password_confirmation"
              onChange={handleInput}
              label="Password Confirmation"
              fullWidth
            />

            <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "2rem" }}>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="text"
                size="medium"
                color="success"
              >
                Cancel
              </Button>
              <MDButton
                type="submit"
                disabled={passwordLoading}
                variant="contained"
                color="info"
                size="medium"
              >
                {passwordLoading ? "Saving..." : "Save"}
              </MDButton>
            </Stack>
          </Box>
        </Modal>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default DeliveryManForm;
