import { Box, Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect } from "react";
import MDInput from "components/MDInput";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import useAlertBox from "lib/hooks/useAlertBox";
import RestaruantLayout from "layouts/RestaruantManagement/RestaruantLayout";
import UploadImage from "components/Utils/ImageUpload";
import { postImageData } from "lib/fetcher";
import { useTranslation } from "react-i18next";

const initalData = {
  name: "",
  image_url: "",
  image_url: "",
};
function RestaruantCategoryForm() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState(state ? { ...state } : initalData);
  const theme = useTheme();
  const { openAlertBox } = useAlertBox();
  const [category, setCategory] = React.useState(
    state ? state?.restaurant_categories?.map((item) => item.id) : []
  );
  const [image_url, setImageUrl] = React.useState(state ? state?.image_url : "");
  const [thumb_url, setThumbUrl] = React.useState(state ? state?.thumb_url : "");
  // const [, setImageUrl] = React.useState(state ? state?.image_url : "");
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const queryClient = useQueryClient();
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postImageData(`/admin-app/restaurant-categories/${state?.id}`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the restaurant category");
      queryClient.invalidateQueries("restaruant-category");
      navigate("/restaruant-category");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postImageData(`/admin-app/restaurant-categories`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully created the restaurant category");
      queryClient.invalidateQueries("restaruant-category");
      navigate("/restaruant-category");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue);

    if (state) {
      const fd = new FormData();
      fd.append("name", inputValue.name);
      if (image_url && typeof image_url === "object") {
        fd.append("image", image_url);
      }
      fd.append("_method", "PUT");
      updateMutate(fd);
    } else {
      const fd = new FormData();
      fd.append("name", inputValue.name);

      fd.append("image", image_url);

      createMutate(fd);
    }
  };
  const { t } = useTranslation();
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state
            ? `${t("Restaruant Category")} ${t("edit")}`
            : `${t("Restaruant Category")} ${t("add")}`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ p: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.name}
                onChange={inputChangeHandler}
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Name")}
                name="name"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}></Box>
          </Grid>
          <Grid item xs={4} sx={{ p: 3 }}>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <UploadImage
                setUploadImage={setImageUrl}
                title="Upload Category Image"
                defaultImg={image_url}
              />
            </Box>
          </Grid>
        </Grid>
        <MDBox mb={1} sx={{ float: "right" }}>
          <MDButton
            disabled={updateLoading || createLoading}
            onClick={handleSubmit}
            variant="gradient"
            color="primary"
            fullWidth
          >
            {updateLoading || createLoading ? "Submiting..." : t("submit")}
          </MDButton>
        </MDBox>
      </Card>
    </RestaruantLayout>
  );
}
export default RestaruantCategoryForm;
