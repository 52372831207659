/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";


function Recommend() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { openAlertBox } = useAlertBox();
    const { openConfirmBox } = useConfirmBox();

    const { data: deliveries, isLoading, error, isError, isSuccess } = useQuery(
        "recommends",
        () => getData("admin-app/recommends"),
        {
            refetchOnWindowFocus: false,
        }
    );

    const columns = [
        { Header: "Name", accessor: "name", align: "left" },
        { Header: "Order", accessor: "order", align: "left" },
        { Header: "StartDate", accessor: "start_date", align: "left" },
        { Header: "EndDate", accessor: "end_date", align: "left" },
        { Header: "action", accessor: "action", align: "center" },
    ];

    const rows = [];
    const TableRowField = ({ title }) => (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {title}
        </MDTypography>
    );
    const ActionField = ({ valueId }) => (
        <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDButton variant="text" color={"dark"} onClick={() => {
                navigate('/recommends/detail', { state: valueId });
            }}>
                <Icon>info</Icon>&nbsp;detail
            </MDButton>
            <MDButton variant="text" color={"dark"}
                onClick={() => {
                    navigate(`/recommends/update`, { state: valueId });
                }}
            >
                <Icon>edit</Icon>&nbsp;Edit
            </MDButton>
            <MDBox mr={1}>
                <MDButton variant="text" onClick={() => {
                    handleDelete(valueId);
                }} color="error">
                    <Icon>delete</Icon>&nbsp;delete
                </MDButton>
            </MDBox>
        </MDBox>
    );

    deliveries?.result?.data?.map((value) => {
        rows.push({
            name: <TableRowField align="left" title={value.name} />,
            order: <TableRowField align="left" title={value.order} />,
            start_date: <TableRowField align="left" title={value.start_date} />,
            end_date: <TableRowField align="left" title={value.end_date} />,
            action: <ActionField align="left" valueId={value} />,
        });
    });

    const DeleteApiHandler = async (value) => {
        try {
            await deleteData("admin-app/recommends/" + value?.id);
            openAlertBox("success", "Delete Success", "You have successfully deleted the Recommends");
            queryClient.invalidateQueries("recommends");
        } catch (error) {
            openAlertBox(
                "error",
                " Something went wrong",
                error?.message || "Something went wrong"
            );
            console.log(error);
        }
    };

    const handleDelete = (id) => {
        openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this Delivery', id);
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                sx={{ display: "flex" }}
                                alignItems="center"
                                justifyContent="space-between"
                                variant="gradient"
                                bgColor="primary"
                                borderRadius="lg"
                                coloredShadow="primary"
                            >
                                <MDTypography variant="h6" color="white">
                                    Recommend Restaurant
                                </MDTypography>
                                <NavLink key={"recommends-form"} to={"/recommends-form"}>
                                    <MDButton variant="gradient" color="dark">
                                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                        &nbsp;add new
                                    </MDButton>
                                </NavLink>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{
                                        columns, rows: isLoading
                                            ? TableLoadingSkeleton(columns) : rows
                                    }}
                                    isSorted={true}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Recommend;
