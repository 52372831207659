import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Badge, Card, Divider, Grid, List, ListItem, ListItemText, TextField } from "@mui/material";
import Footer from "examples/Footer";
import Test from "../../assets/audio/noti.mp3";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "lib/firebase";
import MDTypography from "components/MDTypography";
import UserAvatar from "assets/images/team-3.jpg";
import MDAvatar from "components/MDAvatar";
import { differenceInDays, differenceInHours, format, formatDistanceToNow } from "date-fns";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import ReactAudioPlayer from "react-audio-player";
import { storage } from "lib/firebase";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import getBlobDuration from "get-blob-duration";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const redDotStyles = {
  backgroundColor: "red",
  borderRadius: "50%",
  height: "10px",
  minWidth: "10px",
  width: "10px",
  padding: 0,
};

export default function ChatHome() {
  const [value, setValue] = React.useState(0);
  const [chats, setChats] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [chatHistory, setChatHistory] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [initialUserCount, setInitialUserCount] = React.useState(5);
  const [lastVisibleChatUserDoc, setLastVisibleChatUserDoc] = React.useState(null);
  const [initialMessagesCount, setInitialMessagesCount] = React.useState(5);
  const [lastVisibleMessageDoc, setLastVisibleMessageDoc] = React.useState(null);
  const chatCollectionRef = collection(db, "admin_chat");
  const [tempImage, setTempImage] = React.useState(null);
  const listRef = React.useRef(null);

  React.useEffect(() => {
    let userType;

    switch (value) {
      case 0:
        userType = "deli";
        break;
      case 1:
        userType = "restaurant";
        break;
      case 2:
        userType = "user";
        break;
      default:
    }

    let chatQuery = query(
      chatCollectionRef,
      where("chat_with", "==", userType),
      orderBy("lastMessageTime", "desc"),
      limit(initialUserCount)
    );
    const unsubscribe = onSnapshot(chatQuery, (snap) => {
      const updatedChats = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setChats(updatedChats);
      console.log("success", updatedChats);
      const lastDoc = snap.docs[snap.docs.length - 1];
      setLastVisibleChatUserDoc(lastDoc);
      console.log("last doc", lastDoc);
    });
    return () => {
      unsubscribe();
    };
  }, [value]);
  async function doBannerUpload(file) {
    try {
      console.log(file, "file");
      const imgRef = ref(storage, `chats/${selectedUser.id}/${file.name + uuidv4()}`);
      await uploadBytes(imgRef, file);
      const url = await getDownloadURL(imgRef);
      console.log(url, "url");
      return url;
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    if (
      chatHistory.length === initialMessagesCount &&
      listRef.current &&
      listRef.current.lastElementChild
    ) {
      console.log("scroll height", listRef.current.scrollHeight);
      setTimeout(() => {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }, 10);
      listRef.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);
  const loadMoreChatUsers = async () => {
    // const newLimitAmount = initialUserCount + 10; // Increase the limit by 10 or adjust as needed
    if (lastVisibleChatUserDoc) {
      let userType;
      switch (value) {
        case 0:
          userType = "deli";
          break;
        case 1:
          userType = "restaurant";
          break;
        case 2:
          userType = "user";
          break;
        default:
      }
      const chatQuery = query(
        chatCollectionRef,
        where("chat_with", "==", userType),
        orderBy("lastMessageTime", "desc"),
        startAfter(lastVisibleChatUserDoc),
        limit(initialUserCount) // Make sure to include the limit here as well
      );

      const unsubscribe = onSnapshot(chatQuery, (snap) => {
        const updatedChats = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setChats((prevChats) => [...prevChats, ...updatedChats]);

        const lastDoc = snap.docs[snap.docs.length - 1];
        setLastVisibleChatUserDoc(lastDoc);
      });

      return () => unsubscribe(); // Clean up the listener when the component unmounts or when the dependency changes
    }
  };
  console.log(selectedUser, "selectedUser");
  const loadMoreMessages = () => {
    if (lastVisibleMessageDoc) {
      const timestampOrder = orderBy("timestamp", "desc");
      const chattingCollectionRef = collection(db, "admin_chat", selectedUser.id, "chatting");
      const queryRef = query(
        chattingCollectionRef,
        timestampOrder,
        startAfter(lastVisibleMessageDoc),
        limit(initialMessagesCount)
      );

      const unsubscribe = onSnapshot(queryRef, (snap) => {
        const updatedHistories = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setChatHistory((previousHistory) => [...previousHistory, ...updatedHistories]);
        const lastDoc = snap.docs[snap.docs.length - 1];
        setLastVisibleMessageDoc(lastDoc);
      });
      return () => unsubscribe();
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setInitialUserCount(20);
    setLastVisibleChatUserDoc(null);
    setChats([]);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (message.trim() !== "") {
      try {
        const currentDate = new Date();
        const formattedDate = format(currentDate, "HH:mm:ss,dd-MM-yyyy");
        const chatCollectionRef = collection(db, "admin_chat", selectedUser.id, "chatting");
        const data = await setDoc(doc(chatCollectionRef, formattedDate), {
          message: message,
          status: "sent",
          dateTime: "",
          token: "",
          dateTime: formattedDate,
          id: formattedDate,
          sendFrom: "admin",
          type: "text",
          timestamp: Timestamp.now(),
        });
        console.log(data, "wfojfoi");
        const chatDocRef = doc(db, "admin_chat", selectedUser.id);
        await updateDoc(chatDocRef, {
          isAdminRead: true,
          isUserRead: false,
          lastMessageFrom: "admin",
          lastMessage: message,
          lastMessageTime: Timestamp.now(),
        });
        setMessage("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fomattedTime = (timestamp) => {
    const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;
    const currentDate = new Date();
    const formattedDate = formatDistanceToNow(date, { addSuffix: true });
    if (date.getFullYear() !== currentDate.getFullYear()) {
      // If the year is different, format as "Month Day, Year"
      return format(date, "MMMM d, yyyy");
    } else if (date.getMonth() !== currentDate.getMonth()) {
      // If the month is different, format as "Month Day"
      return format(date, "MMMM d");
    } else if (Math.abs(differenceInDays(currentDate, date)) >= 7) {
      // If more than 7 days have passed, format as "Weeks ago"
      return formatDistanceToNow(date, { addSuffix: true, includeSeconds: false });
    } else if (Math.abs(differenceInDays(currentDate, date)) >= 1) {
      // If more than 1 day has passed, format as "Days ago"
      return formatDistanceToNow(date, { addSuffix: true, includeSeconds: false });
    } else if (Math.abs(differenceInHours(currentDate, date)) >= 1) {
      // If more than 1 hour has passed, format as "Hours ago"
      return formatDistanceToNow(date, { addSuffix: true, includeSeconds: false });
    } else {
      // If less than 1 hour has passed, format as "Minutes ago"
      return formatDistanceToNow(date, { addSuffix: true, includeSeconds: false });
    }
  };

  const getChatData = async (id, name) => {
    setSelectedUser({ id, name });
    setLastVisibleMessageDoc(null);
    setChatHistory([]);
    try {
      const chatDocRef = doc(db, "admin_chat", id);
      await updateDoc(chatDocRef, {
        isAdminRead: true,
      });
      const timestampOrder = orderBy("timestamp", "desc");
      const chattingCollectionRef = collection(db, "admin_chat", id, "chatting");
      const queryRef = query(chattingCollectionRef, timestampOrder, limit(initialMessagesCount));

      const unsubscribe = onSnapshot(queryRef, (snap) => {
        const updatedChats = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setChatHistory(updatedChats);
        const lastDoc = snap.docs[snap.docs.length - 1];
        setLastVisibleMessageDoc(lastDoc);
      });

      // Return the unsubscribe function if needed
      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageUpload = async (file) => {
    try {
      const url = await doBannerUpload(file);
      const currentDate = new Date();
      const formattedDate = format(currentDate, "HH:mm:ss,dd-MM-yyyy");
      const chatCollectionRef = collection(db, "admin_chat", selectedUser.id, "chatting");
      const data = await setDoc(doc(chatCollectionRef, formattedDate), {
        message: "",
        status: "sent",
        dateTime: "",
        token: "",
        dateTime: formattedDate,
        id: formattedDate,
        sendFrom: "admin",
        type: "image",
        timestamp: Timestamp.now(),
        imageUrls: [url],
      });
      console.log(data, "wfojfoi");
      const chatDocRef = doc(db, "admin_chat", selectedUser.id);
      await updateDoc(chatDocRef, {
        isAdminRead: true,
        isUserRead: false,
        lastMessageFrom: "admin",
        lastMessage: "Image",
        lastMessageTime: Timestamp.now(),
      });
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(chatHistory, "chatHistory");
  const { t } = useTranslation();
  const User = ({ id, name, lastMessage, profile_image, time, read }) => (
    <MDBox
      onClick={() => {
        getChatData(id, name);
      }}
    >
      <MDBox
        sx={{
          cursor: "pointer",
          backgroundColor: selectedUser.id === id ? "#dee2e6" : "",
          borderRadius: "10px",
          padding: "5px",
        }}
        display="flex"
        key={id}
        alignItems="center"
        lineHeight={1}
      >
        <MDAvatar src={profile_image ? profile_image : UserAvatar} name={name} size="md" />
        <MDBox ml={2} lineHeight={1} style={{ width: "100%" }}>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name || "-"}
            </MDTypography>
            {!read && (
              <Badge
                overlap="circular"
                variant="dot"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={redDotStyles}
              >
                {/* Your content */}
              </Badge>
            )}
          </MDBox>

          <Box display="flex" sx={{ marginTop: "1rem" }} justifyContent="space-between">
            <MDTypography variant="caption" display="inline">
              {lastMessage.substring(0, 50)}
            </MDTypography>
            <MDTypography variant="caption" display="inline">
              {time && fomattedTime(time)}
            </MDTypography>
          </Box>
        </MDBox>
      </MDBox>
      <Divider />
    </MDBox>
  );
  function secondsToMMSS(totalSeconds) {
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds - minutes * 60;

    // Padding the values to ensure they are two digits

    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return minutes + ":" + seconds;
  }

  const recorderControls = useAudioRecorder();
  const addAudioElement = async (blob) => {
    const storageRef = ref(storage, `chats/deli_34/${uuidv4()}.mp3`);
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);

    const duration = await getBlobDuration(blob);

    const durationMinute = secondsToMMSS(Math.ceil(duration));

    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "HH:mm:ss,dd-MM-yyyy");
      const chatCollectionRef = collection(db, "admin_chat", selectedUser.id, "chatting");
      const data = await setDoc(doc(chatCollectionRef, formattedDate), {
        message: "",
        status: "sent",
        dateTime: "",
        token: "",
        dateTime: formattedDate,
        id: formattedDate,
        sendFrom: "admin",
        timestamp: Timestamp.now(),
        imageUrls: [],
        audioUrl: url,
        audioDuration: durationMinute,
        type: "voice",
      });
      console.log(data, "wfojfoi");
      const chatDocRef = doc(db, "admin_chat", selectedUser.id);
      await updateDoc(chatDocRef, {
        isAdminRead: true,
        isUserRead: false,
        lastMessageFrom: "admin",
        lastMessage: "Voice",
        lastMessageTime: Timestamp.now(),
      });
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={5}>
            <Card>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label={t("riders")} {...a11yProps(0)} />
                    <Tab label={t("restaurants")} {...a11yProps(1)} />
                    <Tab label={t("users")} {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "70vh",
                    overflow: "scroll",
                  }}
                >
                  <TabPanel value={value} index={0} sx={{ height: "300px" }}>
                    {chats?.map(
                      (chat) =>
                        chat.chat_with === "deli" && (
                          <User
                            id={chat.id}
                            name={chat.username}
                            lastMessage={chat.lastMessage}
                            profile_image={chat.profile_image}
                            time={chat.lastMessageTime}
                            read={chat.isAdminRead}
                          />
                        )
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {chats?.map(
                      (chat) =>
                        chat.chat_with === "restaurant" && (
                          <User
                            id={chat.id}
                            name={chat?.username}
                            lastMessage={chat?.lastMessage}
                            profile_image={chat.profile_image}
                            time={chat?.lastMessageTime}
                            read={chat?.isAdminRead}
                          />
                        )
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {chats?.map(
                      (chat) =>
                        chat.chat_with === "user" && (
                          <User
                            id={chat.id}
                            name={chat.username}
                            lastMessage={chat.lastMessage}
                            profile_image={chat.profile_image}
                            time={chat.lastMessageTime}
                            read={chat.isAdminRead}
                          />
                        )
                    )}
                  </TabPanel>
                  <MDButton onClick={loadMoreChatUsers}>{t("lm")}</MDButton>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={7}>
            <Card
              sx={{
                display: "flex",
                padding: "5px",
                flexDirection: "column",
                height: "75vh",
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="button" fontWeight="medium" textTransform="none" padding={1}>
                {selectedUser.name}
              </Typography>
              <Divider />
              <Box sx={{ flex: 1, overflowY: "scroll", p: 2 }}>
                <MDBox
                  key="see-more"
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MDButton onClick={loadMoreMessages}>{t("sm")}</MDButton>
                </MDBox>
                <List
                  ref={listRef}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 0,
                  }}
                >
                  {chatHistory
                    ?.slice()
                    .reverse()
                    .map((message, index) => {
                      if (message.type == "image") {
                        return (
                          <ListItem
                            key={index}
                            sx={{
                              flexDirection: "column",
                              alignSelf: message.sendFrom === "admin" ? "flex-end" : "flex-start",
                              maxWidth: "50%",
                              mt: 1,
                            }}
                          >
                            {message.imageUrls.map((url) => (
                              <div
                                style={{
                                  width: "50%",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  marginLeft: message.sendFrom === "admin" ? "auto" : "0px",
                                  marginRight: message.sendFrom !== "admin" ? "auto" : "0px",
                                  borderRadius:
                                    message.sendFrom === "admin"
                                      ? "10px 0 10px 10px"
                                      : "0 10px 10px 10px",
                                  overflow: "hidden",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
                                  borderColor: message.sendFrom === "admin" ? "#80ed99" : "#e9ecef",
                                }}
                              >
                                <a href={url} target="_blank">
                                  <img
                                    src={url}
                                    alt=""
                                    style={{
                                      width: "100%",

                                      display: "block",
                                    }}
                                  />
                                </a>
                                <Typography variant="caption">{message.dateTime}</Typography>
                              </div>
                            ))}
                          </ListItem>
                        );
                      }
                      if (message.type === "voice") {
                        return (
                          <ListItem
                            key={index}
                            sx={{
                              flexDirection: "column",
                              alignSelf: message.sendFrom === "admin" ? "flex-end" : "flex-start",
                              maxWidth: "50%",
                              mt: 1,
                            }}
                          >
                            <ReactAudioPlayer src={message.audioUrl} type="audio/mpeg" controls />
                            <Typography variant="caption">{message.dateTime}</Typography>
                          </ListItem>
                        );
                      }
                      return (
                        <ListItem
                          key={index}
                          sx={{
                            alignSelf: message.sendFrom === "admin" ? "flex-end" : "flex-start",
                            maxWidth: "50%",
                            mt: 1,
                          }}
                        >
                          <ListItemText
                            primary={message.message}
                            padding={5}
                            secondary={
                              <Typography variant="caption">{message.dateTime}</Typography>
                            }
                            sx={{
                              backgroundColor: message.sendFrom === "admin" ? "#80ed99" : "#e9ecef",
                              borderRadius:
                                message.sendFrom === "admin"
                                  ? "10px 0 10px 10px"
                                  : "0 10px 10px 10px",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
                              padding: "5px",
                            }}
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
              {selectedUser.id && (
                <Box
                  display="flex"
                  component="form"
                  onSubmit={handleSendMessage}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ p: 2 }}
                >
                  <TextField
                    required
                    placeholder="Enter your message"
                    sx={{ border: "0px" }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                    autoFocus
                  />
                  <label
                    htmlFor={"image"}
                    style={{
                      marginLeft: "0.5rem",
                      marginRight: "0.5rem",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <input
                      id={"image"}
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        handleImageUpload(file);
                      }}
                      hidden
                      accept="image/*"
                    />

                    <AttachFileIcon
                      sx={{
                        fontSize: "2rem",
                        width: "1.5rem",
                        height: "1.5rem",
                        cursor: "pointer",
                        margin: "auto",
                      }}
                    />
                  </label>
                  <div>
                    <AudioRecorder
                      onRecordingComplete={(blob) => addAudioElement(blob)}
                      audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                      }}
                      downloadFileExtension="mp3"
                      recorderControls={recorderControls}
                      showVisualizer={true}
                    />
                  </div>
                  <MDButton type="submit" variant="contained" color="primary" sx={{ mx: 1 }}>
                    Send
                  </MDButton>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
