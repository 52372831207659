/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import RTL from "layouts/rtl";
// @mui icons
import Icon from "@mui/material/Icon";
import DeliveryMan from "layouts/DeliveryMan";
import Users from "layouts/users";
import Recommend from "layouts/Recommend";
import Places from "layouts/places";
import PromoCode from "layouts/PromoCode";

// import DeliveryManUsage from "layouts/DeliveryManUsage";
import PlaceAndPrices from "layouts/PlaceAndPrice";
import MapContainer from "layouts/map";
import Setting from "layouts/setting";
import PaymentMethod from "layouts/PaymentMethod";
import OrderForm from "layouts/Order/OrderForm";
import Order from "layouts/Order";

import HeaderAds from "layouts/HeaderAds";
import SpecialAds from "layouts/SpecialAds";

import UserAddressPlaces from "layouts/userAddressPlaces";
import { Chat } from "@mui/icons-material";
import ChatHome from "layouts/chat";
import Profile from "layouts/Profile";

const routes = [
  {
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">account_box</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    name: "Orders",
    key: "ordres",
    icon: <Icon fontSize="small">book_mark_border</Icon>,
    children: [
      {
        name: "Office Order",
        key: "office-order",
        icon: <Icon fontSize="small">book_mark_border</Icon>,
        route: "/office-order",
      },
      {
        name: "Food Order",
        key: "food-order",
        icon: <Icon fontSize="small">book_mark_border</Icon>,
        route: "/food-order",
      },
      {
        name: "Parcel Delivery",
        key: "parcel-delivery",
        icon: <Icon fontSize="small">book_mark_border</Icon>,
        route: "/parcel-delivery",
      },
    ],
  },
  {
    name: "Finance",
    key: "expense",
    icon: <Icon fontSize="small">Finance</Icon>,
    children: [
      {
        name: "Expense",
        key: "expenses",
        icon: <Icon fontSize="small">Expense</Icon>,
        route: "/expense",
      },
      {
        name: "Income",
        key: "income",
        icon: <Icon fontSize="small">Income</Icon>,
        route: "/income",
      },
      {
        name: "Additional Income",
        key: "additionalIncome",
        icon: <Icon fontSize="small">Additional Income</Icon>,
        route: "/additionalIncome",
      },
    ],
  },
  {
    name: "Restaurant",
    key: "restaurant",
    icon: <Icon fontSize="small">store_front</Icon>,
    children: [
      {
        name: "Management",
        key: "restaruant-management",
        icon: <Icon fontSize="small">local_dining</Icon>,
        route: "/restaruant-management",
      },

      {
        name: "Restaruant Category",
        key: "restaruant-category",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/restaruant-category",
      },
      {
        name: "Menu Category",
        key: "restaruant-menu-category",
        icon: <Icon fontSize="small">menu_book</Icon>,
        route: "/restaruant-menu-category",
      },
      {
        name: "Accept Form",
        key: "restaruant-form",
        icon: <Icon fontSize="small">local_dining</Icon>,
        route: "/restaruant-form",
      },
    ],
  },

  // Delivery Man Routes

  {
    name: "Riders",
    key: "delivery-men",
    icon: <Icon fontSize="small">directions_bike</Icon>,
    children: [
      {
        name: "Manage Account",
        key: "delivery-men",
        icon: <Icon fontSize="small">manage_account</Icon>,
        route: "/delivery-men",
      },
      // {
      //   name: "Way Records",
      //   key: "way-records",
      //   icon: <Icon fontSize="small">fact_check</Icon>,
      //   route: "/way-records/:id",
      // },
      {
        name: "Leaves",
        key: "Leaves",
        icon: <Icon fontSize="small">sick</Icon>,
        route: "/deli-leaves",
      },
      {
        name: "Riders on Map",
        key: "map",
        icon: <Icon fontSize="small">map</Icon>,
        route: "/map",
        component: <MapContainer />,
      },
    ],
  },
  {
    name: "General",
    key: "general",
    icon: <Icon fontSize="small">tune</Icon>,
    children: [
      {
        name: "Payment Method",
        key: "payment-method",
        icon: <Icon fontSize="small">credit_card</Icon>,
        route: "/payment-method",
      },
      {
        name: "Order From",
        key: "order-from",
        icon: <Icon fontSize="small">phone_iphone</Icon>,
        route: "/order-from",
        component: <Order />,
      },

      //Recommend Routes
      {
        name: "Recommendation",
        key: "recommends",
        icon: <Icon fontSize="small">recommend</Icon>,
        route: "/recommends",
        component: <Recommend />,
      },
      //End Recommend Routes

      //Promo Code Routes
      {
        name: "Promo Code",
        key: "promo-code",
        icon: <Icon fontSize="small">percent</Icon>,
        route: "/promo-code",
        component: <PromoCode />,
      },
      {
        name: "Header Ads",
        key: "hader-ads",
        icon: <Icon fontSize="small">Header Ads</Icon>,
        route: "/header-ads",
        component: <HeaderAds />,
      },
      {
        name: "Special Ads",
        key: "hader-ads",
        icon: <Icon fontSize="small">Special Ads</Icon>,
        route: "/special-ads",
        component: <SpecialAds />,
      },
    ],
  },
  {
    name: "Places",
    key: "places",
    icon: <Icon fontSize="small">pin_drop</Icon>,
    children: [
      {
        name: "Places",
        key: "places",
        icon: <Icon fontSize="small">place</Icon>,
        route: "/places",
      },
      // {
      //   name: "Map",
      //   key: "map",
      //   icon: <Icon fontSize="small">map</Icon>,
      //   route: "/map",
      // },
      {
        name: "Place And Prices",
        key: "place-and-prices",
        icon: <Icon fontSize="small">price_change</Icon>,
        route: "/place-and-prices",
      },

      {
        name: "User Address Places",
        key: "user-address-places",
        icon: <Icon fontSize="small">person_pin_circle</Icon>,
        route: "/user-address-places",
      },
      {
        name: "Wards",
        key: "wards",
        icon: <Icon fontSize="small">layers</Icon>,
        route: "/wards",
      },
    ],
  },
  {
    name: "User Site",
    key: "user-site",
    icon: <Icon fontSize="small">view_comfy</Icon>,
    children: [
      {
        name: "Banners",
        key: "banners",
        icon: <Icon fontSize="small">view_carousel_icon</Icon>,
        route: "/user-site/banners",
      },
      {
        name: "Sections",
        key: "Sections",
        icon: <Icon fontSize="small">view_agenda</Icon>,
        route: "/user-site/sections",
      },
      {
        name: "App Ads",
        key: "Ads",
        icon: <Icon fontSize="small">view_agenda</Icon>,
        route: "/user-site/ads",
      },
      {
        name: "Posts",
        key: "Post",
        icon: <Icon fontSize="small">article</Icon>,
        route: "/user-site/posts",
      },
    ],
  },
  {
    name: "Chat",
    key: "chat",
    icon: <Icon fontSize="small">message</Icon>,
    route: "/chat",
    component: <ChatHome />,
  },
  {
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    name: "Setting",
    key: "setting",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setting",
    component: <Setting />,
  },
];

export default routes;
