// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { getData } from "lib/fetcher";
import { useQuery } from "react-query";

const IncomeDetail = () => {
  const { state: dd } = useLocation();
  const {
    data: categories,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery(["incomeDetail", dd?.id], () => getData(`/admin-app/office-incomes/${dd?.id}`), {
    refetchOnWindowFocus: false,
  });
  // console.log("d", data)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ p: 3 }}>
            <Card sx={{ p: 3 }}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      ID
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.id}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Title
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.title}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Commission
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.commission}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      delivery_fee
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.delivery_fee}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Total
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.total}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      date
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.date}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Order ID
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.order?.id}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Order Number
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.order?.order_numer}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      Order Date
                    </Typography>
                    <Typography variant="h6" sx={{ width: "30px" }}>
                      -
                    </Typography>
                    <Typography variant="h6" sx={{ width: "200px" }}>
                      {categories?.result?.order?.order_date}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default IncomeDetail;
