import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import RestaruantLayout from "./RestaruantLayout";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getData } from "lib/fetcher";
import { useQuery, useQueryClient } from "react-query";
import { RestaruantMenuFormModal } from "./RestaurantMenuDetail";
import AddIcon from "@mui/icons-material/Add";
import MDButton from "components/MDButton";
import HorizontalScroll from "components/Utils/HorizontalScroll";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import MDDate from "components/MDDate";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";

function RestaruantDetail() {
  const { state: data } = useLocation();

  const [menuCategory, setMenuCategory] = useState(null);
  const [paginationPage, setPaginationPage] = useState(1);
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [date, setDate] = useState(getCurrentDate());
  const [month, setMonth] = useState(getCurrentDate());
  const handleChangeMonthFilter = (newValue) => {
    setMonth(ChangeMuiDate(newValue));

    console.log(ChangeMuiDate(newValue), "newValue");
  };
  const handleChangeDate = (newValue) => {
    setDate(ChangeMuiDate(newValue));
  };
  const [monthFilter, setMonthFilter] = useState(false);
  const { data: categoryData, isLoading } = useQuery(
    "category",
    () => getData(`/admin-app/restaurant-menu-categories`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const {
    data: categories,
    isLoading: isLoadingCategories,
    error,
    isSuccess,
  } = useQuery(
    ["income", monthFilter, month, date, paginationPage],
    () =>
      getData(
        `/admin-app/restaurant-report?&date=${monthFilter ? month : date}&filter_time=${
          monthFilter ? "MONTHLY" : "DAILY"
        }&request_report=LIST&restaurant_id=${data?.id}&page=${paginationPage ?? 1}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { id: restaurantId } = useParams();
  const { data: restaruantMenu, isError } = useQuery(
    ["restaruant-menu", menuCategory, paginationPage],
    () =>
      getData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus${
          menuCategory
            ? `?restaurant_menu_category_id=${menuCategory}&page=${paginationPage}`
            : `?page=${paginationPage}`
        }&limit=5&sort=created_at&order=desc`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [selectedData, setSelectedData] = useState(null);

  const columns = [
    { Header: "ID", accessor: "id", align: "center" },
    {
      Header: "delivery man",
      accessor: "delivery_man",
      align: "center",
    },
    {
      Header: "restaurant",
      accessor: "restaurant",
      align: "center",
    },
    {
      Header: "Order Number",
      accessor: "order_number",
      align: "center",
    },
    {
      Header: "Order Status",
      accessor: "order_status",
      align: "center",
    },
    {
      Header: "Order Date",
      accessor: "order_date",
      align: "center",
    },
    {
      Header: "Total Price",
      accessor: "total_price",
      align: "center",
    },
    {
      Header: "Delivery Man Price",
      accessor: "delivery_charge",
      align: "center",
    },
    {
      Header: "User",
      accessor: "user",
      align: "center",
    },
    {
      Header: "User Phone",
      accessor: "user_phone",
      align: "center",
    },
  ];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const rows = [];
  categories?.result?.data?.map((value) => {
    console.log("value", value);
    rows.push({
      id: <TableRowField align="left" title={value.id} />,
      delivery_man: <TableRowField align="left" title={value?.delivery_man?.name} />,
      restaurant: <TableRowField align="left" title={value?.restaurant?.name} />,
      order_number: <TableRowField align="left" title={value?.order_number} />,
      order_status: <TableRowField align="left" title={value?.order_status} />,
      order_date: <TableRowField align="left" title={value?.order_date} />,
      total_price: <TableRowField align="left" title={value?.total_price} />,
      delivery_charge: <TableRowField align="left" title={value?.delivery_price} />,
      user: <TableRowField align="left" title={value?.user?.name} />,
      user_phone: <TableRowField align="left" title={value?.user?.phone} />,
    });
  });
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h3" sx={{ mr: 2 }}>
              {data?.name}
            </Typography>
            {data?.restaurant_categories?.map((item) => (
              <MDBadge
                sx={{ mx: 0.5, height: "fit-content" }}
                badgeContent={item.name}
                variant="gradient"
                container
              />
            ))}
          </Box>
          {data?.is_open ? (
            <MDButton variant="contained" color="success" size="small">
              open
            </MDButton>
          ) : (
            <MDButton variant="contained" color="error" size="small">
              close
            </MDButton>
          )}
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <img
              src={data?.image_url}
              alt="burger"
              style={{ width: "50%", height: "auto", maxHeight: "50vh", borderRadius: "10px" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                Phone{" "}
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h5" sx={{ width: "200px" }}>
                {data?.phone}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                Email
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h5" sx={{ width: "200px" }}>
                {data?.email}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                cities
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h5" sx={{ width: "200px" }}>
                {data?.cities?.name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                cooking time
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h5" sx={{ width: "200px" }}>
                {data?.cooking_time}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                Delivery
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h5" sx={{ width: "200px" }}>
                {data?.is_delivery_free ? (
                  <MDBadge badgeContent="free" variant="contained" container />
                ) : (
                  <MDBadge badgeContent="paid" variant="contained" container />
                )}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                Rating
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h5" sx={{ width: "200px" }}>
                {data?.rating}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                Commission
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography variant="h5" sx={{ width: "200px" }}>
                {data?.commission}
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <Typography variant="h5" sx={{ width: "200px" }}>
                restaurant_categories
              </Typography>
              <Typography variant="h5" sx={{ width: "30px" }}>
                -
              </Typography>
              <Typography
                variant="h5"
                sx={{ width: "200px", display: "flex", overflow: "hidden", flexWrap: "wrap" }}
              >
              
              </Typography>
            </Box> */}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" sx={{ marginBottom: "20px" }}>
              Opening Days
            </Typography>
            {data?.days?.map((item) => (
              <Box sx={{ display: "flex", marginBottom: "20px" }}>
                <Typography variant="h5" sx={{ width: "150px" }}>
                  {item.day}
                </Typography>
                <Typography variant="h5" sx={{ width: "30px" }}>
                  -
                </Typography>
                <Typography variant="h5" sx={{ width: "200px" }}>
                  {item.is_open ? `${item.opening_hour} - ${item.closing_hour}` : "close"}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h3" sx={{ my: 3 }}>
            Restaurant Menu Items
          </Typography>
          <Box sx={{ display: "flex", width: "560px" }}>
            <HorizontalScroll
              data={categoryData?.result?.data}
              SelectMenu={(name) => setMenuCategory(name)}
            />
            {/* <Box sx={{ width: "200px", height: "100%" }}>
              <MDBox display="flex" alignItems="center" style={{ height: "100%" }} mb={2}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label"> Menu Category</InputLabel>
                  <Select
                    sx={{ p: 1.5 }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="payment_method"
                    label="Payment Method"
                    value={menuCategory}
                    onChange={(e) => setMenuCategory(e.target.value)}
                  >
                    <MenuItem value={null}>All</MenuItem>
                    {categoryData?.result?.data?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </Box> */}
            {/* <MDButton
              type="button"
              sx={{ height: "fit-content", mx: 1 }}
              variant="gradient"
              color="info"
              onClick={() => {
                setOpen(true);
              }}
            >
              Add
            </MDButton> */}
          </Box>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Card
              sx={{
                border: "1px solid #eee",
                height: "100%",
                minHeight: "270px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedData(null);
                setOpen(true);
              }}
            >
              <Box sx={{ fontSize: "30px" }}>
                <AddIcon />
              </Box>
              <Typography variant="h5" component="div">
                Add New
              </Typography>
              {/* <CardContent sx={{ marginBottom: 0, mt: 2, p: 0, paddingLeft: "25px" }}>
                <Typography variant="h5" component="div"></Typography>
                <Typography variant="h6" sx={{ fontSize: "15px" }} component="div"></Typography>
              </CardContent> */}
            </Card>
          </Grid>
          {restaruantMenu?.result?.data.map((item, index) => {
            return (
              <Grid key={index} item xs={3}>
                <Card sx={{ border: "1px solid #eee", height: "100%" }}>
                  <CardMedia sx={{ height: 140 }} image={item.image_url} title="green iguana" />
                  <CardContent sx={{ marginBottom: 0, mt: 2, p: 0, paddingLeft: "25px" }}>
                    <Typography variant="h5" component="div">
                      {item.name}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "15px" }} component="div">
                      {item.base_price}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedData(index);
                        setOpen(true);
                      }}
                    >
                      edit
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          {}
        </Grid>
        <Box sx={{ mt: 2, float: "right" }}>
          {!isError && restaruantMenu?.result && (
            <Pagination
              count={restaruantMenu?.result?.pagination?.last_page}
              color="info"
              sx={{ float: "right" }}
              defaultPage={paginationPage}
              variant="outlined"
              onChange={(_, page) => setPaginationPage(page)}
            />
          )}
        </Box>
      </Card>
      <Card sx={{ p: 2, mt: 2 }}>
        <MDDate
          monthFilter={monthFilter}
          setMonthFilter={setMonthFilter}
          handleChangeMonthFilter={handleChangeMonthFilter}
          handleChangeDate={handleChangeDate}
          date={date}
          month={month}
        />

        <Grid item xs={12}>
          <Card>
            <Box>
              <Typography component="h1" sx={{ ml: 2, mb: 2 }}></Typography>
            </Box>
            <MDBox pt={3}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  Restaurant Sell Report
                </MDTypography>
              </MDBox>
              <DataTable
                table={{
                  columns,
                  rows: isLoadingCategories ? TableLoadingSkeleton(columns) : rows,
                }}
                isSorted={true}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Card>
      <RestaruantMenuFormModal
        data={restaruantMenu?.result?.data[selectedData]}
        open={open}
        closeBox={closeModal}
      />
    </RestaruantLayout>
  );
}

export default RestaruantDetail;
