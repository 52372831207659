import { toggleAlertBox } from "lib/store/slice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useAlertBox() {
  const alertBox = useSelector((state) => state.generalReducer.alertBox);

  useEffect(() => {
    if (alertBox.show) {
      const timeout = setTimeout(() => closeAlertBox(), 5000);
      return () => clearTimeout(timeout);
    }
  }, [alertBox.show]);

  const dispatch = useDispatch();

  function openAlertBox(type, title, content = "") {
    dispatch(toggleAlertBox({ alertBox: { type, title, content, show: true } }));
  }

  function closeAlertBox() {
    dispatch(toggleAlertBox({ close: true }));
  }
  return { openAlertBox, closeAlertBox };
}
