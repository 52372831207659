import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import PointedMarker from "./PointedMarker";
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";

function PickFromMap({ pick, values }) {
  const handleMapClick = (event) => {
    pick(event.latLng.lat(), event.latLng.lng());
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC-FyYPjARri7Anb6C7zHktw4VyClJBY1A",
  });
  const [mapRef, setMapRef] = useState();

  useEffect(() => {
    setTimeout(() => {
      setCurrentLocation({ lat: values.lat, lng: values.lng, zoom: 8 });
    }, 1000);
  }, [isLoaded]);
  const onUnmount = React.useCallback(function callback(map) {
    setMapRef(null);
  }, []);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 22.957076330056516,
    lng: 97.7614830516957,
    zoom: 7,
  });

  return (
    <div style={{ height: "600px", width: "100%" }}>
      {isLoaded && (
        <GoogleMap
          zoom={currentLocation.zoom}
          mapContainerStyle={{ height: "400px", width: "100%" }}
          onLoad={(map) => {
            setMapRef(map);
            const bounds = new window.google.maps.LatLngBounds({
              lat: 22.957076330056516,
              lng: 97.7614830516957,
            });
            if (values?.lat && values?.lng) {
              bounds.extend({ lat: values?.lat, lng: values?.lng });
            }
            map.fitBounds(bounds);
          }}
          onUnmount={onUnmount}
          onClick={handleMapClick}
        >
          {values?.lat && values?.lng && <Marker position={{ lat: values.lat, lng: values.lng }} />}
        </GoogleMap>
      )}
    </div>
  );
}

export default React.memo(PickFromMap);
