/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import {
  Box,
  Button,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import { useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";
import { postData } from "lib/fetcher";
import { useTranslation } from "react-i18next";

const columns = [
  { Header: "Deli Man", accessor: "name", align: "left" },
  { Header: "Phone", accessor: "phone", align: "left" },
  { Header: "Reason", accessor: "reason", align: "left" },
  { Header: "Admin's Reason", accessor: "admin_reason", align: "left" },
  { Header: "From", accessor: "from_date", align: "left" },
  { Header: "To", accessor: "to_date", align: "left" },
  { Header: "Status", accessor: "status", align: "left" },
  { Header: "action", accessor: "action", align: "center" },
];

const LEAVE_STATUS = [
  {
    value: "PENDING",
    label: "PENDING",
  },
  {
    value: "ACCEPTED",
    label: "ACCEPTED",
  },
  {
    value: "DENIED",
    label: "DENIED",
  },
];

function ConfirmLeaves() {
  const queryClient = useQueryClient();
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [status, setStatus] = useState("PENDING");
  const [showModal, setShowModal] = useState({ show: false, leave_id: null, status: null });
  const { openAlertBox } = useAlertBox();
  const { data, isLoading, error, isError, refetch } = useQuery(
    ["deli-leaves", selectedDate, status],
    () => getData("admin-app/delivery-man-leaves?month=" + selectedDate + "&status=" + status)
  );

  const handleDateChange = (e) => {
    setSelectedDate(ChangeMuiDate(e));
  };

  async function handleConfrimation(reason) {
    const body = {
      status: showModal.status,
      leave_id: showModal.leave_id,
      admin_reason: reason,
    };
    console.log(body);
    try {
      await postData("admin-app/confirm-delivery-man-leave", body);
      queryClient.invalidateQueries("deli-leaves");
      queryClient.invalidateQueries("deli-leave");
      refetch();
      closeModal();
      openAlertBox("success", showModal.status);
    } catch (error) {
      openAlertBox("error", error.message);
    }
  }

  function closeModal() {
    setShowModal({ show: false, leave_id: null, status: null });
  }
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ConfirmBox
        status={showModal.status}
        onConfirm={handleConfrimation}
        open={showModal.show}
        closeConfirmBox={closeModal}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("leaves")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDBox mb={2} display="flex" alignItems="center">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      sx={{ m: 2 }}
                      value={selectedDate ? selectedDate : getCurrentDate()}
                      label={t("select_month")}
                      inputFormat="YYYY-MM-DD"
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ mx: 2 }} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                  <Box>
                    <MDBox display="flex" alignItems="center">
                      <FormControl sx={{ width: "10rem" }}>
                        <InputLabel id="demo-simple-select-helper-label">{t("status")}</InputLabel>
                        <Select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          sx={{ p: 1 }}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="restaurant_menu_category_id"
                        >
                          {LEAVE_STATUS.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </Box>
                </MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading
                      ? TableLoadingSkeleton(columns)
                      : TableData(data?.result?.data, (id, status) =>
                          setShowModal({ show: true, leave_id: id, status: status })
                        ),
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ConfirmLeaves;

export function TableData(data = [], onClick) {
  return data.map((item) => {
    return {
      name: item.deliveryMan.name,
      phone: item.deliveryMan.phone,
      reason: item?.reason,
      admin_reason: item?.admin_reason,
      status: (
        <>
          <Typography
            component="h3"
            fontSize="0.8rem"
            color="#fff"
            sx={{
              backgroundColor:
                item?.status === "PENDING"
                  ? "#f0e130"
                  : item?.status === "DENIED"
                  ? "tomato"
                  : "springgreen",
              padding: "0.25rem 1rem",
              borderRadius: "5rem",
            }}
          >
            {item?.status}
          </Typography>
        </>
      ),
      form_date: item?.form_date,
      to_date: item?.to_date,
      action: (
        <div>
          {item?.status === "PENDING" && (
            <>
              <MDButton
                style={{ marginRight: "2px", marginLeft: "2px" }}
                variant="gradient"
                color="error"
                iconOnly
                onClick={() => onClick(item.id, "DENIED")}
              >
                <Icon>close_icon</Icon>
              </MDButton>
              <MDButton
                style={{ marginRight: "2px", marginLeft: "2px" }}
                variant="gradient"
                color="success"
                iconOnly
                onClick={() => onClick(item.id, "ACCEPTED")}
              >
                <Icon>check_icon</Icon>
              </MDButton>
            </>
          )}
        </div>
      ),
    };
  });
}

function ConfirmBox({ open, onConfirm, closeConfirmBox, status }) {
  const [note, setNote] = useState("");
  return (
    <Modal
      open={open}
      onClose={closeConfirmBox}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          minHeight: "5rem",
          width: "100%",
          maxWidth: "25rem",
          outline: "none",
          borderRadius: "0.5rem",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <Typography fontSize="1rem" marginBottom="2rem" fontWeight={700}>
          Leave will be {status}
        </Typography>
        <TextField
          label="Add Note"
          sx={{ width: "100%" }}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "4rem" }}>
          <Button onClick={closeConfirmBox} variant="text" size="medium" color="success">
            Cancel
          </Button>
          <MDButton
            onClick={() => onConfirm(note)}
            variant="contained"
            color="warning"
            size="medium"
          >
            Proceed
          </MDButton>
        </Stack>
      </Box>
    </Modal>
  );
}
