import { Skeleton, Stack } from "@mui/material";
import React from "react";

export default function TableLoadingSkeleton(columns = []) {
  let obj = {};
  columns.forEach((col) => {
    if (col.accessor === "action") {
      obj.action = (
        <Stack direction="row" gap={1} sx={{ marginRight: "3rem" }}>
          <Skeleton variant="rounded" width={40} height={40} sx={{ borderRadius: "0.5rem" }} />
          <Skeleton variant="rounded" width={40} height={40} sx={{ borderRadius: "0.5rem" }} />
          <Skeleton variant="rounded" width={40} height={40} sx={{ borderRadius: "0.5rem" }} />
        </Stack>
      );
      return;
    }
    obj[col.accessor] = (
      <Skeleton variant="rounded" width={140} height={30} sx={{ borderRadius: "0.25rem" }} />
    );
  });
  let skeletons = [];
  for (let index = 0; index < 10; index++) {
    skeletons.push(obj);
  }
  return skeletons;
}
