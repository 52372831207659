/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Avatar, Box, Icon, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { postData } from "lib/fetcher";
import { useTranslation } from "react-i18next";

function Setting() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const closeBox = () => setOpen(false);
  const [editData, setEditData] = useState();

  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();

  const {
    data: categories,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery("setting", () => getData("/admin-app/app-settings"), {
    refetchOnWindowFocus: false,
  });
  const columns = [
    { Header: "Description", accessor: "description", align: "center" },
    { Header: "Setting", accessor: "setting", align: "center" },
    { Header: "value", accessor: "value", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  const rows = [];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );
  const { t } = useTranslation();
  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          setOpen(true);
          setEditData(value);
        }}
        iconOnly
      >
        <Icon>edit</Icon>
      </MDButton>
    </MDBox>
  );
  categories?.result?.map((value) => {
    rows.push({
      description: <TableRowField align="left" title={value.des} />,
      setting: <TableRowField align="left" title={value.setting} />,
      value: <TableRowField align="left" title={value.value} />,
      action: <ActionField align="left" value={value} />,
    });
  });

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("admin-app/restaurant-menu-categories/" + id);
      openAlertBox("success", "Delete Success", "You have successfully deleted the Delivery Man");
      queryClient.invalidateQueries("restaruant-menu-category");
    } catch (error) {
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
      console.log(error);
    }
  };
  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this restaruant menu category', id);
  };

  // const handleDetail = async (id) => {
  //   console.log("id before", id)
  //   // try {
  //   //   console.log("id before", id)
  //   //   await deleteData("admin-app/delivery-men/" + id);
  //   //   queryClient.invalidateQueries("delivery-men");
  //   //   console.log("id", id)
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // };

  // const handleEdit = async (id) => {
  //     navigate(`/delivery-men-edit/${id}`);
  // };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("Setting")}
                </MDTypography>
                {/* <NavLink key={"restaruant-category-form"} to={"/restaruant-menu-category/create"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new
                  </MDButton>
                </NavLink> */}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <EditSetting open={open} closeBox={closeBox} data={editData} />
      <Footer />
    </DashboardLayout>
  );
}
const EditSetting = ({ open, closeBox, data }) => {
  const { openAlertBox } = useAlertBox();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: updateSettingMutate } = useMutation({
    mutationFn: (body) =>
      postData(`/admin-app/app-settings/${data?.id}`, {
        ...body,
      }),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the setting");
      queryClient.invalidateQueries("setting");
      closeBox();
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  const [inputValue, setInputValue] = useState({
    value: "",
  });
  useEffect(() => {
    if (data) {
      setInputValue({
        value: data?.value,
      });
    }
  }, [data]);
  const handleInputChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (data) {
      updateSettingMutate({ ...inputValue, _method: "PUT" });
      return;
    }

    setInputValue({
      name: "",
      price: "",
    });
    return;
  };

  return (
    <Modal
      open={open}
      onClose={closeBox}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          maxWidth: "25rem",
          height: "auto",
          outline: "none",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
      >
        <MDTypography id="Are you sure ?" color="warning" variant="h4">
          {t("Setting")} {t("edit")}
        </MDTypography>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12} sx={{ p: 3, pb: 0, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.value}
                onChange={handleInputChange}
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Value")}
                name="value"
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ px: 3, alignItems: "center" }}>
            <MDButton
              type="button"
              sx={{ height: "fit-content", mx: 1 }}
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleSubmit}
            >
              {t("add")}
            </MDButton>
          </Grid>
          <Grid item xs={6} sx={{ px: 3, alignItems: "center" }}>
            <MDButton
              type="button"
              height={{ height: "fit-content" }}
              variant="gradient"
              color="warning"
              fullWidth
              onClick={closeBox}
            >
              {t("cancel")}
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default Setting;
