import { Box, Button, Card, Grid, Modal, Stack, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useCallback, useRef, useState } from "react";
import MDInput from "components/MDInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import RestaruantLayout from "./RestaruantLayout";
import RestaurantMarker from "layouts/map/RestaurantMarker";
import GoogleMapReact from "google-map-react";
import UploadImage from "components/Utils/ImageUpload";
import { postImageDataRes } from "lib/fetcher";
import { useTranslation } from "react-i18next";
import { convertToPhoneDigit } from "lib/helper";
import PickFromMap from "layouts/map/PickFromMap";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const initalData = {
  name: "",
  email: "",
  phone: "",
  address: "",
  city_id: "",
  latitude: "",
  longitude: "",
  is_open: false,
  is_delivery_free: false,
  restaurant_category_ids: [],
  password: "",
  password_confirmation: "",
  commission: "",
};
function RestaruantForm() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState(
    state
      ? {
          ...state,
          city_id: state?.cities?.id,
          latitude: +state?.latitude,
          longitude: +state?.longitude,
        }
      : initalData
  );
  console.log(state, "inputValue");
  const { data: categoryData, isLoading } = useQuery(
    "category",
    () => getData(`/admin-app/restaurant-categories`),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: cityData } = useQuery("city", () => getData(`/setting/cities`), {
    refetchOnWindowFocus: false,
  });
  const theme = useTheme();
  const { openAlertBox } = useAlertBox();
  const [category, setCategory] = React.useState(
    state ? state?.restaurant_categories?.map((item) => item.id) : []
  );
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const handleCheckInput = (event) => {
    const { name, checked } = event.target;

    setInputValue({ ...inputValue, [name]: checked });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value, "fdfwe");
    setCategory(value);
  };
  const queryClient = useQueryClient();

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/restaurants/${state?.id}`, body),
    onSuccess: () => {
      if (image && typeof image === "object") {
        const formData = new FormData();
        formData.append("image", image);

        updateImageMutate({ formData, id: state?.id });
      } else {
        openAlertBox("success", " Success", "You have successfully updated the restaurant");
        queryClient.invalidateQueries("restaruant");
        navigate("/restaruant-management");
      }
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  const { mutate: updateImageMutate, isLoading: updateImageLoading } = useMutation({
    mutationFn: (body) => postImageDataRes(`/admin-app/restaurants/update-profile-image/`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", " successfully ");
      queryClient.invalidateQueries("restaruant");
      navigate("/restaruant-management");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/restaurants`, body),
    onSuccess: (data) => {
      if (image && typeof image === "object") {
        const formData = new FormData();
        formData.append("image", image);
        updateImageMutate({ formData, id: data?.result?.id });
      } else {
        openAlertBox("success", " Success", "You have successfully create the restaurant");
        queryClient.invalidateQueries("restaruant");
        navigate("/restaruant-management");
      }
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue);
    if (state) {
      updateMutate({
        name: inputValue.name,
        phone: inputValue.phone ? convertToPhoneDigit(inputValue.phone) : "",
        email: inputValue.email,
        address: inputValue.address,
        // is_open: inputValue.is_open === true ? 1 : 0,
        is_delivery_free: inputValue.is_delivery_free === true ? 1 : 0,
        restaurant_category_ids: category,
        _method: "PUT",
        latitude: inputValue.latitude.toString() || "0.00000001",
        longitude: inputValue.longitude.toString() || "0.00000001",
        city_id: inputValue.city_id,
        is_special_one_ad: 0,
        commission: inputValue.commission,
      });
      // const formData = new FormData();
      // formData.append("name", inputValue.name);
      // formData.append("phone", inputValue.phone);
      // formData.append("email", inputValue.email);
      // formData.append("address", inputValue.address);
      // formData.append(" restaurant_category_ids", JSON.stringify(category));
      // formData.append("is_delivery_free", inputValue.is_delivery_free === true ? 1 : 0);
      // formData.append("latitude", marker.lat);
      // formData.append("longitude", marker.lng);
      // formData.append("city_id", inputValue.city_id);
      // formData.append("is_special_one_ad", 0);
      // formData.append("_method", "PUT");
      // if (image && typeof image === "object") {
      //   formData.append("image", image);
      // }
      // updateMutateImage(formData);
    } else {
      createMutate({
        name: inputValue.name,
        phone: inputValue.phone ? convertToPhoneDigit(inputValue.phone) : "",
        email: inputValue.email,
        address: inputValue.address,
        // is_open: inputValue.is_open === true ? 1 : 0,
        is_delivery_free: inputValue.is_delivery_free === true ? 1 : 0,
        restaurant_category_ids: category,
        latitude: inputValue.latitude.toString() || "0.00000001",
        longitude: inputValue.longitude.toString() || "0.00000001",
        city_id: inputValue.city_id,
        is_special_one_ad: 0,
        password: inputValue.password,
        password_confirmation: inputValue.password_confirmation,
        commission: inputValue.commission,
      });
      // const formData = new FormData();
      // formData.append("name", inputValue.name);
      // formData.append("phone", inputValue.phone);
      // formData.append("email", inputValue.email);
      // formData.append("address", inputValue.address);
      // formData.append(" restaurant_category_ids", JSON.stringify(category));
      // formData.append("is_delivery_free", inputValue.is_delivery_free === true ? 1 : 0);
      // formData.append("latitude", marker.lat);
      // formData.append("longitude", marker.lng);
      // formData.append("city_id", inputValue.city_id);
      // formData.append("is_special_one_ad", 0);
      // formData.append("password", "123456");
      // formData.append("password_confirmation", "123456");
      // if (image && typeof image === "object") {
      //   formData.append("image", image);
      // }
      // createMutate(formData);
    }
  };

  const onMapClick = useCallback(({ x, y, lat, lng, event }) => {
    setInputValue((current) => {
      return {
        ...current,
        latitude: lat,
        longitude: lng,
      };
    });
  });
  const [marker, setMarker] = useState(
    state
      ? { lat: state?.latitude || 22.957076330056516, lng: state?.longitude || 97.7614830516957 }
      : null
  );
  const mapRef = useRef(null);
  const [image, setImage] = useState(state ? state.image_url : null);
  const [open, setOpen] = useState(false);
  const { mutate: passwordMutate, isLoading: passwordLoading } = useMutation({
    mutationFn: (body) => postData(`admin-app/reset-password`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the restaurant");
      setOpen(false);
    },

    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const [inputPassword, setInputPassword] = useState({
    password: "",
    password_confirmation: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;

    setInputPassword((current) => {
      return {
        ...current,
        [name]: value,
      };
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (inputPassword.password !== inputPassword.password_confirmation) {
      openAlertBox("error", "Something went wrong", "Password and confirm password not match");
    } else {
      passwordMutate({
        id: state.id,
        password: inputPassword.password,
        password_confirmation: inputPassword.password_confirmation,
        login_app: "SHOP_OWNER_APP",
      });
    }
  };
  const { t } = useTranslation();
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h3" sx={{ mb: 4 }}>
            {state ? `${t("Restaurant")} ${t("edit")}` : `${t("Restaurant")} ${t("add")}`}
          </Typography>

          {state && (
            <MDButton
              style={{ marginRight: "2px", marginLeft: "2px" }}
              variant="gradient"
              color="warning"
              onClick={() => {
                setOpen(true);
              }}
            >
              {t("password")} {t("reset")}
            </MDButton>
          )}
        </Box>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={5} sx={{ p: 3, alignItems: "center" }}>
            <UploadImage
              setUploadImage={setImage}
              title="Restaurant Image"
              defaultImg={image}
              mHeight="80vh"
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6} sx={{ p: 3 }}>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <MDInput
                value={inputValue?.name}
                onChange={inputChangeHandler}
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Name")}
                name="name"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <MDInput
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Phone")}
                name="phone"
                value={inputValue?.phone}
                onChange={inputChangeHandler}
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <MDInput
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Email")}
                name="email"
                value={inputValue?.email}
                required
                type="email"
                onChange={inputChangeHandler}
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <MDInput
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Cooking Time")}
                name="Cooking Time"
                required
                type="text"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <MDInput
                label={t("Address")}
                multiline
                rows={5}
                sx={{ width: "100%" }}
                value={inputValue?.address}
                onChange={inputChangeHandler}
                name="address"
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ p: 3 }}>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("city")}</InputLabel>
                <Select
                  sx={{ height: 45 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputValue?.city_id || inputValue?.cities?.id}
                  label={t("city")}
                  name="city_id"
                  onChange={inputChangeHandler}
                >
                  {cityData?.result?.map((city) => (
                    <MenuItem key={city?.id} value={city?.id}>
                      {city?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", marginBottom: "25px" }}>
              {!isLoading && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Category">{t("category")}</InputLabel>
                  <Select
                    required
                    labelId="Category"
                    id="demo-multiple-chip"
                    sx={{ height: 45 }}
                    multiple
                    value={category}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              categoryData?.result?.data.filter((el) => el.id === value)[0]?.name
                            }
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {categoryData?.result?.data?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            {!state && (
              <>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <MDInput
                    sx={{ width: "100%" }}
                    variant="outlined"
                    label={t("password")}
                    name="password"
                    value={inputValue?.password}
                    onChange={inputChangeHandler}
                  />
                </Box>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <MDInput
                    sx={{ width: "100%" }}
                    variant="outlined"
                    label={`Confirm ${t("password")}`}
                    name="password_confirmation"
                    value={inputValue?.password_confirmation}
                    onChange={inputChangeHandler}
                  />
                </Box>
              </>
            )}
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <MDInput
                type="number"
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Commission")}
                name="commission"
                value={inputValue?.commission}
                onChange={inputChangeHandler}
              />
            </Box>
          </Grid>
        </Grid>
        <div style={{ height: "500px", width: "100%" }}>
          <PickFromMap
            pick={(lat, lng) => {
              setInputValue((current) => {
                return {
                  ...current,
                  latitude: lat,
                  longitude: lng,
                };
              });
            }}
            values={{ lat: inputValue?.latitude, lng: inputValue?.longitude }}
          />
        </div>

        <MDBox mb={1} mt={3} sx={{ float: "right" }}>
          <MDButton
            disabled={isLoading}
            onClick={handleSubmit}
            variant="gradient"
            color="primary"
            fullWidth
          >
            {isLoading ? "Submiting..." : t("submit")}
          </MDButton>
        </MDBox>
      </Card>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            minHeight: "15rem",
            width: "100%",
            maxWidth: "40rem",
            outline: "none",
            borderRadius: "0.5rem",
            padding: "1rem",
            textAlign: "center",
          }}
          component="form"
          onSubmit={handleResetPassword}
        >
          <Typography
            textAlign="left"
            component="h4"
            fontSize="1.1rem"
            color="info"
            fontWeight={600}
            sx={{ marginBottom: "1rem", color: theme.palette.info.main }}
          >
            {t("password")} {t("change")}
          </Typography>

          <MDInput
            type="password"
            name="password"
            label="Password"
            fullWidth
            onChange={handleInput}
            sx={{ marginBottom: "1rem" }}
          />
          <MDInput
            type="password"
            name="password_confirmation"
            onChange={handleInput}
            label="Password Confirmation"
            fullWidth
          />

          <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "2rem" }}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="text"
              size="medium"
              color="success"
            >
              Cancel
            </Button>
            <MDButton
              type="submit"
              disabled={passwordLoading}
              variant="contained"
              color="info"
              size="medium"
            >
              {isLoading ? "Saving..." : t("submit")}
            </MDButton>
          </Stack>
        </Box>
      </Modal>
    </RestaruantLayout>
  );
}
export default RestaruantForm;
