import React, { useState } from "react";

import classes from "./HorizontalScroll.module.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const HorizontalScroll = ({ data, SelectMenu }) => {
  const btnPrev = () => {
    let container = document.querySelector("#scroll");
    let width = container.clientWidth;
    container.scrollLeft = container.scrollLeft - width + 200;
    console.log(container.scrollLeft);
  };
  const btnNext = () => {
    let container = document.querySelector("#scroll");
    let width = container.clientWidth;
    container.scrollLeft = container.scrollLeft + width - 200;
    console.log(container.scrollLeft);
  };
  const [activeTab, setActiveTab] = useState("all");
  return (
    <div style={{ width: "100%" }} className={`${classes["nav"]}`}>
      <div className={classes.prev}>
        <ArrowBackIosNewIcon onClick={btnPrev}></ArrowBackIosNewIcon>
      </div>

      <div className={`${classes["live_category"]}`} id="scroll">
        <div
          className={` ${classes["lc-item"]}   ${activeTab === "all" && classes["lc_active"]} `}
          onClick={() => {
            SelectMenu(null);
            setActiveTab("all");
          }}
        >
          all
        </div>

        {data?.map((el, index) => {
          return (
            <div
              key={index}
              className={` ${classes["lc-item"]}   ${activeTab == index && classes["lc_active"]} `}
              onClick={() => {
                SelectMenu(el?.id);
                setActiveTab(index);
              }}
            >
              {el?.name}
            </div>
          );
        })}
      </div>
      <div className={classes.next}>
        <ArrowForwardIosIcon onClick={btnNext}></ArrowForwardIosIcon>
      </div>
    </div>
  );
};

export default HorizontalScroll;
