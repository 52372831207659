import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Switch,
  Typography,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect } from "react";

import MDInput from "components/MDInput";

import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import RestaruantLayout from "layouts/RestaruantManagement/RestaruantLayout";
import UploadImage from "components/Utils/ImageUpload";
import { postImageData } from "lib/fetcher";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const initalData = {
  order: 0,
  available: false,
};
function PaymentMethodForm() {
  const { state } = useLocation();
  console.log(state, "state");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState(
    state ? { order: state?.order, available: state?.available == 1 ? true : false } : initalData
  );
  const theme = useTheme();
  const { openAlertBox } = useAlertBox();
  const [category, setCategory] = React.useState(
    state ? state?.restaurant_categories?.map((item) => item.id) : []
  );
  const [image_url, setImageUrl] = React.useState(state ? state?.image_url : "");
  const [thumb_url, setThumbUrl] = React.useState(state ? state?.thumb_url : "");
  // const [, setImageUrl] = React.useState(state ? state?.image_url : "");
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const queryClient = useQueryClient();
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/payment-methods/${state?.id}`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the payment method");
      queryClient.invalidateQueries("payment-method");
      navigate("/payment-method");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/restaurant-menu-categories`, body),
    onSuccess: () => {
      openAlertBox(
        "success",
        " Success",
        "You have successfully created the restaurant menu category"
      );
      queryClient.invalidateQueries("restaruant-menu-category");
      navigate("/restaruant-menu-category");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue);

    if (state) {
      updateMutate({
        order: inputValue.order,
        available: inputValue.available ? 1 : 0,
      });
    } else {
      createMutate({
        name: inputValue.name,
      });
    }
  };
  const handleCheckInput = (event) => {
    const { name, checked } = event.target;

    setInputValue({ ...inputValue, [name]: checked });
  };
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state ? "Payment Type Update" : "Payment Type Create"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ p: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.order}
                onChange={inputChangeHandler}
                sx={{ width: "100%" }}
                variant="outlined"
                type="number"
                label="Order"
                name="order"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <FormControlLabel
                value="top"
                control={
                  <Switch
                    checked={inputValue?.available}
                    onChange={handleCheckInput}
                    name="available"
                  />
                }
                label="Available"
              />
            </Box>
          </Grid>
        </Grid>
        <MDBox mb={1} sx={{ float: "right" }}>
          <MDButton
            disabled={updateLoading || createLoading}
            onClick={handleSubmit}
            variant="gradient"
            color="primary"
            fullWidth
          >
            {updateLoading || createLoading ? "Submiting..." : "Submit"}
          </MDButton>
        </MDBox>
      </Card>
    </RestaruantLayout>
  );
}
export default PaymentMethodForm;
