/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Avatar, Box, Button, Icon, Pagination, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import MDBadge from "components/MDBadge";
import { useState } from "react";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { postData } from "lib/fetcher";
import { useTranslation } from "react-i18next";

function RestaurantCreateForm() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const [paginationPage, setPaginationPage] = useState(1);
  const { openConfirmBox } = useConfirmBox();
  const [selectedDate, setSelectedDate] = useState(null);
  const {
    data: form,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery(
    ["restaruant-form", paginationPage, selectedDate],
    () =>
      // getData(
      //   `admin-app/get-restaurant-form-requests?date=${
      //     selectedDate ? selectedDate : getCurrentDate()
      //   }?page=${paginationPage}`
      // ),
      getData(
        `admin-app/get-restaurant-form-requests?date=${
          selectedDate ? selectedDate : getCurrentDate()
        }&page=${paginationPage || 1}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const handleDateChange = (e) => {
    setSelectedDate(ChangeMuiDate(e));
    console.log(ChangeMuiDate(e));
  };

  const columns = [
    { Header: "Name", accessor: "name", align: "center" },
    { Header: "Phone", accessor: "phone", align: "center" },
    { Header: "Email", accessor: "email", align: "center" },
    { Header: "Address", accessor: "address", align: "center" },
    { Header: "City", accessor: "city", align: "center" },
    { Header: "Image", accessor: "image", align: "center" },
    { Header: "Restaurant Categories", accessor: "restaurant_categories", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  const rows = [];
  const TableRowField = ({ title }) => {
    return (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    );
  };
  const TableRestaurantCategory = ({ data }) => (
    <MDBox
      display="flex"
      alignItems="center"
      style={{ flexWrap: "wrap", width: "100px" }}
      mt={{ xs: 2, sm: 0 }}
      ml={{ xs: -1.5, sm: 0 }}
    >
      {data.map((value) => (
        <MDBadge sx={{ mx: 1, mb: 1 }} badgeContent={value.name} variant="gradient" container />
      ))}
    </MDBox>
  );
  const TableRowImage = ({ title: src }) => (
    <a style={{ cursor: "pointer" }} href={src} target="_blank" rel="noreferrer">
      <img
        alt="Remy Sharp"
        src={
          src === ""
            ? "http://k2key.in/invesnew/wp-content/uploads/2019/06/profile-default.png"
            : src
        }
        style={{ width: "60px", height: "60px" }}
      />
    </a>
  );

  const ActionField = ({ value }) =>
    value?.mark_as_done ? (
      <MDBadge sx={{ mx: 1, mb: 1 }} badgeContent="success" color="success" container />
    ) : (
      <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
        <MDButton
          size="small"
          style={{ marginRight: "2px", marginLeft: "2px" }}
          variant="gradient"
          color="warning"
          onClick={() => {
            console.log("valuesss", value);
            handleComfirm(value.id);
          }}
        >
          Confirm
        </MDButton>
      </MDBox>
    );
  form?.result?.data?.map((value) => {
    console.log("value", value);
    rows.push({
      name: <TableRowField align="left" title={value.name} />,
      phone: <TableRowField align="left" title={value.phone} />,
      email: <TableRowField align="left" title={value.email} />,
      address: <TableRowField align="left" title={value.address} />,
      city: <TableRowField align="left" title={value?.city?.name} />,
      image: <TableRowImage title={value.thumb_url} />,
      restaurant_categories: <TableRestaurantCategory data={value?.restaurant_categories} />,
      action: <ActionField align="left" value={value} />,
    });
  });

  const ComfirmApiHandler = async (id) => {
    try {
      await postData("/admin-app/create-restaurant-owner-account", {
        restaurant_request_form_id: id,
      });
      openAlertBox("success", "Delete Success", "You have successfully create restuarnat");
      queryClient.invalidateQueries("restaruant-form");
    } catch (error) {
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
      console.log(error);
    }
  };
  const handleComfirm = (id) => {
    openConfirmBox(ComfirmApiHandler, 'Clik "Confirm" to confirm this restaruant', id);
  };
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("Restaruant Form")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      sx={{ m: 2 }}
                      value={selectedDate ? selectedDate : getCurrentDate()}
                      label={t("date")}
                      inputFormat="YYYY-MM-DD"
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ mx: 2 }} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </MDBox>
                {!isError && (
                  <DataTable
                    table={{
                      columns,
                      rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                    }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Box sx={{ mt: 2, float: "right" }}>
        {!isError && form?.result && (
          <Pagination
            count={form?.result?.pagination?.last_page}
            color="info"
            sx={{ float: "right" }}
            defaultPage={paginationPage}
            variant="outlined"
            onChange={(_, page) => setPaginationPage(page)}
          />
        )}
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default RestaurantCreateForm;
