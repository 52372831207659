import React from "react";
import { Pagination as MUIPagination } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Pagination({ totalPageCount = 1 }) {
  const [params] = useSearchParams();
  const currentPage = params.get("page");
  const navigate = useNavigate();

  function handlePageChange(newPageValue) {
    console.log("newPageValue", newPageValue)
    navigate({ search: "?page=" + newPageValue });
  }

  return (
    <div style={{ paddingBlock: "1rem", display: "flex", justifyContent: "center", width: "100%" }}>
      <MUIPagination
        count={totalPageCount}
        color="info"
        defaultPage={+currentPage || 1}
        variant="outlined"
        onChange={(_, page) => handlePageChange(page)}
      />
    </div>
  );
}
