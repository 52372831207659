import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../../global.css";

import { db } from "lib/firebase";
import { styled } from "@mui/material";

const Section = styled("section")`
  width: 100%;
  height: 10rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (min-width: 1024px) {
    height: 35rem;
  }
  .swiper {
    height: 10rem;
    @media screen and (min-width: 1024px) {
      height: 35rem;
    }
  }
`;

export default function HomeBanner() {
  const [banners, setBanners] = useState([]);
  async function getBanners() {
    try {
      const q = query(collection(db, "banners"), orderBy("order", "asc"));
      const res = await getDocs(q);
      const arr = [];
      res.forEach((doc) => arr.push(doc.data()));
      setBanners(arr);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getBanners();
  }, []);
  return (
    <Section>
      <Swiper
        modules={[Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className="swiper"
        loop
        autoplay={true}
      >
        {banners?.map((banner) => (
          <SwiperSlide key={banner.id}>
            <figure>
              <img src={banner.url} alt="" />
            </figure>
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  );
}
