import {
  Box,
  Card,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import RestaruantLayout from "layouts/RestaruantManagement/RestaruantLayout";
import { useTranslation } from "react-i18next";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getCurrentDate } from "lib/helper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getData } from "lib/fetcher";
import { ChangeMuiDate } from "lib/helper";

const initalData = {
  title: "",
  amount: "",
};
function AdditionalIncomeForm() {
  const { state } = useLocation();
  console.log(state, "state");
  const handleDateChange = (e) => {
    setSelectedDate(ChangeMuiDate(e));
  };
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [about, setAbout] = useState("");
  const [amount, setAmount] = useState("");
  useEffect(() => {
    if (state) {
      setSelectedDate(state?.order_date);
      setPaymentMethod(state?.payment_method?.id);
      setAbout(state?.about);
      setAmount(state?.amount);
    }
  }, [state]);
  const theme = useTheme();
  const { openAlertBox } = useAlertBox();
  // const [, setImageUrl] = React.useState(state ? state?.image_url : "");

  const queryClient = useQueryClient();
  const { data: paymentMethodData, isLoading: paymentMethodLoading } = useQuery(
    "payment-methods",
    () => getData(`/admin-app/payment-methods`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/additional-incomes/${state?.id}`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated ");
      queryClient.invalidateQueries("additional");
      navigate("/additionalIncome");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/additional-incomes`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully created ");
      queryClient.invalidateQueries("additional");
      navigate("/additionalIncome");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    if (state) {
      updateMutate({
        about,
        amount,
        order_date: selectedDate,
        payment_method_id: paymentMethod,
        _method: "PUT",
      });
    } else {
      createMutate({
        about,
        amount,
        order_date: selectedDate || getCurrentDate(),
        payment_method_id: paymentMethod,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state ? `${"AdditionalIncome"} ${t("edit")}` : `${"AdditionalIncome"} ${t("add")}`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ p: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "5px", alignItems: "center" }}>
              <MDInput
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                sx={{ width: "100%" }}
                variant="outlined"
                label={"Amount"}
                name="amount"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "5px" }}></Box>
          </Grid>
          <Grid item xs={6} sx={{ p: 3, alignItems: "center", width: "100%" }}>
            <Box sx={{ display: "flex", marginBottom: "5px", width: "100%", alignItems: "center" }}>
              <MDBox display="flex" alignItems="center" sx={{ width: "100%" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {t("Payment Method")}
                  </InputLabel>
                  <Select
                    sx={{ p: 1.5, width: "100%" }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="payment_method"
                    value={paymentMethod}
                    // defaultValue={data?.payment_method?.id}
                    label="Payment Method"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    // onChange={(e) =>
                    //   setData((prev) => {
                    //     return { ...prev, payment_method: e.target.value };
                    //   })
                    // }
                  >
                    {/* <MenuItem value="all">Please Select Payment Method</MenuItem> */}
                    {paymentMethodData?.result?.data.map((option) => (
                      <MenuItem value={option.id}>{option.provider}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "5px" }}></Box>
          </Grid>
          <Grid item xs={6} sx={{ p: 1, alignItems: "center" }}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDInput
                name="about"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                multiline
                rows={3}
                // defaultValue={data.pick_up_note}
                // onChange={(e) =>
                //   setData((prev) => {
                //     return { ...prev, pick_up_note: e.target.value };
                //   })
                // }
                type="text"
                label="about"
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={6} sx={{ p: 1, alignItems: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={{ m: 2 }}
                value={selectedDate ? selectedDate : getCurrentDate()}
                label={t("date")}
                inputFormat="YYYY-MM-DD"
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} sx={{ mx: 2 }} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <MDBox mb={1} sx={{ float: "right" }}>
          <MDButton
            disabled={updateLoading || createLoading}
            onClick={handleSubmit}
            variant="gradient"
            color="primary"
            fullWidth
          >
            {updateLoading || createLoading ? "Submiting..." : t("submit")}
          </MDButton>
        </MDBox>
      </Card>
    </RestaruantLayout>
  );
}
export default AdditionalIncomeForm;
