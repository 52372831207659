import { postData } from "lib/fetcher";
import { getErrorMessage } from "lib/helper";
import { controlAuth } from "lib/store/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAlertBox from "./useAlertBox";

export default function useAuth() {
  const { openAlertBox } = useAlertBox();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleLogin(credentials) {
    const formData = new FormData();
    formData.append("email", credentials.email);
    formData.append("password", credentials.password);
    formData.append("login_app", "ADMIN_APP");
    try {
      const response = await postData("login", formData);

      if (response.result && response.result.token) {
        localStorage.setItem("tkn", response.result.token);
        localStorage.setItem("urs", response.result.auth_user);
        openAlertBox("success", "Login Success", "You have successfully logged in");
        return { success: true };
      }
    } catch (error) {
      openAlertBox("error", "Login Failed", getErrorMessage(error.message));
    }
  }

  function logout() {
    localStorage.removeItem("tkn");
    localStorage.removeItem("urs");
    dispatch(controlAuth({ auth: false, info: null }));
    openAlertBox("success", "Logout Success", "You have successfully logged out");
    navigate("/authentication/sign-in");
  }

  return { handleLogin, logout };
}
