import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translations
const resources = {
  en: {
    translation: {
      //Dashboard
      Dashboard: "Dashboard",
      free: "Free",
      Finance: "Finance",
      add_office_order: "Add office order",
      date: "Date",
      office_orders: "Office orders",
      parcel_orders: "Parcel orders",
      food_orders: "Food orders",
      Riders: "Riders",
      see_all: "See all",
      all: "All",
      giveaway_orders: "Giveaway orders",
      check_riders_on: "Check riders on",
      map: "Map",
      way: "Way",
      edit: "Edit",
      copy: "Copy",
      delete: "Delete",
      add: "Add",
      status: "Status",
      Orders: "Orders",
      "Office Order": "Office Order",
      "Food Order": "Food Order",
      "Parcel Delivery": "Parcel Delivery",
      Restaurant: "Restaurant",
      Management: "Management",
      "Restaruant Category": "Restaruant Category",
      "Restaruant Menu": "Restaruant Menu",
      "Restaruant Form": "Restaruant Form",
      "Menu Category": "Menu Category",
      "Accept Form": "Accept Form",
      "Riders on Map": "Riders on Map",
      General: "General",
      "Payment Method": "Payment Method",
      "Order From": "Order From",
      Recommendation: "Recommendation",
      "Promo Code": "Promo Code",
      "Header Ads": "Header Ads",
      "Special Ads": "Special Ads",
      Places: "Places",
      Map: "Map",
      "Place And Prices": "Place And Prices",
      "User Address Places": "User Address Places",
      Wards: "Wards",
      "User Site": "User Site",
      Banners: "Banners",
      Sections: "Sections",
      Posts: "Posts",
      Chat: "Chat",
      Users: "Users",
      Setting: "Setting",
      //Home Page
      "ADD Office Order": "ADD Office Order",
      Rider: "Rider",
      "Check Riders on ": "Check Riders on ",
      "Map View": "Map View",
      // Office Order
      "Order ID": "Order ID",
      "Delivery Price": "Delivery Price",
      "Delivery Man": "Delivery Man",
      "Pickup Name": "Pickup Name",
      "Drop Off Name": "Drop Off Name",
      "Order Date": "Order Date",
      Action: "Action",
      // Food Order
      User: "User",
      "Delivery Man ID": "Delivery Man ID",
      // Express
      Expense: "Expense",
      Title: "title",
      Amount: "Amount",
      Date: "Date",
      // Income
      Income: "Income",
      "Daily Income": "Income",
      "Monthly Income": "Monthly Income",
      "Income List Table": "Income List Table",
      Commission: "Commission",
      "Delivery Fee": "Delivery Fee",
      deli_income: "Delivery Income",
      Total: "Total",
      // Restaurant
      Phone: "Phone",
      Email: "Email",
      Address: "Address",
      Cities: "Cities",
      "Cooking Time": "Cooking Time",
      Delivery: "Delivery",
      Rating: "Rating",
      Name: "Name",
      Image: "Image",
      "Restaurant Categories": "Restaurant Name",
      // Rider
      "Manage Account": "Manage Account",
      "Way Records": "Way Records",
      Leave: "Leave",
      "Parcel Order": "Parcel Order",
      "Deli Man": "Deli Man",
      Reason: "Reason",
      "Admin Reason": "Admin Reason",
      "Leave Date": "Leave Date",
      Status: "Status",
      // General
      "Payment Method Table": "Payment Method Table",
      Provider: "Provider",
      Available: "Available",
      Order: "Order",
      Method: "Method",
      "Order From Table": "Order From Table",
      "Start Date": "Start Date",
      Code: "Code",
      DiscountPrice: "DiscountPrice",
      IsPercentage: "IsPercentage",
      Limit: "Limit",
      IsAvailable: "IsAvailable",
      "Restaurant Id": "Restaurant Id",
      //
      Latitude: "Latitude",
      Longitude: "Longitude",
      Detail: "Detail",
      from: "From Place",
      to: "To Place",
      Price: "Price",
      link: "link",
      //Office order create
      pick_up_name: "Pick up name",
      pick_up_phone: "Pick up phone",
      pick_up_address: "Pick up address",
      pick_up_note: "Pick up note",
      drop_off_name: "Drop off name",
      drop_off_phone: "Drop off phone",
      drop_off_address: "Drop off address",
      drop_off_note: "Drop off note",
      first: "First",
      second: "Second",
      third: "Third",
      submit: "Submit",
      add_image: "Add Image",
      select_deli: "Select Delivery Man",
      //Restaurant Management
      "Restaurant Management": "Restaurant Management",
      total_orders: "Total Orders",
      total_sale: "Total Sales",
      search: "Search",
      city: "City",
      category: "Category",
      password: "Password",
      reset: "Reset",
      change: "Change",
      //Rider
      all_way: "All Way",
      fo_way: "Food Order Way",
      gw_deli: "Give Way",
      pd_way: "Parcel Way",
      total_way: "Total Way",
      other_phones: "Other Phones",
      rwr: "Recent Way Record",
      usage: "Usage",
      all_usage: "All Usage",
      all_records: "All Records",
      bs: "Base Salary",
      ws: "Salary by way",
      petro: "Petroleum",
      pb: "Phone Bill",
      pa: "Pre Advanced",
      leaves: "Leaves",
      select_month: "Select Month",
      //Places
      place: "Place",
      places: "Places",
      desc: "Description",
      pap: "Place And Price",
      paps: "Places And Prices",
      us_ad: "User Address",
      uss_ad: "Users Address",
      ward: "Ward",
      riders: "Riders",
      restaurants: "Restaurants",
      users: "Users",
      lm: "Load More",
      sm: "See More",
      cancel: "Cancel",
    },
  },
  mm: {
    translation: {
      //Dashboard
      search: "Search",
      Dashboard: "ဒက်ရှ်ဘုတ်",
      free: "အားလပ်",
      add_office_order: "ရုံးတစ်ခုထည့်ပါ",
      Finance: "ငွေစာရင်း",
      date: "နေ့စွဲ",
      office_orders: "ရုံးမှာယူခြင်းများ",
      parcel_orders: "ပါဆယ်ထုပ်များ",
      food_orders: "အစားအသောက်မှာယူခြင်းများ",
      Riders: "ပို့ဆောင်သူများ",
      see_all: "အားလုံးကြည့်ပါ",
      all: "အားလုံး",
      giveaway_orders: "giveaway အော်ဒါများ",
      check_riders_on: "ပို့ဆောင်သူ တွေကိုကြည့်ပါ",
      map: "မြေပုံ",
      //Orders
      edit: "တည်းဖြတ်မည်",
      copy: "ကူးပြောင်းမည်",
      delete: "ဖျက်မည်",
      add: "ထည့်မည်",
      status: "အခြေအနေ",
      Orders: "အော်ဒါများ",
      "Office Order": "ရုံးအော်ဒါ",
      "Food Order": "အစားအသောက်မှာယူမှု",
      "Parcel Delivery": "ပါဆယ်ပို့ဆောင်မှု",
      Restaurant: "စားသောက်ဆိုင်",
      Management: "စီမံခန့်ခွဲမှု",
      "Restaruant Category": "စားသောက်ဆိုင် အမျိုးအစား",
      "Restaruant Menu": "စားသောက်ဆိုင် မီနူး",
      "Restaurant Form": "စားသောက်ဆိုင် ဖောင်",
      "Menu Category": "မီနူး အမျိုးအစား",
      "Accept Form": "ဖောင်ကိုလက်ခံသည်",
      "Riders on Map": "မြေပုံပေါ်မှ ပို့ဆောင်သူများ",
      General: "အထွေထွေ",
      "Payment Method": "ငွေပေးချေမှုနည်းလမ်း",
      "Order From": "အော်ဒါ ေဖာင်",
      Recommendation: "ထောက်ခံချက်",
      "Promo Code": "ပရိုမိုကုဒ်",
      "Header Ads": "ခေါင်းစီးကြော်ငြာများ",
      "Special Ads": "ခေါင်းစီးကြော်ငြာများ",
      Places: "နေရာများ",
      Map: "မြေပုံ",
      "Place And Prices": "နေရာနှင့် ဈေးနှုန်း",
      "User Address Places": "အသုံးပြုသူလိပ်စာနေရာများ",
      Wards: "ရပ်ကွက်များ",
      "User Site": "အသုံးပြုသူဆိုက်",
      Banners: "ဘန်ဒါ",
      Sections: "ကဏ္ဍများ",
      Posts: "ပို့စ်များ",
      Chat: "ချတ်",
      Users: "အသုံးပြုသူများ",
      Setting: "ဆက်တင်",
      //Home Page
      "ADD Office Order": "ရုံးအော်ဒါ ထည့်မည်",
      Rider: "Rider",
      "Check Riders on ": "ပို့ဆောင်သူများကို စစ်ဆေးပါ",
      "Map View": "မြေပုံကြည့်ရန်",
      // Office Order
      "Order ID": "အော်ဒါ အမှတ်",
      "Delivery Price": "ပို့ဆောင်သူစေျးနှုန်း",
      "Delivery Man": "ပို့ဆောင်သူ",
      "Pickup Name": "လာယူသူအမည်",
      "Drop Off Name": " ပို့ဆောင်သူအမည်",
      "Order Date": "မှာယူသည့်ရက်စွဲ",
      Action: "အက်ရှင်",
      // Food Order
      User: "အသုံးပြုသူ",
      "Delivery Man ID": "ပို့ဆောင်သူ အမှတ်",
      // Express
      Name: "နာမည်",
      Expense: "ကုန်ကျစရိတ်",
      Title: "ခေါင်းစဥ်",
      Amount: "ပမာဏ",
      Date: "Date",
      // Income
      Income: "ဝင်ငွေ",
      "Daily Income": "နေ့စဉ်ဝင်ငွေ",
      "Monthly Income": "လစဉ်ဝင်ငွေ",
      "Income List Table": "ဝင်ငွေစာရင်း",
      Commission: "ကော်မရှင်",
      "Delivery Fee": "ပို့ဆောင်ခ",
      Total: "စုစုပေါင်း",
      deli_income: "ပို့ဆောင်မှုဝင်ငွေ",

      // Restaurant
      Phone: "ဖုန်းနံပါတ်",
      Email: "အီးမေးလ်",
      Address: "လိပ်စာ",
      Cities: "Cities",
      "Cooking Time": "Cooking Time",
      Delivery: "Delivery",
      Rating: "Rating",
      Image: "Image",
      "Restaurant Categories": "Restaurant Name",
      // Rider
      "Manage Account": "Manage Account",
      "Way Records": "Way Records",
      Leave: "Leave",
      "Parcel Order": "Parcel Order",
      "Deli Man": "Deli Man",
      Reason: "Reason",
      "Admin Reason": "Admin Reason",
      "Leave Date": "Leave Date",
      Status: "Status",
      // General
      "Payment Method Table": "Payment Method Table",
      Provider: "Provider",
      Available: "Available",
      Order: "Order",
      Method: "Method",
      "Order From Table": "Order From Table",
      "Start Date": "Start Date",
      Code: "Code",
      DiscountPrice: "DiscountPrice",
      IsPercentage: "IsPercentage",
      Limit: "Limit",
      IsAvailable: "IsAvailable",
      "Restaurant Id": "Restaurant Id",
      //
      Latitude: "လတ္တီတွဒ်",
      Longitude: "လောင်ဂျီတွဒ်",
      Detail: "ဖော်ပြချက်",
      from: "ထံမှ",
      to: "သို့",
      Price: "စျေးနှုန်း",
      link: "link",
      pick_up_name: " မှာယူသူနံပါတ်",
      pick_up_phone: " မှာယူသူဖုန်းနံပါတ်",
      pick_up_address: " မှာယူသူလိပ်စာ",
      pick_up_note: " မှာယူသူမှတ်ချက်",
      drop_off_name: "ပယ်ဖျက်နံပါတ်",
      drop_off_phone: "ပယ်ဖျက်ဖုန်းနံပါတ်",
      drop_off_address: "ပယ်ဖျက်လိပ်စာ",
      drop_off_note: "ပယ်ဖျက်မှတ်ချက်",
      first: "ပထမ",
      second: "ဒုတိယ",
      third: "တတိယ",
      submit: "ပေးပို့",
      add_image: "ပုံထည့်ပါ",
      select_deli: "Delivery ရွေးပါ",
      way: "လမ်း",
      //Restaurant Management
      "Restaurant Management": "Restaurant Management",
      total_orders: "စုစုပေါင်းအော်ဒါများ",
      total_sale: "စုစုပေါင်းရောင်းချမှု",
      city: "မြို့",
      category: "အမျိုးအစား",
      password: "စကားဝှက်",
      reset: "ပြောင်းလဲရန်",
      change: "ပြောင်းလဲရန်",
      //Rider
      all_way: "လမ်းကြောအားလုံး",
      fo_way: "အစားအသောက်လမ်းကြောင်း",
      gw_deli: "Give လမ်းကြောင်း",
      pd_way: "ပါဆယ် လမ်းကြောင်း",
      total_way: "စုစုပေါင်းလမ်းကြောင်း",
      other_phones: "အခြားဖုန်းနံပါတ်များ",
      rwr: "Recent Way Record",
      usage: "အသုံးပြု",
      all_usage: "အသုံးပြုခြင်းအားလုံး",
      all_records: "မှတ်တမ်းအားလုံး",
      bs: "အခြေခံလစာ",
      ws: "လမ်းကြောင်းလစာ",
      petro: "ဆီဈေး",
      pb: "ဖုန်းငွေ",
      pa: "လစာကြိုငွေ",
      leaves: "ခွင့်ရက်",
      select_month: "လကိုရွေးပါ",
      //Places
      place: "နေရာ",
      places: "နေရာများ",
      desc: "ဖော်ပြချက်",
      pap: "နေရာနှင့်စျေးနှုန်း",
      paps: "နေရာများနှင့်စျေးနှုန်းများ",
      us_ad: "အသုံးပြုသူလိပ်စာ",
      uss_ad: "အသုံးပြုသူများလိပ်စာ",
      ward: "ရပ်ကွက်",
      riders: "ပေးပို့သူများ",
      restaurants: "စားသောက်ဆိုင်များ",
      users: "အသုံးပြုသူမျာ",
      lm: "နောက်ထပ်ရှာဖွေမည်",
      sm: "ပိုကြည့်မည်",
      cancel: "ပယ်ဖျက်မည်",
    },
  },
  cn: {
    translation: {
      //Dashboard
      Dashboard: "仪表盘",
      free: "免费",
      Finance: "财务",
      add_office_order: "添加办公室订单",
      date: "日期",
      office_orders: "办公室订单",
      parcel_orders: "包裹订单",
      food_orders: "食品订单",
      Riders: "骑手",
      see_all: "查看全部",
      all: "全部",
      giveaway_orders: "赠品订单",
      check_riders_on: "查看骑手位置",
      map: "地图",
      way: "路线",
      edit: "编辑",
      copy: "复制",
      delete: "删除",
      add: "添加",
      status: "状态",
      Orders: "订单",
      "Office Order": "办公室订单",
      "Food Order": "食品订单",
      "Parcel Delivery": "包裹配送",
      Restaurant: "餐厅",
      Management: "管理",
      "Restaruant Category": "餐厅类别",
      "Restaruant Menu": "餐厅菜单",
      "Restaruant Form": "餐厅表格",
      "Menu Category": "菜单类别",
      "Accept Form": "接受表格",
      "Riders on Map": "骑手地图",
      General: "常规",
      "Payment Method": "支付方式",
      "Order From": "订单来源",
      Recommendation: "推荐",
      "Promo Code": "优惠码",
      "Header Ads": "头部广告",
      "Special Ads": "特别广告",
      Places: "地点",
      Map: "地图",
      "Place And Prices": "地点和价格",
      "User Address Places": "用户地址地点",
      Wards: "行政区",
      "User Site": "用户站点",
      Banners: "横幅广告",
      Sections: "板块",
      Posts: "帖子",
      Chat: "聊天",
      Users: "用户",
      Setting: "设置",
      //Home Page
      "ADD Office Order": "添加办公室订单",
      Rider: "骑手",
      "Check Riders on ": "查看骑手位置",
      "Map View": "地图视图",
      // Office Order
      "Order ID": "订单编号",
      "Delivery Price": "配送价格",
      "Delivery Man": "配送员",
      "Pickup Name": "取件人姓名",
      "Drop Off Name": "收件人姓名",
      "Order Date": "订单日期",
      Action: "操作",
      // Food Order
      User: "用户",
      "Delivery Man ID": "配送员编号",
      // Express
      Expense: "支出",
      Title: "标题",
      Amount: "金额",
      Date: "日期",
      // Income
      Income: "收入",
      "Daily Income": "日收入",
      "Monthly Income": "月收入",
      "Income List Table": "收入列表表格",
      Commission: "佣金",
      "Delivery Fee": "配送费",
      deli_income: "配送收入",
      Total: "总计",
      // Restaurant
      Phone: "电话",
      Email: "电子邮件",
      Address: "地址",
      Cities: "城市",
      "Cooking Time": "烹饪时间",
      Delivery: "配送",
      Rating: "评分",
      Name: "名称",
      Image: "图片",
      "Restaurant Categories": "餐厅名称",
      // Rider
      "Manage Account": "管理账户",
      "Way Records": "行程记录",
      Leave: "请假",
      "Parcel Order": "包裹订单",
      "Deli Man": "配送员",
      Reason: "原因",
      "Admin Reason": "管理员原因",
      "Leave Date": "请假日期",
      Status: "状态",
      // General
      "Payment Method Table": "支付方式表格",
      Provider: "供应商",
      Available: "可用",
      Order: "订单",
      Method: "方法",
      "Order From Table": "订单来源表格",
      "Start Date": "开始日期",
      Code: "代码",
      DiscountPrice: "折扣价格",
      IsPercentage: "是否为百分比",
      Limit: "限制",
      IsAvailable: "是否可用",
      "Restaurant Id": "餐厅编号",
      //
      Latitude: "纬度",
      Longitude: "经度",
      Detail: "详情",
      "From Place": "起始地点",
      "To Place": "目的地点",
      Price: "价格",
      link: "链接",
      //Office order create
      pick_up_name: "取件人姓名",
      pick_up_phone: "取件人电话",
      pick_up_address: "取件地址",
      pick_up_note: "取件备注",
      drop_off_name: "收件人姓名",
      drop_off_phone: "收件人电话",
      drop_off_address: "收件地址",
      drop_off_note: "收件备注",
      first: "第一",
      second: "第二",
      third: "第三",
      submit: "提交",
      add_image: "添加图片",
      select_deli: "选择配送员",
      //Restaurant Management
      "Restaurant Management": "餐厅管理",
      total_orders: "总订单数",
      total_sale: "总销售额",
      search: "搜索",
      city: "城市",
      category: "类别",
      password: "密码",
      reset: "重置",
      change: "更改",
      //Rider
      all_way: "所有路线",
      fo_way: "食品订单路线",
      gw_deli: "礼物路线",
      pd_way: "包裹路线",
      total_way: "总路线",
      other_phones: "其他电话",
      rwr: "最近路线记录",
      usage: "用法",
      all_usage: "所有用法",
      all_records: "所有记录",
      bs: "基本工资",
      ws: "按路线计算的工资",
      petro: "石油费",
      pb: "电话费",
      pa: "预付款",
      leaves: "请假",
      select_month: "选择月份",
      //Places
      place: "地点",
      places: "地点",
      desc: "描述",
      pap: "地点和价格",
      paps: "地点和价格",
      us_ad: "用户地址",
      uss_ad: "用户地址",
      ward: "行政区",
      riders: "骑手",
      restaurants: "餐馆",
      users: "用户",
      lm: "加载更多",
      sm: "查看更多",
      cancel: "取消",
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Set the default language
  fallbackLng: "en", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already escapes the values
  },
});

export default i18n;
