import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import "./global.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { I18nextProvider } from "react-i18next";
import i18n from "./localization/i18n";
import { store } from "lib/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { Notifications } from "react-push-notification";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Notifications />
            <App />
          </Provider>
        </QueryClientProvider>
      </I18nextProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
