/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Avatar, Box, Icon, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import MDBadge from "components/MDBadge";
import { useState } from "react";

function PaymentMethod() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();
  const [paginationPage, setPaginationPage] = useState(1);
  const {
    data: categories,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery(
    ["payment_method", paginationPage],
    () => getData(`/admin-app/payment-methods?page=${paginationPage}`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const columns = [
    { Header: "id", accessor: "id", align: "center" },
    {
      Header: "Provider",
      accessor: "provider",
      align: "center",
    },
    {
      Header: "available",
      accessor: "available",
      align: "center",
    },
    {
      Header: "order",
      accessor: "order",
      align: "center",
    },
    {
      Header: "methods",
      accessor: "methods",
      align: "center",
    },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  const rows = [];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          navigate(`/payment-method/update`, { state: value });
        }}
        iconOnly
      >
        <Icon>edit</Icon>
      </MDButton>
      {/* <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="error"
        iconOnly
        onClick={() => {
          console.log("valuesss", value);
          handleDelete(value.id);
        }}
      >
        <Icon>delete</Icon>
      </MDButton> */}
    </MDBox>
  );
  categories?.result?.data?.map((value) => {
    rows.push({
      id: <TableRowField align="left" title={value.id} />,
      provider: <TableRowField align="left" title={value.provider} />,
      available: value?.available ? (
        <MDBadge badgeContent="Available" color="primary" container />
      ) : (
        <MDBadge badgeContent="Unavailable" color="error" container />
      ),
      order: <TableRowField align="left" title={value.order} />,
      methods: <TableRowField align="left" title={value.methods.join(",")} />,
      action: <ActionField align="left" value={value} />,
    });
  });

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("/admin-app/payment-methods/" + id);
      openAlertBox(
        "success",
        "Delete Success",
        "You have successfully deleted the  payment method"
      );
      queryClient.invalidateQueries("payment_method");
    } catch (error) {
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
      console.log(error);
    }
  };
  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this  payment method ', id);
  };

  // const handleDetail = async (id) => {
  //   console.log("id before", id)
  //   // try {
  //   //   console.log("id before", id)
  //   //   await deleteData("admin-app/delivery-men/" + id);
  //   //   queryClient.invalidateQueries("delivery-men");
  //   //   console.log("id", id)
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // };

  // const handleEdit = async (id) => {
  //     navigate(`/delivery-men-edit/${id}`);
  // };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  Payment Method Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, float: "right" }}>
          {!isError && categories?.result && (
            <Pagination
              count={categories?.result?.pagination?.last_page}
              color="info"
              sx={{ float: "right" }}
              defaultPage={paginationPage}
              variant="outlined"
              onChange={(_, page) => setPaginationPage(page)}
            />
          )}
        </Box>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PaymentMethod;
