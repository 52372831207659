import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Icon,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestaruantLayout from "./RestaruantLayout";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { NavLink, useNavigate } from "react-router-dom";
import { deleteData } from "lib/fetcher";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentDate } from "lib/helper";
import MDDate from "components/MDDate";
import { ChangeMuiDate } from "lib/helper";
import MDSearch from "components/MDSearch";
import { useTranslation } from "react-i18next";
const tableColumns = [
  { Header: "name", accessor: "name" },
  { Header: "phone", accessor: "phone" },
  { Header: "email", accessor: "email" },
  { Header: "image", accessor: "image" },
  { Header: "city", accessor: "city" },
  { Header: "open", accessor: "open" },
  { Header: "action", accessor: "action" },
];
function RestaruantManagement() {
  // /restaruant-category/create
  const [page, setPage] = React.useState(1);
  const [date, setDate] = useState(getCurrentDate());
  const [month, setMonth] = useState(getCurrentDate());
  const [search, setSearch] = useState("");
  const handleChangeMonthFilter = (newValue) => {
    setMonth(ChangeMuiDate(newValue));

    console.log(ChangeMuiDate(newValue), "newValue");
  };
  const handleChangeDate = (newValue) => {
    setDate(ChangeMuiDate(newValue));
  };
  const [monthFilter, setMonthFilter] = useState(false);
  const { data, isLoading } = useQuery(
    ["restaruant", page, search],
    () => getData(`admin-app/restaurants?page=${page}${search !== "" ? `&search=${search}` : ""}`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: testing, isLoading: cwqd } = useQuery(
    ["ffwe", page, monthFilter, date, month],
    () =>
      getData(
        `/admin-app/restaurant-report?&date=${monthFilter ? month : date}&filter_time=${
          monthFilter ? "MONTHLY" : "DAILY"
        }`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log(testing);

  const TableRowImage = ({ title: src }) => (
    <a style={{ cursor: "pointer" }} href={src} target="_blank" rel="noreferrer">
      <img
        alt="Remy Sharp"
        src={
          src === ""
            ? "http://k2key.in/invesnew/wp-content/uploads/2019/06/profile-default.png"
            : src
        }
        style={{ width: "50px", height: "50px" }}
      />
    </a>
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();

  const handleChange = (event, value) => {
    console.log(value);
    setPage(value);
  };
  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("admin-app/restaurants/" + id);
      queryClient.invalidateQueries("restaruant");
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };
  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this restaruant', id);
  };
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          mt: 2,
        }}
      >
        <MDSearch search={search} setSearch={setSearch} />
        <MDDate
          monthFilter={monthFilter}
          setMonthFilter={setMonthFilter}
          handleChangeMonthFilter={handleChangeMonthFilter}
          handleChangeDate={handleChangeDate}
          date={date}
          month={month}
        />
      </Box>
      <MDBox pt={6}>
        <Grid container spacing={6} sx={{ mb: 5 }}>
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("total_orders")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {testing?.result?.all_restaurant_order_count}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("total_sale")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {testing?.result?.all_restaurant_sell}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
          width: "100%",
        }}
      >
        <NavLink key={"restaruant-category-form"} to={"/restaruant-management/create"}>
          <MDButton variant="gradient" color="dark">
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;{t("add")}
          </MDButton>
        </NavLink>
      </Box>
      <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid container spacing={4}>
          {!isLoading &&
            data?.result?.data?.map((item, index) => (
              <Grid key={index} item xs={4}>
                <Card sx={{ border: "1px solid #eee", height: "100%" }}>
                  <CardMedia sx={{ height: 140 }} image={item.image_url} title="green iguana" />
                  <CardContent sx={{ marginBottom: 0, mt: 2, p: 0, mr: 2, paddingLeft: "25px" }}>
                    <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                      {item.name}
                    </Typography>

                    <Typography variant="h5" sx={{ fontSize: "15px", mb: 1 }}>
                      {item.email}
                    </Typography>
                    <Typography variant="h5" sx={{ fontSize: "15px", mb: 1 }}>
                      {item.phone}
                    </Typography>
                    <Divider component="div" sx={{ borderColor: "black", background: "black" }} />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="h5" sx={{ fontSize: "15px" }}>
                        {t("total_sale")}
                      </Typography>
                      <Typography variant="h5" sx={{ fontSize: "15px", mr: 2 }}>
                        {
                          testing?.result?.each_restaurant_data?.find(
                            (x) => x.restaurant_name === item.name
                          )?.restaurant_sell
                        }
                      </Typography>
                    </Box>
                    <Divider component="div" sx={{ borderColor: "black", background: "black" }} />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="h5" sx={{ fontSize: "15px" }}>
                        {t("Total")} {t("Commission")}
                      </Typography>
                      <Typography variant="h5" sx={{ fontSize: "15px", mr: 2 }}>
                        {
                          testing?.result?.each_restaurant_data?.find(
                            (x) => x.restaurant_name === item.name
                          )?.restaurant_commission
                        }
                      </Typography>
                    </Box>
                    <Divider component="div" sx={{ borderColor: "black", background: "black" }} />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="h5" sx={{ fontSize: "15px" }}>
                        {t("total_orders")}
                      </Typography>
                      <Typography variant="h5" sx={{ fontSize: "15px", mr: 2 }}>
                        {
                          testing?.result?.each_restaurant_data?.find(
                            (x) => x.restaurant_name === item.name
                          )?.restaurant_order_count
                        }
                      </Typography>
                    </Box>
                    <Divider component="div" sx={{ borderColor: "black", background: "black" }} />
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MDButton
                      variant="gradient"
                      style={{ marginRight: "2px", marginLeft: "2px" }}
                      color="info"
                      iconOnly
                      onClick={() => {
                        navigate(`/restaruant-management/detail/${item?.id}`, {
                          state: item,
                        });
                      }}
                    >
                      <VisibilityIcon />
                    </MDButton>
                    <MDButton
                      style={{ marginRight: "2px", marginLeft: "2px" }}
                      variant="gradient"
                      color="warning"
                      onClick={() => {
                        navigate(`/restaruant-management/update`, { state: item });
                      }}
                      iconOnly
                    >
                      <Icon>edit</Icon>
                    </MDButton>
                    <MDButton
                      style={{ marginRight: "2px", marginLeft: "2px" }}
                      variant="gradient"
                      color="error"
                      iconOnly
                      onClick={() => {
                        handleDelete(item.id);
                      }}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </MDBox>
      <Box sx={{ mt: 2, float: "right" }}>
        <Pagination
          color="info"
          count={data?.result?.pagination?.last_page}
          onChange={handleChange}
          defaultPage={page}
          variant="outlined"
          sx={{ my: "20px", ml: "auto" }}
        />
      </Box>

      <Footer />
    </DashboardLayout>
    // <RestaruantLayout>
    //   <Card>

    //   </Card>
    // </RestaruantLayout>
  );
}

export default RestaruantManagement;
