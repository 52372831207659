import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import MDBadge from "components/MDBadge";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDInput from "components/MDInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import RestaruantLayout from "layouts/RestaruantManagement/RestaruantLayout";
import UploadImage from "components/Utils/ImageUpload";
import { postImageData } from "lib/fetcher";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const initalData = {
  restaurant_id: "",
  start_date: getCurrentDate(),
  end_date: getCurrentDate(),
  image: "",
};
function SpecialAdsForm() {
  const { state } = useLocation();
  console.log(state, "state");
  const navigate = useNavigate();
  const { data: res, isLoading: resLoading } = useQuery(
    ["restaurant"],
    () => getData(`/admin-app/restaurants`),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  console.log(state, "state");
  const [inputValue, setInputValue] = React.useState(state ? { ...state } : initalData);
  const theme = useTheme();
  const { openAlertBox } = useAlertBox();
  const [image_url, setImageUrl] = React.useState(state ? state?.profile_photo : "");
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const queryClient = useQueryClient();
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postImageData(`/admin-app/special-ads${state?.id}`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated ");
      queryClient.invalidateQueries("special-ads");
      navigate("/special-ads");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postImageData(`/admin-app/special-ads`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully created ");
      queryClient.invalidateQueries("special-ads");
      navigate("/special-ads");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue);

    if (state) {
      const fd = new FormData();
      fd.append("name", inputValue.name);
      fd.append("image", image_url);
      fd.append("restaurant_id", inputValue.restaurant_id);
      fd.append("start_date", inputValue.start_date);
      fd.append("end_date", inputValue.end_date);
      fd.append("order", 1);
      if (image_url && typeof image_url === "object") {
        fd.append("image", image_url);
      }
      fd.append("_method", "PUT");
      updateMutate(fd);
    } else {
      const fd = new FormData();
      fd.append("name", inputValue.name);
      fd.append("image", image_url);

      fd.append("image", image_url);
      fd.append("restaurant_id", inputValue.restaurant_id);
      fd.append("start_date", inputValue.start_date);
      fd.append("end_date", inputValue.end_date);
      fd.append("order", 1);

      createMutate(fd);
    }
  };
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state ? "Special Ads Update" : "Special Ads Create"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ p: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              {!resLoading && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Restaruant</InputLabel>
                  <Select
                    sx={{ height: 45 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputValue?.restaurant_id}
                    label="restaurant"
                    name="restaurant_id"
                    onChange={inputChangeHandler}
                  >
                    {res?.result?.data.map((city) => (
                      <MenuItem key={city?.id} value={city?.id}>
                        {city?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>

            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  value={inputValue?.start_date}
                  label="Start Date"
                  name="start_date"
                  sx={{ width: "100%" }}
                  inputFormat="YYYY-MM-DD"
                  onChange={(e) => setInputValue({ ...inputValue, start_date: ChangeMuiDate(e) })}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} name="start_date" size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  sx={{ width: "100%" }}
                  value={inputValue?.end_date}
                  label="End Date"
                  name="end_date"
                  inputFormat="YYYY-MM-DD"
                  onChange={(e) => setInputValue({ ...inputValue, end_date: ChangeMuiDate(e) })}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} name="start_date" size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ p: 3 }}>
            <Box sx={{ display: "flex", marginBottom: "20px" }}>
              <UploadImage
                setUploadImage={setImageUrl}
                title="Upload  Image"
                defaultImg={image_url}
              />
            </Box>
          </Grid>
        </Grid>
        <MDBox mb={1} sx={{ float: "right" }}>
          <MDButton
            disabled={updateLoading || createLoading}
            onClick={handleSubmit}
            variant="gradient"
            color="primary"
            fullWidth
          >
            {updateLoading || createLoading ? "Submiting..." : "Submit"}
          </MDButton>
        </MDBox>
      </Card>
    </RestaruantLayout>
  );
}
export default SpecialAdsForm;
