import { styled } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "lib/firebase";
import React, { useEffect, useState } from "react";
import PhoneIcon from "@mui/icons-material/LocalPhone";
import InfoIcon from "@mui/icons-material/Info";

const Section = styled("section")`
  height: 100vh;
  width: 100vw;
  margin-top: -1rem;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  .info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
  }
  h2 {
    font-size: 3.5rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.25rem;
  }
  .qr {
    max-width: 8rem;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    font-size: 1.05rem;
    background-color: rgba(255, 255, 255, 0.5);
    border: solid 1px white;
    text-align: center;
    justify-content: center;
    padding-block: 0.25rem;
    padding-inline: 1rem;
    border-radius: 0.15rem;
    :hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 2.5rem;
      text-align: center;
    }
    .info {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export default function HomeSections() {
  const [sections, setSections] = useState([]);

  async function getsections() {
    try {
      const q = query(collection(db, "sections"), orderBy("order", "asc"));
      const res = await getDocs(q);
      const arr = [];
      res.forEach((doc) => arr.push(doc.data()));
      setSections(arr);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getsections();
  }, []);
  return (
    <>
      {sections.map((section) => (
        <Section key={section.id} url={section.bgURL}>
          <h2>{section.title}</h2>
          <div className="info">
            <img
              src={section.qr}
              alt={section.title + "QR"}
              className="qr"
              title={section.title + "QR code"}
            />
            <a href={"tel:+" + section.phone} className="button">
              <PhoneIcon /> Call Now
            </a>
            <a href={section.link} target="_blank" rel="norefferrer" className="button">
              <InfoIcon /> Learn More
            </a>
          </div>
        </Section>
      ))}
    </>
  );
}
