import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { postData } from "lib/fetcher";
import { getErrorMessage } from "lib/helper";
import useAlertBox from "lib/hooks/useAlertBox";
import PickFromMap from "layouts/map/PickFromMap";
import { useTranslation } from "react-i18next";
import { getData } from "lib/fetcher";
const initalData = {
  name: "",
  detail: "",
  latitude: "",
  longitude: "",
  ward_id: "",
};
function PlacesForm() {
  const { state } = useLocation();
  console.log("state", state);
  const [data, setData] = React.useState(state ? state : initalData);
  const navigate = useNavigate();
  const { openAlertBox } = useAlertBox();
  const { t } = useTranslation();
  function pickLatLong(lat, long) {
    setData((prev) => {
      return { ...prev, latitude: lat, longitude: long };
    });
  }
  const [page, setPage] = useState(1);
  const [ward, setWard] = useState([]);
  const { data: wardData } = useQuery(
    ["wards", page],
    () => getData(`/admin-app/wards?page=${page}`),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setWard((prev) => {
          return [...prev, ...data?.result?.data];
        });
        if (data?.result?.pagination?.last_page !== page) {
          setPage((prev) => prev + 1);
        }
      },
    }
  );
  const { mutate, isLoading } = useMutation({
    mutationFn: async (e) => {
      e.preventDefault();
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("latitude", data.latitude);
      formData.append("longitude", data.longitude);
      formData.append("detail", data.detail);
      formData.append("ward_id", data.ward_id);
      let res;
      console.log("state id", state?.id);
      if (state?.id) {
        formData.append("_method", "put");
        console.log("updating...");
        console.log(Object.fromEntries(formData));

        res = await postData(`admin-app/places/${state?.id}`, formData);
        return res;
      } else {
        res = await postData("admin-app/places", formData);
      }
      return res;
    },
    onSuccess: () => {
      openAlertBox(
        "success",
        "Success",
        `${state?.id ? "Updated Successfully" : "A new place has successfully created"}`
      );
      navigate("/places");
    },
    onError: (error) => {
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ p: 3, mb: 2 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state ? `${t("place")} ${t("edit")}` : `${t("place")} ${t("add")}`}
        </Typography>
        <div style={{ display: "flex" }}>
          <Grid
            container
            spacing={3}
            component="form"
            onSubmit={mutate}
            sx={{ width: "max-content" }}
          >
            <Grid item xs={4} sx={{ p: 2, minWidth: "25rem" }}>
              <MDBox display="flex" alignItems="center" mb={2}>
                <MDInput
                  name="name"
                  defaultValue={data.name}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, name: e.target.value };
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label={t("Name")}
                  fullWidth
                />
              </MDBox>

              <MDBox display="flex" alignItems="center" mb={2}>
                <MDInput
                  rows={3}
                  name="detail"
                  defaultValue={data.detail}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, detail: e.target.value };
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label={t("Detail")}
                  fullWidth
                />
              </MDBox>

              <MDBox display="flex" alignItems="center" mb={2}>
                <MDInput
                  rows={3}
                  name="latitude"
                  value={data.latitude}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, latitude: e.target.value };
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  label={t("Latitude")}
                  fullWidth
                />
              </MDBox>

              <MDBox display="flex" alignItems="center" mb={2}>
                <MDInput
                  rows={3}
                  name="longitude"
                  value={data.longitude}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, longitude: e.target.value };
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  label={t("Longitude")}
                  fullWidth
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" mb={2}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">Select Ward</InputLabel>
                  <Select
                    sx={{ p: 1.5 }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="ward_id"
                    defaultValue={data.ward_id}
                    label="Select Ward"
                    onChange={(e) =>
                      setData((prev) => {
                        return { ...prev, ward_id: e.target.value };
                      })
                    }
                  >
                    {ward?.map((value) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <MDBox sx={{ width: 300, float: "left" }} mb={1}>
                <MDButton
                  type="submit"
                  // disabled={isLoading}
                  variant="gradient"
                  color="primary"
                  fullWidth
                >
                  {isLoading ? "Submiting..." : t("submit")}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <PickFromMap pick={pickLatLong} values={{ lat: data.latitude, lng: data.longitude }} />
        </div>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default PlacesForm;
