import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Data
import MDButton from "components/MDButton";
import { Box, FormControl, Icon, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { getCurrentDate } from "lib/helper";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import { deleteData } from "lib/fetcher";
import { useState } from "react";
import { formatDate } from "lib/helper";
import MDInput from "components/MDInput";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ChangeMuiDate } from "lib/helper";
import Avatar from "assets/images/team-4.jpg";
import MDAvatar from "components/MDAvatar";
import { useTranslation } from "react-i18next";
export const DELIVERY_STATUS = [
  { value: "PENDING", label: "PENDING" },
  {
    value: "DELIVERY_ACCEPTED",
    label: "DELIVERY ACCEPTED ",
  },
  {
    value: "DELIVERY_PICKING_UP",
    label: "DELIVERY PICKING UP",
  },
  {
    value: "DELIVERY_DONE",
    label: "DELIVERY DONE",
  },
  {
    value: "PAYMENT_UNDONE",
    label: "PAYMENT UNDONE",
  },
  {
    value: "PAYMENT_DONE",
    label: "PAYMENT DONE",
  },
  {
    value: "PAYMENT_ERROR",
    label: "PAYMENT ERROR",
  },
];

function OrderManagement() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();
  const [selectedDate, setSelectedDate] = useState(null);
  const { t } = useTranslation();
  const [status, setStatus] = useState("PENDING");
  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };

  const { data, isLoading, error, isError } = useQuery(
    ["give-way-orders", { selectedDate, status }],
    () =>
      getData(
        `/admin-app/get-parcel-delivery?date=${
          selectedDate ? selectedDate : getCurrentDate()
        }&filter=${status}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log(data);

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("admin-app/give-way-orders/" + id);
      queryClient.invalidateQueries("give-way-orders");
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this give way.', id);
  };

  const handleDateChange = (e) => {
    setSelectedDate(ChangeMuiDate(e));
  };

  const columns = [
    { Header: "order id", accessor: "order_id", align: "left" },
    { Header: "delivery man", accessor: "delivery_man", align: "center" },
    { Header: "pickup place", accessor: "pick_up_place", align: "center" },
    { Header: "receiver place", accessor: "receiver_place", align: "left" },
    { Header: "Order Date", accessor: "order_date", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [];

  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );
  const DeliveryMan = ({ deliMan }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {deliMan?.name && <MDAvatar src={Avatar} name={deliMan?.name} size="sm" />}

      <MDBox ml={2} lineHeight={1}>
        {deliMan?.name ? (
          <MDTypography display="block" variant="button" fontWeight="medium">
            {deliMan?.name}
          </MDTypography>
        ) : (
          <MDTypography
            display="block"
            style={{
              color: "red",
            }}
            variant="button"
            fontWeight="medium"
          >
            No Delivery Man
          </MDTypography>
        )}

        <MDTypography variant="caption">{deliMan?.phone || deliMan?.email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        variant="gradient"
        style={{ marginRight: "2px", marginLeft: "2px" }}
        color="info"
        iconOnly
        onClick={() => {
          navigate(`/parcel-delivery/${value?.id}`);
        }}
      >
        <VisibilityIcon />
      </MDButton>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="error"
        iconOnly
        onClick={() => {
          handleDelete(value.id);
        }}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );

  data?.result?.data.map((value) => {
    rows.push({
      order_id: <TableRowField title={value.order_number} />,
      delivery_man: <DeliveryMan deliMan={value.deliveryMan} />,
      pick_up_place: <TableRowField title={value.pickup_place_id?.name} />,
      receiver_place: <TableRowField title={value.receiver_place_id?.name} />,
      order_date: <TableRowField title={value.order_date} />,
      action: <ActionField value={value} />,
    });
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("parcel_orders")}
                </MDTypography>
                <NavLink hidden={true} key={"giveway-form"} to={"/giveway-management/create"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;{t("add")}
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                <MDBox mb={2} display="flex" alignItems="center">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      sx={{ m: 2 }}
                      value={selectedDate ? selectedDate : getCurrentDate()}
                      label={t("date")}
                      inputFormat="YYYY-MM-DD"
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ mx: 2 }} size="small" />
                      )}
                    />
                  </LocalizationProvider>

                  <Box>
                    <MDBox display="flex" alignItems="center">
                      <FormControl>
                        <InputLabel id="demo-simple-select-helper-label">{t("status")}</InputLabel>
                        <Select
                          value={status}
                          onChange={handleOptionChange}
                          sx={{ p: 1 }}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="restaurant_menu_category_id"
                          label="Menu Category"
                        >
                          {DELIVERY_STATUS.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </Box>
                </MDBox>

                {!isError && (
                  <DataTable
                    table={{
                      columns,
                      rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderManagement;
