import React from "react";
import { styled } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Logo from "../../assets/images/logos/logo.png";

const Foot = styled("footer")`
  display: flex;
  min-height: 3rem;
  justify-content: space-between;
  align-items: center;
  padding-inline: 2rem;
  background-color: #17aa6b;
  white-space: nowrap;
  font-size: 0.8rem;
  color: white;
  img {
    max-width: 6rem;
  }
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    list-style: none;
    font-size: 1rem;
  }
  li {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  h4 {
    font-size: 1.5rem;
  }
  a {
    color: white;
    :hover {
      text-decoration: underline;
      text-decoration-color: currentColor;
    }
  }
  @media screen and (max-width: 960px) {
    padding-block: 1rem;
    flex-direction: column;
    gap: 1rem;
  }
`;

const Footer = () => {
  return (
    <Foot>
      <img src={Logo} alt="Myenda" />
      <ul>
        <li>
          <LocalPhoneIcon />
          09848409202
        </li>
        <li>
          <EmailIcon />
          myenda@gmail.com
        </li>
      </ul>
      <p>
        Powered by <a href="https://lightideasdc.com/">Light Idea</a>
      </p>
    </Foot>
  );
};

export default Footer;
