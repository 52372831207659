import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Data
import { NavLink, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import {
  Box,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import MDDate from "components/MDDate";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";

const initalData = {
  restaurant_id: "",
  order: "",
  start_date: "",
  end_date: "",
};

function WayRecords() {
  const { delivery_man_id } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState(getCurrentDate());
  const [month, setMonth] = useState(getCurrentDate());
  const handleChangeMonthFilter = (newValue) => {
    setMonth(ChangeMuiDate(newValue));

    console.log(ChangeMuiDate(newValue), "newValue");
  };
  const handleChangeDate = (newValue) => {
    setDate(ChangeMuiDate(newValue));
  };
  console.log("date", delivery_man_id);
  const [monthFilter, setMonthFilter] = useState(false);
  const { id } = useParams();
  console.log("id", id);
  const {
    data: categories,
    isLoading: isLoadingCategories,
    error,
    isSuccess,
  } = useQuery(
    ["income", monthFilter, month, date, id],
    () =>
      getData(
        `/admin-app/get-delivery-man-way-report?&date=${monthFilter ? month : date}&filter_time=${
          monthFilter ? "MONTHLY" : "DAILY"
        }&request_report=LIST&delivery_man_id=${id}
        `
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [inputValue, setInputValue] = useState(initalData);
  const [inputValueOrder, setInputValueOrder] = useState("Parcel Order");

  // const { data: deliveries, isLoading } = useQuery(
  //   "delivery-men",
  //   () =>
  //     getData(
  //       "admin-app/get-delivery-man-way-records?delivery_man_id=1&date=2023-03-17&filter_order=all&filter_time=monthly"
  //     ),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const columns = [
    { Header: "ID", accessor: "id", align: "center" },
    {
      Header: "delivery man",
      accessor: "delivery_man",
      align: "center",
    },
    {
      Header: "restaurant",
      accessor: "restaurant",
      align: "center",
    },
    {
      Header: "Order Number",
      accessor: "order_number",
      align: "center",
    },
    {
      Header: "Order Status",
      accessor: "order_status",
      align: "center",
    },
    {
      Header: "Order Date",
      accessor: "order_date",
      align: "center",
    },
    {
      Header: "Total Price",
      accessor: "total_price",
      align: "center",
    },
    {
      Header: "Delivery Man Price",
      accessor: "delivery_charge",
      align: "center",
    },
    {
      Header: "User",
      accessor: "user",
      align: "center",
    },
    {
      Header: "User Phone",
      accessor: "user_phone",
      align: "center",
    },
  ];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const rows = [];
  categories?.result?.data?.map((value) => {
    console.log("value", value);
    rows.push({
      id: <TableRowField align="left" title={value.id} />,
      delivery_man: <TableRowField align="left" title={value?.delivery_man?.name} />,
      restaurant: <TableRowField align="left" title={value?.restaurant?.name} />,
      order_number: <TableRowField align="left" title={value?.order_number} />,
      order_status: <TableRowField align="left" title={value?.order_status} />,
      order_date: <TableRowField align="left" title={value?.order_date} />,
      total_price: <TableRowField align="left" title={value?.total_price} />,
      delivery_charge: <TableRowField align="left" title={value?.delivery_price} />,
      user: <TableRowField align="left" title={value?.user?.name} />,
      user_phone: <TableRowField align="left" title={value?.user?.phone} />,
    });
  });
  // const DeleteApiHandler = async (id) => {
  //   try {
  //     await deleteData("admin-app/delivery-men/" + id);
  //     openAlertBox("success", "Delete Success", "You have successfully deleted the Delivery Man");
  //     queryClient.invalidateQueries("delivery-men");
  //   } catch (error) {
  //     openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
  //     console.log(error);
  //   }
  // };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this Delivery', id);
  };

  const handleChangeStartDate = (e) => {
    // console.log("st", dayjs(e))
    setStartDate(e?.$d);
    let date = new Date(e.$d);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let payload = year + "-" + month + "-" + day;
    setInputValue({ ...inputValue, start_date: payload });
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e?.$d);
    let date = new Date(e.$d);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let payload = year + "-" + month + "-" + day;
    setInputValue({ ...inputValue, end_date: payload });
  };

  //   console.log("deli", deliveries?.result?.data)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ p: 2, mt: 2 }}>
              <MDDate
                monthFilter={monthFilter}
                setMonthFilter={setMonthFilter}
                handleChangeMonthFilter={handleChangeMonthFilter}
                handleChangeDate={handleChangeDate}
                date={date}
                month={month}
              />

              <Grid item xs={12}>
                <Card>
                  <Box>
                    <Typography component="h1" sx={{ ml: 2, mb: 2 }}></Typography>
                  </Box>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns,
                        rows: isLoadingCategories ? TableLoadingSkeleton(columns) : rows,
                      }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WayRecords;
