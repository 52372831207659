/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, InputLabel, MenuItem, Select, Table, TextField } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Avatar, Box, Icon, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { getCurrentDate } from "lib/helper";
import { ChangeMuiDate } from "lib/helper";
import { getPreviousDate } from "lib/helper";
import MDDate from "components/MDDate";
import { useTranslation } from "react-i18next";

function IncomeList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();
  const { paginationPage, setPaginationPage } = useState(1);
  const [incomeFilter, setIncomeFilter] = useState("all");
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [date, setDate] = useState(getCurrentDate());
  const [month, setMonth] = useState(getCurrentDate());
  const [paymentMethod, setPaymentMethod] = useState("all");
  const { t } = useTranslation();
  const handleChangeMonthFilter = (newValue) => {
    setMonth(ChangeMuiDate(newValue));
  };
  const handleChangeDate = (newValue) => {
    setDate(ChangeMuiDate(newValue));
  };
  const [monthFilter, setMonthFilter] = useState(false);
  const {
    data: categories,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery(
    ["income", monthFilter, month, date, incomeFilter, paginationPage],
    () =>
      getData(
        `/admin-app/office-income-report?page=${paginationPage ?? 1}&date=${
          monthFilter ? month : date
        }&request_report=LIST&filter_time=${monthFilter ? "MONTHLY" : "DAILY"}
        ${incomeFilter !== "all" ? `&order_type=${incomeFilter}` : ""}${
          paymentMethod !== "all" ? `&payment_method_id=${paymentMethod}` : ""
        }`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: additionalIncome } = useQuery(
    ["additional", { monthFilter, month, date, incomeFilter }],
    () =>
      getData(
        `/admin-app/office-income-report?date=${
          monthFilter ? month : date
        }&request_report=ADDITIONAL_INCOME_TOTAL&filter_time=${
          monthFilter ? "MONTHLY" : "DAILY"
        }&order_type=ADDITIONAL_INCOME`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: expense } = useQuery(["expense", { monthFilter, month, date, incomeFilter }], () =>
    getData(
      `/admin-app/office-expense-report?date=${monthFilter ? month : date}&filter_time=${
        monthFilter ? "MONTHLY" : "DAILY"
      }
      `,
      {
        refetchOnWindowFocus: false,
      }
    )
  );
  const { data: paymentMethodData, isLoading: paymentMethodLoading } = useQuery(
    "payment-methods",
    () => getData(`/admin-app/payment-methods`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: ResIncome } = useQuery(
    ["daily-income", incomeFilter, monthFilter, month, date],
    () =>
      getData(
        `/admin-app/office-income-report?date=${
          monthFilter ? month : date
        }&request_report=RESTAURANT_COMMISSION_TOTAL&filter_time=${
          monthFilter ? "MONTHLY" : "DAILY"
        }
        ${incomeFilter !== "all" ? `&order_type=${incomeFilter}` : ""}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: DeliveryIncome } = useQuery(
    ["daily-income", startDate, incomeFilter, monthFilter, month, date, paymentMethod],
    () =>
      getData(
        `/admin-app/office-income-report?date=${
          monthFilter ? month : date
        }&request_report=DELIVERY_INCOME_TOTAL&filter_time=${monthFilter ? "MONTHLY" : "DAILY"}
        ${incomeFilter !== "all" ? `&order_type=${incomeFilter}` : ""}
        ${paymentMethod !== "all" ? `&payment_method_id=${paymentMethod}` : ""}
        `
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: TotalIncome } = useQuery(
    ["daily-income", startDate, incomeFilter, monthFilter, month, date],
    () =>
      getData(
        `/admin-app/office-income-report?date=${
          monthFilter ? month : date
        }&request_report=RESTAURANT_DELIVERY_INCOME_TOTAL&filter_time=${
          monthFilter ? "MONTHLY" : "DAILY"
        }
        ${incomeFilter !== "all" ? `&order_type=${incomeFilter}` : ""}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const columns = [
    { Header: "ID", accessor: "id", align: "center" },
    {
      Header: "delivery man",
      accessor: "delivery_man",
      align: "center",
    },
    {
      Header: "restaurant",
      accessor: "restaurant",
      align: "center",
    },
    {
      Header: "Order Number",
      accessor: "order_number",
      align: "center",
    },
    {
      Header: "Order Status",
      accessor: "order_status",
      align: "center",
    },
    {
      Header: "Order Date",
      accessor: "order_date",
      align: "center",
    },
    {
      Header: "Net Price",
      accessor: "total_price",
      align: "center",
    },
    {
      Header: "Delivery Man Price",
      accessor: "delivery_charge",
      align: "center",
    },
    {
      Header: "Profit",
      accessor: "profit",
      align: "center",
    },
    {
      Header: "User",
      accessor: "user",
      align: "center",
    },
    {
      Header: "User Phone",
      accessor: "user_phone",
      align: "center",
    },
    {
      Header: "Payment Method",
      accessor: "payment_method",
    },
    {
      Header: "Payment Provider",
      accessor: "payment_provider",
    },
  ];
  const rows = [];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        variant="gradient"
        style={{ marginRight: "2px", marginLeft: "2px" }}
        color="info"
        iconOnly
        onClick={() => {
          navigate("/income/detail", {
            state: {
              id: value,
            },
          });
        }}
      >
        <VisibilityIcon />
      </MDButton>
    </MDBox>
  );
  categories?.result?.data?.map((value) => {
    console.log("value", value);
    rows.push({
      id: <TableRowField align="left" title={value.id} />,
      delivery_man: <TableRowField align="left" title={value?.delivery_man?.name} />,
      restaurant: <TableRowField align="left" title={value?.restaurant?.name} />,
      order_number: <TableRowField align="left" title={value?.order_number} />,
      order_status: <TableRowField align="left" title={value?.order_status} />,
      order_date: <TableRowField align="left" title={value?.order_date} />,
      user: <TableRowField align="left" title={value?.user?.name} />,
      user_phone: <TableRowField align="left" title={value?.user?.phone} />,
      total_price: <TableRowField align="left" title={value?.net_amount} />,
      delivery_charge: <TableRowField align="left" title={value?.delivery_price} />,
      profit: <TableRowField align="left" title={value?.income} />,
      payment_method: <TableRowField align="left" title={value?.payment_method?.methods[0]} />,
      payment_provider: <TableRowField align="left" title={value?.payment_method?.provider} />,
    });
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox
        mb={0}
        sx={{ color: "white !important", padding: "5px", marginLeft: "15px" }}
        display="flex"
        alignItems="center"
      >
        <MDBox
          display="flex"
          sx={{
            mx: 1,
          }}
          alignItems="center"
        >
          <FormControl>
            <InputLabel id="demo-simple-select-helper-label">{"Payment Method"}</InputLabel>
            <Select
              sx={{ p: 1 }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="payment_method"
              label="Payment Method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <MenuItem value="all">All Payment Method</MenuItem>
              {paymentMethodData?.result?.data.map((option) => (
                <MenuItem  key={option.id} value={option.id}>{option.provider}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
        <MDDate
          monthFilter={monthFilter}
          setMonthFilter={setMonthFilter}
          handleChangeMonthFilter={handleChangeMonthFilter}
          handleChangeDate={handleChangeDate}
          date={date}
          month={month}
        />
        <FormControl sx={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-labels">Types</InputLabel>
          <Select
            labelId="demo-simple-select-labels"
            id="demo-simple-select"
            value={incomeFilter}
            label="Restaurants"
            // onChange={handleChange}
            onChange={(e) => setIncomeFilter(e.target.value)}
            sx={{ padding: "10px" }}
          >
            <MenuItem required key="all" value="all">
              {t("all")}
            </MenuItem>
            <MenuItem required key="Food Order" value="FOOD_ORDER">
              {t("food_orders")}
            </MenuItem>
            <MenuItem required key="Parcel Delivery" value="PARCEL_DELIVERY">
              {t("parcel_orders")}
            </MenuItem>
            <MenuItem required key="Giveway Order" value="GIVEWAY_ORDER">
              {t("giveaway_orders")}
            </MenuItem>
          </Select>
        </FormControl>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("Income")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {TotalIncome?.result + additionalIncome?.result - expense?.result}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("deli_income")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {DeliveryIncome?.result}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("Commission")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {ResIncome?.result}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("Expense")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {expense?.result}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt: 3, mb: 3 }}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("Additional Income")}
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h4" sx={{ padding: "10px" }} fontWeight="bold">
                    {additionalIncome?.result}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("Income List Table")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, float: "right" }}>
          {!isError && categories?.result && (
            <Pagination
              count={categories?.result?.pagination?.last_page}
              color="info"
              sx={{ float: "right" }}
              defaultPage={paginationPage}
              variant="outlined"
              onChange={(_, page) => setPaginationPage(page)}
            />
          )}
        </Box>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default IncomeList;
