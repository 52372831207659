import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar from "assets/images/team-4.jpg";
import UserAvatar from "assets/images/team-3.jpg";

// Data
import MDButton from "components/MDButton";
import { Box, FormControl, Icon, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { getCurrentDate } from "lib/helper";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import { deleteData } from "lib/fetcher";
import { useState } from "react";
import { formatDate } from "lib/helper";
import MDInput from "components/MDInput";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ChangeMuiDate } from "lib/helper";
import MDAvatar from "components/MDAvatar";
import { useTranslation } from "react-i18next";
function OrderManagement() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();
  const [selectedDate, setSelectedDate] = useState(null);
  const [status, setStatus] = useState("PENDING");

  const { data, isLoading, error, isError } = useQuery(
    ["give-way-orders", { selectedDate, status }],
    () =>
      getData(
        `/admin-app/get-food-orders?date=${
          selectedDate ? selectedDate : getCurrentDate()
        }&filter=${status}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { t } = useTranslation();
  const ORDER_STATUS = [
    { label: "PENDING", value: "PENDING" },
    { label: "RESTAURANT_ACCEPTED", value: "RESTAURANT_ACCEPTED" },
    { label: "RESTAURANT_CANCELLED", value: "RESTAURANT_CANCELLED" },
    { label: "RESTAURANT_COOKING", value: "RESTAURANT_COOKING" },
    { label: "RESTAURANT_DONE", value: "RESTAURANT_DONE" },
    { label: "DELIVERY_ACCEPTED", value: "DELIVERY_ACCEPTED" },
    { label: "DELIVERY_PICKING_UP", value: "DELIVERY_PICKING_UP" },
    { label: "DELIVERY_DONE", value: "DELIVERY_DONE" },
    { label: "PAYMENT_UNDONE", value: "PAYMENT_UNDONE" },
    { label: "PAYMENT_DONE", value: "PAYMENT_DONE" },
    { label: "PAYMENT_ERROR", value: "PAYMENT_ERROR" },
  ];

  const handleOptionChange = (event) => {
    console.log(event.target.value);
    setStatus(event.target.value);
  };

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("admin-app/give-way-orders/" + id);
      queryClient.invalidateQueries("give-way-orders");
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this give way.', id);
  };

  const handleDateChange = (e) => {
    setSelectedDate(ChangeMuiDate(e));
  };

  const columns = [
    { Header: "order id", accessor: "order_id", align: "left" },
    { Header: "user", accessor: "user", align: "center" },
    { Header: "restaurant", accessor: "restaurant", align: "center" },

    { Header: "delivery man id", accessor: "delivery_man_id", align: "center" },
    { Header: "Order Date", accessor: "order_date", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [];

  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const DeliMan = ({ id, name, phone }) => (
    <MDBox ml={2} lineHeight={1}>
      <MDTypography
        display="block"
        style={{
          color: name ? "black" : "red",
        }}
        variant="button"
        fontWeight="medium"
      >
        {name || "NO DELIVERY MAN"}
      </MDTypography>
      <MDTypography variant="caption">{phone}</MDTypography>
    </MDBox>
  );
  const User = ({ name, phone }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={UserAvatar} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name || "-"}
        </MDTypography>
        <MDTypography variant="caption">{phone}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const Restaurant = ({ name, phone, image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{phone}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        variant="gradient"
        style={{ marginRight: "2px", marginLeft: "2px" }}
        color="info"
        iconOnly
        onClick={() => {
          navigate(`/food-order/${value?.id}`);
        }}
      >
        <VisibilityIcon />
      </MDButton>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="error"
        iconOnly
        onClick={() => {
          handleDelete(value.id);
        }}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );

  data?.result?.data.map((value) => {
    rows.push({
      order_id: <TableRowField title={value.order_number} />,
      user: <User name={value.user?.name} phone={value.user?.phone} />,
      restaurant: (
        <Restaurant
          name={value.restaurant?.name}
          phone={value.restaurant?.phone}
          image={value.restaurant?.thumb_rul}
        />
      ),
      delivery_man_id: (
        <DeliMan
          name={value.delivery_man?.name}
          phone={value.delivery_man?.phone}
          id={value.delivery_man?.id}
        />
      ),
      order_date: <TableRowField title={formatDate(value.order_date)} />,
      action: <ActionField value={value} />,
    });
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("food_orders")}
                </MDTypography>
                <NavLink hidden={true} key={"giveway-form"} to={"/giveway-management/create"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;{t("add")}
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                <MDBox mb={2} display="flex" alignItems="center">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      sx={{ m: 2 }}
                      value={selectedDate ? selectedDate : getCurrentDate()}
                      label={t("date")}
                      inputFormat="YYYY-MM-DD"
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ mx: 2 }} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                  <Box>
                    <MDBox display="flex" alignItems="center">
                      <FormControl>
                        <InputLabel id="demo-simple-select-helper-label">{t("status")}</InputLabel>
                        <Select
                          value={status}
                          onChange={handleOptionChange}
                          sx={{ p: 1 }}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="restaurant_menu_category_id"
                          label="Menu Category"
                        >
                          {ORDER_STATUS.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </Box>
                </MDBox>

                {!isError && (
                  <DataTable
                    table={{
                      columns,
                      rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderManagement;
