import RestaurantIcon from "@mui/icons-material/Restaurant";
import { styled } from "@mui/material";

const Marker = styled("button")`
  position: relative;
  translate: -50% -100%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  border: none;
  border-radius: 50%;
  margin-bottom: 4rem;
  display: grid;
  place-items: center;
  z-index: 10;
`;

const Indicator = styled("div")`
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  width: 1rem;
  height: 1rem;
  rotate: 45deg;
  left: 50%;
  translate: -50%;
  bottom: -0.25rem;
  z-index: -1;
`;

const PointedMarker = () => {
  return (
    <Marker>
      <RestaurantIcon />
      <Indicator />
    </Marker>
  );
};

export default PointedMarker;
