// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import MDButton from "components/MDButton";
import { useEffect } from "react";
import {
    Box,
    CardActions,
    CardContent,
    Stack,
    Button,
    Divider,
    Typography,
    Modal
} from "@mui/material";
import MDInput from "components/MDInput";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { postData } from "lib/fetcher";

function Profile() {
    const { openAlertBox } = useAlertBox();

    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [inputPassword, setInputPassword] = useState({
        password: "",
        password_confirmation: "",
    });

    const handleInput = (e) => {
        const { name, value } = e.target;

        setInputPassword((current) => {
            return {
                ...current,
                [name]: value,
            };
        });
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        if (inputPassword.password !== inputPassword.password_confirmation) {
            openAlertBox("error", "Something went wrong", "Password and confirm password not match");
        } else {
            passwordMutate({
                id: authProfile?.result?.id,
                password: inputPassword.password,
                password_confirmation: inputPassword.password_confirmation,
                login_app: "ADMIN_APP",
            });
        }
    };

    const { mutate: passwordMutate, isLoading: passwordLoading } = useMutation({
        mutationFn: (body) => postData(`admin-app/reset-password`, body),
        onSuccess: () => {
            openAlertBox("success", " Success", "You have successfully updated the password");
            setOpen(false);
        },

        onError: (error) => {
            console.log(error, "error");
            openAlertBox(
                "error",
                " Something went wrong",
                error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
            );
        },
    });

    const { data: authProfile, isLoading: authProfileLoading, status: authProfileStatus } = useQuery(
        ["profile"],
        () =>
            postData(
                `/profile`, {
                login_app: 'ADMIN_APP',
            }
            ),
        {
            refetchOnWindowFocus: false,
        }
    );

    // if (authProfileStatus == 'success') {
    //     setAdminId(authProfile?.result?.id)
    // }

    const { t } = useTranslation();

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <MDBox pb={3}>

                <Grid container spacing={6}>

                    <Grid item xs={12}>
                        <MDButton
                            style={{ marginRight: "2px", float: "right", marginLeft: "2px" }}
                            variant="gradient"
                            color="warning"
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            {t("password")} {t("reset")}
                        </MDButton>

                        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid container spacing={4}>
                                <Modal
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    sx={{ display: "grid", placeItems: "center" }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#fff",
                                            minHeight: "15rem",
                                            width: "100%",
                                            maxWidth: "40rem",
                                            outline: "none",
                                            borderRadius: "0.5rem",
                                            padding: "1rem",
                                            textAlign: "center",
                                        }}
                                        component="form"
                                        onSubmit={handleResetPassword}
                                    >
                                        <Typography
                                            textAlign="left"
                                            component="h4"
                                            fontSize="1.1rem"
                                            color="info"
                                            fontWeight={600}
                                            sx={{ marginBottom: "1rem", color: theme.palette.info.main }}
                                        >
                                            Change password
                                        </Typography>

                                        <MDInput
                                            type="password"
                                            name="password"
                                            label="Password"
                                            fullWidth
                                            onChange={handleInput}
                                            sx={{ marginBottom: "1rem" }}
                                        />
                                        <MDInput
                                            type="password"
                                            name="password_confirmation"
                                            onChange={handleInput}
                                            label="Password Confirmation"
                                            fullWidth
                                        />

                                        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "2rem" }}>
                                            <Button
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                                variant="text"
                                                size="medium"
                                                color="success"
                                            >
                                                Cancel
                                            </Button>
                                            <MDButton
                                                type="submit"
                                                disabled={passwordLoading}
                                                variant="contained"
                                                color="info"
                                                size="medium"
                                            >
                                                {passwordLoading ? "Saving..." : "Save"}
                                            </MDButton>
                                        </Stack>
                                    </Box>
                                </Modal>
                                <Grid item xs={4}>
                                    <Card sx={{ border: "1px solid #eee", height: "100%" }}>
                                        <CardContent
                                            sx={{ marginBottom: 0, mt: 2, p: 0, mr: 2, paddingLeft: "25px" }}
                                        >
                                            <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                                                name: {authProfile?.result?.name ?? '-'}
                                            </Typography>
                                            <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                                                email: {authProfile?.result?.email ?? '-'}
                                            </Typography>
                                            <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                                                phone: {authProfile?.result?.phone ?? '-'}
                                            </Typography>
                                        </CardContent>

                                    </Card>
                                </Grid>

                            </Grid>
                        </MDBox>

                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Profile;
