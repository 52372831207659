import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAlertBox from "lib/hooks/useAlertBox";
import { IconButton, Stack, styled } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "lib/firebase";
import MDInput from "components/MDInput";
import { BorderColor } from "@mui/icons-material";

const sectionIDs = ["section-1", "section-2"];

const SectionContainer = styled("form")`
  width: 100%;
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  padding-block: 2rem;
`;

const Background = styled("figure")`
  width: 90%;
  aspect-ratio: 16 / 7;
  border: solid 1px #606060;
  border-style: dashed;
  padding-block: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    aspect-ratio: 16 / 6;
    object-fit: contain;
  }
`;

const Qr = styled(Background)`
  max-width: 8rem;
  margin-top: 1rem;
  aspect-ratio: 1 / 1;
  padding: 0rem;
  font-size: 1rem;
  text-align: center;
  img {
    aspect-ratio: 1 / 1;
  }
`;

const Info = styled("div")`
  position: relative;

  width: 100%;
  .buttons {
    position: absolute;
    bottom: 0rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

export default function Sections() {
  const { openAlertBox } = useAlertBox();
  const [sections, setSections] = useState([]);

  async function getsections() {
    try {
      const q = query(collection(db, "sections"), orderBy("order", "asc"));
      const res = await getDocs(q);
      const arr = [];
      res.forEach((doc) => arr.push(doc.data()));
      setSections(arr);
    } catch (error) {
      openAlertBox("error", error.message || "Unexpected error");
    }
  }
  useEffect(() => {
    getsections();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Stack gap={"1rem"}>
          {sectionIDs?.map((id, index) => (
            <Section
              sectionID={id}
              key={id}
              order={index + 1}
              section={sections[index]}
              refetch={() => getsections()}
            />
          ))}
        </Stack>
      </MDBox>
    </DashboardLayout>
  );
}

function Section({ sectionID, section, refetch, order }) {
  const { openAlertBox } = useAlertBox();
  const [tempImage, setTempImage] = useState(null);
  const [qr, setQr] = useState(null);
  const [phone, setPhone] = useState("");
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setPhone(section?.phone || "");
    setLink(section?.link || "");
    setTitle(section?.title || "");
  }, [section]);

  async function doSectionImageUpload(path, image) {
    try {
      const imgRef = ref(storage, path);
      await uploadBytes(imgRef, image);
      const url = await getDownloadURL(imgRef);
      return url;
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }
  async function uploadsection() {
    try {
      let bgURL = section.bgURL;
      let qrURL = section.qr;
      if (tempImage) {
        bgURL = await doSectionImageUpload("sectionsBG/" + sectionID, tempImage);
      }
      if (qr) {
        qrURL = await doSectionImageUpload("sectionsQR/" + sectionID, qr);
      }
      const body = {
        bgURL,
        qr: qrURL,
        id: sectionID,
        order,
        phone,
        link,
        title,
      };
      await setDoc(doc(db, "sections", sectionID), body);
      openAlertBox("success", "Section uploaded successfully");
      reset();
      refetch();
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }

  async function deletesection() {
    try {
      await deleteDoc(doc(db, "sections", sectionID));
      openAlertBox("success", "section deleted successfully");
      reset();
      refetch();
    } catch (error) {
      openAlertBox("error", error.message || "Deleting failed");
    }
  }

  function reset() {
    setQr(null);
    setPhone(section?.phone || "");
    setLink(section?.link || "");
    setTitle(section?.title || "");
    setTempImage(null);
    setDisabled(true);
  }

  return (
    <SectionContainer>
      <Background>
        <label htmlFor={sectionID}>
          {tempImage ? (
            <img src={URL.createObjectURL(tempImage)} alt="" />
          ) : section?.bgURL ? (
            <img src={section?.bgURL} alt="" />
          ) : (
            "Upload background"
          )}
          <input
            id={sectionID}
            type="file"
            accept="image/*"
            disabled={disabled}
            hidden
            onChange={(e) => setTempImage(e.target.files[0])}
          />
        </label>
      </Background>
      <Info>
        <Stack gap={2}>
          <MDInput
            type="text"
            name="title"
            label="Title"
            required
            disabled={disabled}
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <MDInput
            type="text"
            name="phone"
            label="Phone"
            required
            disabled={disabled}
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <MDInput
            type="text"
            name="link"
            label="Link"
            required
            disabled={disabled}
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </Stack>
        <Qr>
          <label htmlFor={sectionID + "qr"}>
            {qr ? (
              <img src={URL.createObjectURL(qr)} alt="" />
            ) : section?.qr ? (
              <img src={section?.qr} alt="" />
            ) : (
              "Upload QR"
            )}
          </label>
          <input
            id={sectionID + "qr"}
            type="file"
            accept="image/*"
            disabled={disabled}
            hidden
            onChange={(e) => setQr(e.target.files[0])}
          />
        </Qr>
        <div className="buttons">
          {disabled && section ? (
            <IconButton color="error" onClick={deletesection}>
              <DeleteIcon />
            </IconButton>
          ) : (
            ""
          )}
          {disabled ? (
            <IconButton color="warning" onClick={() => setDisabled(false)}>
              <BorderColor />
            </IconButton>
          ) : (
            <>
              <IconButton color="warning" onClick={reset}>
                <CloseIcon />
              </IconButton>
              <IconButton color="success" onClick={uploadsection}>
                <CheckIcon />
              </IconButton>
            </>
          )}
        </div>
      </Info>
    </SectionContainer>
  );
}
