import { Icon, Typography } from "@mui/material";
import MDButton from "components/MDButton";

export const columns = [
  {
    Header: (
      <Typography component="h6" fontSize="0.8rem" fontWeight={600}>
        ID
      </Typography>
    ),
    accessor: "id",
    align: "left",
    width: "5rem",
  },
  {
    Header: (
      <Typography component="h6" fontSize="0.8rem" fontWeight={600}>
        User Name
      </Typography>
    ),
    accessor: "name",
    align: "left",
  },
  {
    Header: (
      <Typography component="h6" fontSize="0.8rem" fontWeight={600}>
        Email
      </Typography>
    ),
    accessor: "email",
    align: "left",
  },
  {
    Header: (
      <Typography component="h6" fontSize="0.8rem" fontWeight={600}>
        Phone
      </Typography>
    ),
    accessor: "phone",
    align: "left",
  },
  {
    Header: (
      <Typography component="h6" fontSize="0.8rem" fontWeight={600}>
        Action
      </Typography>
    ),
    accessor: "action",
    align: "left",
  },
];

export function TableData(data = [], editUser, deleteUser, controlBanDialog) {
  return data.map((item) => {
    return {
      id: item.id,
      name: item?.name,
      email: item?.email,
      phone: item?.phone,
      action: (
        <div>
          <MDButton
            style={{ marginRight: "2px", marginLeft: "2px" }}
            variant="gradient"
            color="error"
            iconOnly
            onClick={() => controlBanDialog(item.id, item.name)}
          >
            Ban User
          </MDButton>
          <MDButton
            style={{ marginRight: "2px", marginLeft: "2px" }}
            variant="gradient"
            color="warning"
            iconOnly
            onClick={() => editUser(item)}
          >
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            style={{ marginRight: "2px", marginLeft: "2px" }}
            variant="gradient"
            color="error"
            iconOnly
            onClick={() => deleteUser(item.id, item.name)}
          >
            <Icon>delete</Icon>
          </MDButton>
        </div>
      ),
    };
  });
}
