import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDAvatar from "components/MDAvatar";

// Data
import MDButton from "components/MDButton";
import { Icon, TextField } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { getCurrentDate } from "lib/helper";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import { deleteData } from "lib/fetcher";
import { useState } from "react";
import { formatDate } from "lib/helper";
import MDInput from "components/MDInput";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import { useTranslation } from "react-i18next";
function UserAddressPlaces() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();

  const { data, isLoading, error, isError } = useQuery(
    "places",
    () => getData(`/admin-app/user-address-places`),
    {
      refetchOnWindowFocus: false,
    }
  );

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("/admin-app/user-address-places/" + id);
      queryClient.invalidateQueries("places");
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this give way.', id);
  };

  const columns = [
    { Header: "name", accessor: "name", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [];

  const TableRowField = ({ name, image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name || "-"}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          navigate(`/user-address-places/update`, { state: value });
        }}
        iconOnly
      >
        <Icon>edit</Icon>
      </MDButton>

      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="error"
        iconOnly
        onClick={() => {
          handleDelete(value.id);
        }}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );

  data?.result?.data.map((value) => {
    rows.push({
      name: <TableRowField name={value.name} image={value.thumb_url} />,
      action: <ActionField value={value} />,
    });
  });
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("uss_ad")}
                </MDTypography>
                <NavLink key={"user-address-places"} to={"/user-address-places/create"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;{t("add")}
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                {!isError && (
                  <DataTable
                    table={{
                      columns,
                      rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserAddressPlaces;
