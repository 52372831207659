// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const PromoCodeDetail
    = () => {
        const { state: data } = useLocation();
        // console.log("d", data)

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sx={{ p: 3 }}>
                            <Card sx={{ p: 3 }}>
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    {data?.name}
                                </Typography>
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                Discount Price
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "30px" }}>
                                                -
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                {data?.discount_price}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                Code
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "30px" }}>
                                                -
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                {data?.code}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                Limit
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "30px" }}>
                                                -
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                {data?.limit}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                Is Available
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "30px" }}>
                                                -
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                {data?.is_available ? "True" : "False"}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                Is Percentage
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "30px" }}>
                                                -
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                {data?.is_percentage ? "True" : "False"}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                Start Date
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "30px" }}>
                                                -
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                {data?.start_date}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                End Date
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "30px" }}>
                                                -
                                            </Typography>
                                            <Typography variant="h6" sx={{ width: "200px" }}>
                                                {data?.end_date}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        )
    }

export default PromoCodeDetail

