import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAlertBox from "lib/hooks/useAlertBox";
import { IconButton, Stack, styled } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "lib/firebase";
import MDInput from "components/MDInput";
import { BorderColor } from "@mui/icons-material";

const postIDs = ["post-1", "post-2", "post-3", "post-4", "post-5", "post-6", "post-7"];

const PostContainer = styled("form")`
  width: 100%;
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  padding-block: 2rem;
  position: relative;
  .order {
    position: absolute;
    left: 1rem;
    top: 0rem;
  }
`;

const Background = styled("figure")`
  width: 90%;
  aspect-ratio: 16 / 7;
  border: solid 1px #606060;
  border-style: dashed;
  padding: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    aspect-ratio: 16 / 6;
    object-fit: contain;
  }
`;

const Info = styled("div")`
  position: relative;

  width: 100%;
  textarea {
    resize: none;
    height: 9rem;
    padding-left: 1rem;
    padding-top: 1rem;
    font-family: inherit;
  }
  .buttons {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    display: flex;
  }
`;

export default function Posts() {
  const { openAlertBox } = useAlertBox();
  const [posts, setPosts] = useState([]);

  async function getposts() {
    try {
      const q = query(collection(db, "posts"), orderBy("order", "asc"));
      const res = await getDocs(q);
      const arr = [];
      res.forEach((doc) => arr.push(doc.data()));
      setPosts(arr);
    } catch (error) {
      openAlertBox("error", error.message || "Unexpected error");
    }
  }
  useEffect(() => {
    getposts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Stack gap={"1rem"}>
          {postIDs?.map((id, index) => (
            <Post
              postID={id}
              key={id}
              order={index + 1}
              post={posts[index]}
              refetch={() => getposts()}
            />
          ))}
        </Stack>
      </MDBox>
    </DashboardLayout>
  );
}

function Post({ postID, post, refetch, order }) {
  const { openAlertBox } = useAlertBox();
  const [tempImage, setTempImage] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setTitle(post?.title || "");
    setContent(post?.content || "");
  }, [post]);

  async function doPostImageUpload() {
    try {
      const imgRef = ref(storage, `posts/${postID}`);
      await uploadBytes(imgRef, tempImage);
      const url = await getDownloadURL(imgRef);
      return url;
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }
  async function uploadpost() {
    try {
      let url = post.url;
      if (tempImage) {
        url = await doPostImageUpload();
      }
      const body = {
        url,
        id: postID,
        order,
        title,
        content,
      };
      await setDoc(doc(db, "posts", postID), body);
      openAlertBox("success", "Post uploaded successfully", postID + "has been uploaded");
      reset();
      refetch();
    } catch (error) {
      openAlertBox("error", error.message || "Uploading failed");
    }
  }

  async function deletepost() {
    try {
      await deleteDoc(doc(db, "posts", postID));
      openAlertBox("success", "post deleted successfully");
      reset();
      refetch();
    } catch (error) {
      openAlertBox("error", error.message || "Deleting failed");
    }
  }

  function reset() {
    setTitle(post?.title || "");
    setContent(post?.content || "");
    setTempImage(null);
    setDisabled(true);
  }

  return (
    <PostContainer>
      <h3 className="order">{order}</h3>
      <Background>
        <label htmlFor={postID}>
          {tempImage ? (
            <img src={URL.createObjectURL(tempImage)} alt="" />
          ) : post ? (
            <img src={post?.url} alt="" />
          ) : (
            "Upload background"
          )}
          <input
            id={postID}
            type="file"
            accept="image/*"
            disabled={disabled}
            hidden
            onChange={(e) => setTempImage(e.target.files[0])}
          />
        </label>
      </Background>
      <Info>
        <Stack gap={2}>
          <MDInput
            type="text"
            name="title"
            label="Title"
            required
            disabled={disabled}
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            type="text"
            name="content"
            required
            disabled={disabled}
            fullWidth
            placeholder="Content *"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Stack>

        <div className="buttons">
          {disabled && post ? (
            <IconButton color="error" onClick={deletepost}>
              <DeleteIcon />
            </IconButton>
          ) : (
            ""
          )}
          {disabled ? (
            <IconButton color="warning" onClick={() => setDisabled(false)}>
              <BorderColor />
            </IconButton>
          ) : (
            <>
              <IconButton color="warning" onClick={reset}>
                <CloseIcon />
              </IconButton>
              <IconButton color="success" onClick={uploadpost}>
                <CheckIcon />
              </IconButton>
            </>
          )}
        </div>
      </Info>
    </PostContainer>
  );
}
