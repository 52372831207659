import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from "@mui/material";

import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestaruantLayout from "./RestaruantLayout";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getData } from "lib/fetcher";
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import useConfirmBox from "lib/hooks/useConfirmBox";
import MDTypography from "components/MDTypography";
import UploadImage from "components/Utils/ImageUpload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MDInput from "components/MDInput";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import { deleteData } from "lib/fetcher";
import { postImageData } from "lib/fetcher";
import RestaurantMarker from "layouts/map/RestaurantMarker";
function RestaurantMenuDetail() {
  const { state: data } = useLocation();
  const { id: restaurantId } = useParams();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const DeleteApiHandler = async (id) => {
    try {
      await deleteData(`/admin-app/restaurant/${restaurantId}/restaurant-menus/${id}`);
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
      queryClient.invalidateQueries("restaruant-menu");
      navigate("/restaruant-management/detail/" + restaurantId);
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };
  const [open, setOpen] = useState(false);
  const closeBox = () => {
    setOpen(false);
  };
  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this restaruant menu', id);
  };

  const { state } = useLocation();

  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <Typography variant="h3" sx={{ mb: 1 }}>
              {state.name}
            </Typography>
            <Box sx={{ alignItems: "center", display: "flex", mb: 4, fontSize: "20px" }}>
              <Typography variant="p" sx={{ color: state.available ? "green" : "red", mr: 1 }}>
                {state.available ? "Available" : "Not Available"}
              </Typography>
              {state.available && (
                <CheckCircleOutlineIcon sx={{ color: state.available ? "green" : "red" }} />
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", height: "fit-content" }}>
            <MDButton
              type="button"
              sx={{ height: "fit-content", mx: 1 }}
              variant="gradient"
              color="warning"
              fullWidth
              onClick={() => {
                setOpen(true);
              }}
            >
              Edit
            </MDButton>
            <MDButton
              type="button"
              onClick={() => {
                handleDelete(state.id);
              }}
              height={{ height: "fit-content" }}
              variant="gradient"
              color="error"
              fullWidth
            >
              Delete
            </MDButton>
          </Box>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <img
              src={state?.image_url}
              alt="burger"
              style={{ width: "100%", height: "300px", borderRadius: "10px" }}
            />
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Options
        </Typography>

        <Grid container item spacing={2}>
          {data?.restaurant_menu_options.map((item) => (
            <Grid item xs={3}>
              <Box sx={{ fontSize: "16px", background: "#17AA6B0D", p: 1, borderRadius: "5px" }}>
                <p>{item.name}</p>
                <p>{item.price}</p>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h5" sx={{ mb: 1, mt: 5 }}>
          Add on
        </Typography>
        <Grid container item spacing={2}>
          {data?.restaurant_menu_add_ons?.map((item) => (
            <Grid item xs={3}>
              <Box sx={{ fontSize: "16px", background: "#17AA6B0D", p: 1, borderRadius: "5px" }}>
                <p>{item.name}</p>
                <p>{item.price}</p>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Card>
      <RestaruantMenuFormModal data={data} open={open} closeBox={closeBox} />
    </RestaruantLayout>
  );
}
// eslint-disable-next-line react-hooks/rules-of-hooks
const initalData = {
  name: "",
  base_price: "",
  restaurant_menu_category_id: "",
  image: "",
  discount_price: "",
  remark: "",
  available: false,
};

export const RestaruantMenuFormModal = ({ open, closeBox, data }) => {
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const [OptionType, setOptionType] = useState("option");
  const [editData, setEditData] = useState(null);
  const [inputData, setInputData] = useState({});

  const [image, setImage] = useState(data ? data.image_url : null);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const AddCloseModal = () => {
    setAddModalOpen(false);
  };
  const [radioInputValue, setRadioInputValue] = useState(
    data?.discount_percentage ? "percentage" : "pirce"
  );
  const handleRadioInputChange = (event) => {
    setRadioInputValue(event.target.value);
  };
  const [inputValue, setInputValue] = useState(
    data
      ? {
          ...data,
          restaurant_menu_category_id: data?.restaurant_menu_category?.id,
        }
      : initalData
  );
  const handleInputChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };
  const { id: restaurantId } = useParams();
  useEffect(() => {
    setInputData(data);
    setImage(data?.image_url || null);
  }, [data]);
  useEffect(() => {
    setInputValue(
      data
        ? {
            ...data,
            restaurant_menu_category_id: data?.restaurant_menu_category?.id,
          }
        : initalData
    );
    setImage(data ? data.image_url : null);
  }, [data]);
  const { openAlertBox } = useAlertBox();
  const {
    data: categories,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery("restaruant-menu-category", () => getData("/admin-app/restaurant-menu-categories"), {
    refetchOnWindowFocus: false,
  });
  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    (data) => postImageData(`/admin-app/restaurant/${restaurantId}/restaurant-menus`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("restaruant-menu");
        openAlertBox("success", " Success", " successfully created");
        closeBox();
      },
      onError: (error) => {
        console.log(error, "error");
        openAlertBox(
          "error",
          " Something went wrong",
          error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
        );
      },
    }
  );
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    (updateData) =>
      postImageData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus/${data?.id}`,
        updateData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("restaruant-menu");
        openAlertBox("success", " Success", "You have successfully update ");
        closeBox();
      },
      onError: (error) => {
        console.log(error, "error");
        openAlertBox(
          "error",
          " Something went wrong",
          error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
        );
      },
    }
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", inputValue.name);
    formData.append("base_price", inputValue.base_price);
    formData.append("restaurant_menu_category_id", inputValue.restaurant_menu_category_id);
    if (image && typeof image === "object") {
      formData.append("image", image);
    }
    formData.append("discount_price", inputValue.discount_price || 0);
    formData.append("discount_percentage", radioInputValue === "pirce" ? 0 : 1);
    formData.append("remark", inputValue.remark);
    formData.append("available", inputValue.available ? 1 : 0);
    if (data) {
      formData.append("_method", "PUT");
      updateMutate(formData);
    } else {
      createMutate(formData);
    }
  };

  const DeleteOptionApiHandler = async (id) => {
    try {
      await deleteData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus/${data?.id}/restaurant-menu-options/${id}`
      );
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
      queryClient.invalidateQueries("restaruant-menu");
      closeBox();
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };
  const DeleteAddonApiHandler = async (id) => {
    try {
      await deleteData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus/${data?.id}/restaurant-menu-addons/${id}`
      );
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
      queryClient.invalidateQueries("restaruant-menu");
      closeBox();
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };
  const handleDeleteAddon = (id) => {
    openConfirmBox(
      DeleteAddonApiHandler,
      'Clik "Confirm" to delete this restaruant menu Addon',
      id
    );
  };
  const handleDeleteOption = (id) => {
    openConfirmBox(
      DeleteOptionApiHandler,
      'Clik "Confirm" to delete this restaruant menu Option ',
      id
    );
  };
  const DeleteApiHandler = async (id) => {
    try {
      await deleteData(`/admin-app/restaurant/${restaurantId}/restaurant-menus/${id}`);
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
      queryClient.invalidateQueries("restaruant-menu");
      closeBox();
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };
  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this restaruant menu', id);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={closeBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            height: "100%",
            width: "100%",
            maxWidth: "50rem",
            outline: "none",
            borderRadius: "0.5rem",
            padding: "1rem",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <MDTypography id="Are you sure ?" color="warning" variant="h4">
              {data ? "Edit Restaurant Menu" : "Create Restaurant Menu"}
            </MDTypography>
            {data && (
              <MDButton
                type="button"
                onClick={() => {
                  handleDelete(data?.id);
                }}
                height={{ height: "fit-content" }}
                variant="gradient"
                color="error"
              >
                Delete
              </MDButton>
            )}
          </Box>

          <Grid container spacing={1} mt={3}>
            <Grid item xs={5} sx={{ p: 3, alignItems: "center" }}>
              <UploadImage
                setUploadImage={setImage}
                title=" Restaurant Menu Image"
                defaultImg={image}
                mHeight="80vh"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} mt={1}>
            <Grid item xs={6} sx={{ p: 3, alignItems: "center" }}>
              <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
                <MDInput
                  sx={{ width: "100%" }}
                  value={inputValue.name}
                  onChange={handleInputChange}
                  variant="outlined"
                  label="name"
                  name="name"
                />
              </Box>
              <Box sx={{ display: "flex", marginBottom: "20px" }}>
                <FormControlLabel
                  value="top"
                  color="success"
                  control={
                    <Switch
                      name="available"
                      onChange={handleInputChange}
                      checked={inputValue.available}
                    />
                  }
                  label="Available"
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <MDBox display="flex" alignItems="center" mb={2}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label"> Menu Category</InputLabel>
                    <Select
                      value={inputValue.restaurant_menu_category_id}
                      onChange={handleInputChange}
                      sx={{ p: 1.5 }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="restaurant_menu_category_id"
                      label="Menu Category"
                    >
                      {categories?.result?.data?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ p: 3, alignItems: "center" }}>
              <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
                <MDInput
                  sx={{ width: "100%" }}
                  value={inputValue.base_price}
                  onChange={handleInputChange}
                  variant="outlined"
                  label="price"
                  name="base_price"
                />
              </Box>
              <Box sx={{ display: "flex", marginBottom: "20px" }}>
                <FormControl sx={{ width: "52%" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={radioInputValue}
                    onChange={handleRadioInputChange}
                  >
                    <FormControlLabel value="pirce" control={<Radio />} label="Price" />
                    <FormControlLabel value="percent" control={<Radio />} label="Percent" />
                  </RadioGroup>
                </FormControl>

                <MDInput
                  sx={{ width: "48%" }}
                  value={inputValue.discount_price}
                  onChange={handleInputChange}
                  variant="outlined"
                  label="price"
                  name="discount_price"
                />
              </Box>
              <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
                <textarea
                  style={{
                    width: "100%",
                    height: "100px",
                    padding: "10px",
                    border: "1px solid #D2D6DA",
                    outline: "none",
                    borderRadius: "5px",
                  }}
                  value={inputValue.remark}
                  onChange={handleInputChange}
                  variant="outlined"
                  label="remark"
                  name="remark"
                  placeholder="Please enter remark"
                />

                {/* <MDInput
                  sx={{ width: "100%" }}
                  value={inputValue.remark}
                  onChange={handleInputChange}
                  variant="outlined"
                  label="remark"
                  name="remark"
                /> */}
              </Box>
            </Grid>
          </Grid>
          {inputData && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Typography variant="h5" sx={{ mr: 1 }}>
                  Options
                </Typography>
                <AddCircleOutlineIcon
                  onClick={() => {
                    setOptionType("option");
                    setAddModalOpen(true);
                  }}
                  sx={{ cursor: "pointer", color: "green" }}
                />
              </Box>
              <Grid container item spacing={2}>
                {inputData?.restaurant_menu_options?.map((item, index) => (
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        background: "#17AA6B0D",
                        display: "flex",
                        p: 1,
                        borderRadius: "5px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ fontSize: "16px" }}>
                        <p>{item?.name}</p>
                        <p>{item?.price}</p>
                      </Box>
                      <Box>
                        <MDButton
                          style={{ marginRight: "2px", marginLeft: "2px" }}
                          variant="gradient"
                          color="warning"
                          size="small"
                          onClick={() => {
                            setOptionType("option");
                            setAddModalOpen(true);
                            setEditData(item);
                          }}
                          iconOnly
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                        <MDButton
                          size="small"
                          style={{ marginRight: "2px", marginLeft: "2px" }}
                          variant="gradient"
                          color="error"
                          iconOnly
                          onClick={() => {
                            handleDeleteOption(item.id);
                          }}
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}>
                <Typography variant="h5" sx={{ mr: 1 }}>
                  Adds on
                </Typography>
                <AddCircleOutlineIcon
                  onClick={() => {
                    setOptionType("addon");
                    setAddModalOpen(true);
                  }}
                  sx={{ cursor: "pointer", color: "green" }}
                />
              </Box>
              <Grid container item spacing={2}>
                {inputData?.restaurant_menu_add_ons?.map((item, index) => (
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        background: "#17AA6B0D",
                        display: "flex",
                        p: 1,
                        borderRadius: "5px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ fontSize: "16px" }}>
                        <p>{item?.name}</p>
                        <p>{item?.price}</p>
                      </Box>
                      <Box>
                        <MDButton
                          style={{ marginRight: "2px", marginLeft: "2px" }}
                          variant="gradient"
                          color="warning"
                          size="small"
                          onClick={() => {
                            setOptionType("addon");
                            setAddModalOpen(true);
                            setEditData(item);
                          }}
                          iconOnly
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                        <MDButton
                          size="small"
                          style={{ marginRight: "2px", marginLeft: "2px" }}
                          variant="gradient"
                          color="error"
                          iconOnly
                          onClick={() => {
                            handleDeleteAddon(item.id);
                          }}
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <MDButton
              disable={isLoading}
              onClick={handleSubmit}
              variant="contained"
              color="success"
            >
              {isLoading ? (
                <CircularProgress size={20} sx={{ color: "#fff", marginRight: "5px" }} />
              ) : (
                "Submit"
              )}
            </MDButton>
          </Box>
        </Box>
      </Modal>
      <EditOption
        open={addModalOpen}
        closeBox={AddCloseModal}
        type={OptionType}
        menuId={data?.id}
        data={editData}
        setData={setEditData}
      />
    </>
  );
};
const EditOption = ({ open, closeBox, menuId, type, data, setData }) => {
  const { openAlertBox } = useAlertBox();
  const queryClient = useQueryClient();
  const { id: restaurantId } = useParams();
  const { mutate: createOptionMutate, isLoading: createOptionLoading } = useMutation(
    (data) =>
      postData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus/${menuId}/restaurant-menu-options`,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("restaruant-menu");
        openAlertBox(
          "success",
          " Success",
          "You have successfully created the restaurant menu category"
        );
        closeBox();
      },
      onError: (error) => {
        console.log(error, "error");
        openAlertBox(
          "error",
          " Something went wrong",
          error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
        );
      },
    }
  );
  const { mutate: createAddOnMutate, isLoading: createAddOnLoading } = useMutation(
    (data) =>
      postData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus/${menuId}/restaurant-menu-addons`,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("restaruant-menu");
        openAlertBox(
          "success",
          " Success",
          "You have successfully created the restaurant menu category"
        );
        closeBox();
      },
      onError: (error) => {
        console.log(error, "error");
        openAlertBox(
          "error",
          " Something went wrong",
          error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
        );
      },
    }
  );
  const { mutate: updateAddonMutate, isLoading: updateAddonLoading } = useMutation({
    mutationFn: (body) =>
      postData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus/${menuId}/restaurant-menu-addons/${data?.id}`,
        {
          ...body,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("restaruant-menu");
      openAlertBox("success", " Success", "You have successfully updated the restaurant menu");

      closeBox();
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const { mutate: updateOptionMutate, isLoading: updateOptionLoading } = useMutation({
    mutationFn: (body) =>
      postData(
        `/admin-app/restaurant/${restaurantId}/restaurant-menus/${menuId}/restaurant-menu-options/${data?.id}`,
        {
          ...body,
        }
      ),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the restaurant menu");
      queryClient.invalidateQueries("restaruant-menu");
      closeBox();
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    price: "",
  });
  useEffect(() => {
    if (data) {
      setInputValue({
        name: data?.name,
        price: data?.price,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (type === "option") {
      if (data) {
        updateOptionMutate({ ...inputValue, _method: "PUT" });
        return;
      }
      createOptionMutate(inputValue);
      setInputValue({
        name: "",
        price: "",
      });
      return;
    } else {
      if (data) {
        updateAddonMutate({ ...inputValue, _method: "PUT" });
        return;
      }
      createAddOnMutate(inputValue);
      setInputValue({
        name: "",
        price: "",
      });
      return;
    }
  };
  return (
    <Modal
      open={open}
      onClose={closeBox}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          maxWidth: "25rem",
          height: "auto",
          outline: "none",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
      >
        <MDTypography id="Are you sure ?" color="warning" variant="h4">
          {type === "option" ? "Create Option" : "Create Addon"}
        </MDTypography>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12} sx={{ p: 3, pb: 0, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.name}
                onChange={handleInputChange}
                sx={{ width: "100%" }}
                variant="outlined"
                label=" Name"
                name="name"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.price}
                onChange={handleInputChange}
                sx={{ width: "100%" }}
                variant="outlined"
                label="Price"
                name="price"
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ px: 3, alignItems: "center" }}>
            <MDButton
              type="button"
              sx={{ height: "fit-content", mx: 1 }}
              variant="gradient"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Add
            </MDButton>
          </Grid>
          <Grid item xs={6} sx={{ px: 3, alignItems: "center" }}>
            <MDButton
              type="button"
              height={{ height: "fit-content" }}
              variant="gradient"
              color="warning"
              fullWidth
              onClick={closeBox}
            >
              Cancel
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default RestaurantMenuDetail;
