// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";

function PromoCode() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();

  const {
    data: promocodes,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery("promocode", () => getData("admin-app/promo-codes"), {
    refetchOnWindowFocus: false,
  });

  const columns = [
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Code", accessor: "code", align: "left" },
    { Header: "DiscountPrice", accessor: "discount_price", align: "left" },
    { Header: "IsPercentage", accessor: "is_percentage", align: "left" },
    { Header: "Limit", accessor: "limit", align: "left" },
    { Header: "IsAvailable", accessor: "is_available", align: "left" },
    { Header: "Date", accessor: "date", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );
  const ActionField = ({ valueId }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        variant="gradient"
        style={{ marginRight: "2px", marginLeft: "2px" }}
        iconOnly
        color={"info"}
        onClick={() => {
          navigate("/promo-codes/detail", { state: valueId });
        }}
      >
        <Icon>info</Icon>
      </MDButton>
      <MDButton
        variant="gradient"
        style={{ marginRight: "2px", marginLeft: "2px" }}
        iconOnly
        color={"warning"}
        onClick={() => {
          navigate(`/promo-codes/update`, { state: valueId });
        }}
      >
        <Icon>edit</Icon>
      </MDButton>
      <MDBox mr={1}>
        <MDButton
          onClick={() => {
            handleDelete(valueId);
          }}
          variant="gradient"
          style={{ marginRight: "2px", marginLeft: "2px" }}
          iconOnly
          color={"error"}
        >
          <Icon>delete</Icon>
        </MDButton>
      </MDBox>
    </MDBox>
  );

  promocodes?.result?.data?.map((value) => {
    rows.push({
      name: <TableRowField align="left" title={value.name} />,
      discount_price: <TableRowField align="left" title={value.discount_price} />,
      is_percentage: (
        <TableRowField align="left" title={value?.is_percentage === true ? "Yes" : "No"} />
      ),
      code: <TableRowField align="left" title={value.code} />,
      limit: <TableRowField align="left" title={value.limit} />,
      is_available: (
        <TableRowField align="left" title={value?.is_available === true ? "Yes" : "No"} />
      ),
      date: (
        <TableRowField
          align="left"
          title={value?.start_date.split(" ")[0] + "/" + value?.end_date.split(" ")[0]}
        />
      ),
      action: <ActionField align="left" valueId={value} />,
    });
  });

  const DeleteApiHandler = async (value) => {
    try {
      await deleteData("admin-app/promo-codes/" + value?.id);
      openAlertBox("success", "Delete Success", "You have successfully deleted the PromoCode");
      queryClient.invalidateQueries("promocode");
    } catch (error) {
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
      // console.log(error);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this PromoCode', id);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  PromoCode Table
                </MDTypography>
                <NavLink key={"promo-codes-form"} to={"/promo-codes-form"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PromoCode;
