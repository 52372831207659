import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import MDButton from "components/MDButton";
import { Box, MenuItem, Select, Typography, FormControl, InputLabel } from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import { useEffect, useState } from "react";
import { formatDate } from "lib/helper";
import React from "react";
import { makeStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";
import { postData } from "lib/fetcher";
import DeliveryBox from "components/Utils/DeliveryBox";
const useStyles = makeStyles({
  pending: {
    backgroundColor: "#ffcc00",
    color: "#000000",
    margin: "5px",
    cursor: "pointer",
  },
  canceled: {
    backgroundColor: "#ff0000",
    color: "#ffffff",
    margin: "5px",
    cursor: "pointer",
  },
  doing: {
    backgroundColor: "#00ccff",
    color: "#000000",
    margin: "5px",
    cursor: "pointer",
  },
  done: {
    backgroundColor: "#00ff00",
    color: "#000000",
    margin: "5px",
    cursor: "pointer",
  },
  accpted: {
    backgroundColor: "#c2c2c2",
    color: "#000000",
    margin: "5px",
    cursor: "pointer",
  },
});
function FoodOrderDetail() {
  const [open, setOpen] = useState(false);
  const closeBox = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();
  const [selectedDate, setSelectedDate] = useState(null);
  const [updateOrder, setUpdateOrder] = useState({
    delivery_man_id: null,
    restaurant_status: "PENDING",
    order_status: "PENDING", // Set default value here
  });

  // const { data: deliMenData, isLoading: deliMenLoading } = useQuery(
  //   "delivery-men",
  //   () => getData(`/admin-app/delivery-men`),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const { data, isLoading, error, isError } = useQuery(
    ["office-order", params.id],
    () => getData(`/admin-app/get-food-order-detail/${params.id}`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [deliveryMan, setDeliveryMan] = React.useState(data?.result?.delivery_man?.id);

  useEffect(() => {
    if (data?.result) {
      setUpdateOrder({
        delivery_man_id: deliveryMan ? deliveryMan : data.result.delivery_man?.id,
        restaurant_status: data.result.restaurant_status,
        order_status: data.result.order_status,
      });
    }
  }, [data]);

  useEffect(() => {
    changeDeliveryMan(deliveryMan);
  }, [deliveryMan]);
  const changeDeliveryMan = (id) => {
    setUpdateOrder((prevState) => ({
      ...prevState,
      deliver: { ...prevState.option_data, delivery_man_id: id },
    }));
  };

  const InfoField = ({ title, value }) => (
    <Box sx={{ display: "flex", marginBottom: "20px" }}>
      <Typography variant="h6" sx={{ width: "200px" }}>
        {title}
      </Typography>
      <Typography variant="h6" sx={{ width: "30px" }}>
        -
      </Typography>
      <Typography variant="h6" sx={{ width: "200px" }}>
        {value}
      </Typography>
    </Box>
  );

  const OrderItem = ({ item }) => (
    <Box key={item.id} sx={{ display: "flex", marginBottom: "20px" }}>
      <Typography variant="h6" sx={{ width: "200px" }}>
        {item.restaurant_menu_name}*{item.quantity}
      </Typography>
      <Typography variant="h6" sx={{ width: "30px" }}>
        -
      </Typography>
      <Typography variant="h6" sx={{ width: "200px" }}>
        {item.total_price}
      </Typography>
    </Box>
  );

  const ORDER_STATUS = [
    { value: "PENDING", label: "Pending" },
    { value: "RESTAURANT_CANCELLED", label: "Restaurant Cancelled" },
    { value: "RESTAURANT_COOKING", label: "Cooking" },
    { value: "RESTAURANT_DONE", label: "Restaurant Done" },
  ];

  const RESTAURANT_STATUS = [
    { value: "PENDING", label: "Pending" },
    { value: "RESTAURANT_CANCELLED", label: "Restaurant Cancelled" },
    { value: "RESTAURANT_COOKING", label: "Cooking" },
    { value: "RESTAURANT_DONE", label: "Restaurant Done" },
  ];

  const OrderStatus = ({ status }) => {
    const classes = useStyles();

    let label = "";
    let className = "";
    switch (status) {
      case "PENDING":
        label = "Pending";
        className = classes.pending;
        break;
      case "RESTAURANT_CANCELLED":
        label = "Cancelled";
        className = classes.cancelled;
        break;
      case "RESTAURANT_COOKING":
        label = "Cooking";
        className = classes.doing;
        break;
      case "RESTAURANT_DONE":
        label = "Done";
        className = classes.done;
        break;
      default:
        break;
    }
    return <Chip label={label} className={className} />;
  };

  const PaymentStatus = ({ status }) => {
    const classes = useStyles();
    let label = "";
    let className = "";
    switch (status) {
      case "PAYMENT_DONE":
        label = "Done";
        className = classes.done;
        break;
      case "PAYMENT_UNDONE":
        label = "Undone";
        className = classes.pending;
        break;
      default:
        break;
    }
    return <Chip label={label} className={className} />;
  };
  const DeliveryStatus = ({ status }) => {
    const classes = useStyles();
    let label = "";
    let className = "";
    switch (status) {
      case "PENDING":
        label = "Pending";
        className = classes.pending;
        break;
      case "DELIVERY_ACCEPTED":
        label = "Accepted";
        className = classes.accpted;
        break;
      case "DELIVERY_PICKING_UP":
        label = "Picking up";
        className = classes.doing;
        break;
      case "DELIVERY_DONE":
        label = "Done";
        className = classes.done;
        break;

      default:
        break;
    }
    return <Chip label={label} className={className} />;
  };

  const RestaurantStatus = ({ status }) => {
    const classes = useStyles();
    let label = "";
    let className = "";
    switch (status) {
      case "PENDING":
        label = "Pending";
        className = classes.pending;
        break;
      case "RESTAURANT_CANCELLED":
        label = "Canceled";
        className = classes.canceled;
      case "RESTAURANT_ACCEPTED":
        label = "Accepted";
        className = classes.accpted;
        break;
      case "RESTAURANT_COOKING":
        label = "Cooking";
        className = classes.doing;
        break;
      case "RESTAURANT_DONE":
        label = "Done";
        className = classes.done;
        break;

      default:
        break;
    }
    return <Chip label={label} className={className} />;
  };

  const handleOrderStatusChange = (event) => {
    setUpdateOrder((prev) => {
      return { ...prev, order_status: event.target.value };
    });
  };

  const handleRestaurantStatusChange = (event) => {
    setUpdateOrder((prev) => {
      return { ...prev, restaurant_status: event.target.value };
    });
  };

  const { mutate, isLoading: updateLoading } = useMutation({
    mutationFn: async (e) => {
      e.preventDefault();
      let formData = new FormData();
      formData.append("delivery_man_id", deliveryMan ? deliveryMan : updateOrder.delivery_man_id);
      formData.append("restaurant_status", updateOrder.restaurant_status);
      formData.append("order_status", updateOrder.order_status);
      formData.append("_method", "put");
      return await postData(`admin-app/update-food-orders/${data?.result?.id}`, formData);
    },
    onSuccess: () => {
      openAlertBox("success", "Success", "Foode Order has been successfully updated");
      navigate("/food-order");
    },
    onError: (error) => {
      openAlertBox("error", "Error", getErrorMessage(error.message));
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3} component="form" onSubmit={mutate}>
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ marginBottom: "20px" }}>
            Food ORDER DETAIL
          </Typography>
          <Grid container spacing={2} marginBottom={"20px"}>
            <Grid item xs={4}>
              <Card
                sx={{ p: 3, backgroundColor: "#12AB6810", minHeight: "10rem", boxShadow: "none" }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5">ORDER INFO</Typography>
                  {/* <OrderStatus status={data?.result?.order_status} /> */}
                  {updateOrder && (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-select-small-label">Change Status</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={updateOrder?.order_status}
                        label="Change Status"
                        autoWidth
                        onChange={handleOrderStatusChange}
                      >
                        {ORDER_STATUS.map((option) => (
                          <MenuItem value={option.value} key={option.value} sx={{ p: "4px" }}>
                            <OrderStatus status={option.value} />
                            {/* {option.value} */}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>

                <InfoField title={"Date"} value={formatDate(data?.result?.order_date)} />
                <InfoField title="Order Number" value={data?.result?.order_number} />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{ p: 3, backgroundColor: "#12AB6810", minHeight: "10rem", boxShadow: "none" }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5">DELIVERY INFO</Typography>{" "}
                  <DeliveryStatus status={data?.result?.delivery_status} />
                </Box>
                <InfoField title={"ID"} value={data?.result?.delivery_man?.id} />

                <InfoField title={"Name"} value={data?.result?.delivery_man?.name} />
                <InfoField title={"Price"} value={data?.result?.delivery_price} />
                <MDBox display="">
                  <Typography>Change ID: {deliveryMan}</Typography>
                  <MDBox sx={{ width: "100%" }} mb={1}>
                    <MDButton
                      type="button"
                      onClick={() => setOpen(true)}
                      variant="gradient"
                      color="dark"
                      fullWidth
                    >
                      Change Delivery Man
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{ p: 3, backgroundColor: "#12AB6810", minHeight: "10rem", boxShadow: "none" }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5">PAYMENT INFO</Typography>
                  <PaymentStatus status={data?.result?.payment_status} />
                </Box>
                <InfoField title="Payment Method" value={data?.result?.payment_method.provider} />
                <InfoField title="Total Price" value={data?.result?.total_price} />
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Card sx={{ p: 3, backgroundColor: "#12AB6810", boxShadow: "none" }}>
                <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                  USER INFO
                </Typography>
                <InfoField title="Name" value={data?.result?.user?.name} />
                <InfoField title="Phone" value={data?.result?.user?.phone} />
                <InfoField title="Email" value={data?.result?.user?.email} />
                <InfoField title="Address" value={data?.result?.user_address?.address} />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ p: 3, backgroundColor: "#12AB6810", boxShadow: "none" }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5">RESTAURANT INFO</Typography>
                  {/* <RestaurantStatus status={data?.result?.restaurant_status} /> */}
                  {updateOrder !== {} && (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-select-small-label">Change Status</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={updateOrder?.restaurant_status}
                        label="Change Status"
                        autoWidth
                        onChange={handleRestaurantStatusChange}
                      >
                        {RESTAURANT_STATUS.map((option) => (
                          <MenuItem value={option.value} key={option.value} sx={{ p: "4px" }}>
                            <RestaurantStatus status={option.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>
                <InfoField title="Name" value={data?.result?.restaurant?.name} />
                <InfoField title="Address" value={data?.result?.restaurant?.address} />
                <InfoField title="Phone" value={data?.result?.restaurant?.phone} />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ p: 3, backgroundColor: "#12AB6810", boxShadow: "none" }}>
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: "20px" }}
                >
                  <Typography variant="h5">ORDER INFO</Typography>
                  <OrderStatus status={data?.result?.order_status} />
                </Box> */}

                {/* TODO: order items */}
                <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                  ORDER ITEMS
                </Typography>
                {data?.result?.order_details?.map((order) => (
                  <OrderItem item={order} />
                ))}
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ py: 2 }}>
            <MDBox sx={{ width: 300, float: "left" }} mb={1}>
              <MDButton
                type="submit"
                // disabled={isLoading}
                variant="gradient"
                color="light"
                fullWidth
                onClick={() => navigate("/food-order")}
              >
                {isLoading ? "Loading..." : "Cancel"}
              </MDButton>
            </MDBox>
            <MDBox sx={{ width: 300, float: "right" }} mb={1}>
              <MDButton type="submit" disabled={updateLoading} color="success" fullWidth>
                {isLoading ? "Submiting..." : "Save"}
              </MDButton>
            </MDBox>
          </Grid>
        </Card>
      </MDBox>
      <DeliveryBox
        open={open}
        setDeliveryMan={setDeliveryMan}
        deliveryMan={deliveryMan}
        closeBox={closeBox}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default FoodOrderDetail;
