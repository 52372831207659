import { store } from "./lib/store";

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Notifications } from "react-push-notification";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React components

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import SignIn from "layouts/authentication/sign-in";

// Material Dashboard 2 React themes
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "./routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandDark from "assets/images/logos/favicon.png";

import RestaruantDetail from "layouts/RestaruantManagement/RestaruantDetail";
import RestaruantForm from "layouts/RestaruantManagement/RestaurantForm";

//Orders
import OfficeOrder from "layouts/OfficeOrder";
import OfficeOrderForm from "layouts/OfficeOrder/OfficeOrderForm";
import OfficeOrderDetail from "layouts/OfficeOrder/OfficeOrderDetail";
import FoodOrder from "layouts/FoodOrder";
import FoodOrderDetail from "layouts/FoodOrder/FoodOrderDetail";
import OrderForm from "layouts/Order/OrderForm";
import ParcelDelivery from "layouts/ParcelDelivery";
import ParcelDeliveryDetail from "layouts/ParcelDelivery/ParcelDeliveryDetail";

//Restaurant
import RestaruantManagement from "layouts/RestaruantManagement";
import RestaruantCategory from "layouts/restaurantCategory";
import RestaruantMenuCategory from "layouts/RestaruatnMenuCategory";
import RestaurantCreateForm from "layouts/RestaurantCreateForm";

//DeliveryMan
import DeliveryMan from "layouts/DeliveryMan";
import WayRecords from "layouts/WayRecords";
//general
import PaymentMethod from "layouts/PaymentMethod";
import Order from "layouts/Order";
import Recommend from "layouts/Recommend";
import PromoCode from "layouts/PromoCode";
import PlaceAndPrices from "layouts/PlaceAndPrice";
import Places from "layouts/places";

import { Provider } from "react-redux";
import GlobalLayout from "layouts/GlobalLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import DeliveryManForm from "layouts/DeliveryMan/DeliveryManForm";
import RecommendForm from "layouts/Recommend/RecommendForm";
import RecommendDetail from "layouts/Recommend/RecommendDetail";
import PlacesForm from "layouts/places/PlacesForm";
import PromoCodeForm from "layouts/PromoCode/PromoCodeForm";
import PromoCodeDetail from "layouts/PromoCode/PromoCodeDetail";
import RestaruantCategoryForm from "layouts/restaurantCategory/restaurantCategoryForm";
import RestaurantMenuDetail from "layouts/RestaruantManagement/RestaurantMenuDetail";
import DeliveryManDetail from "layouts/DeliveryMan/DeliveryManDetail";
import WayRecordDetail from "layouts/WayRecords/WayRecordDetail";
import DeliveryManUsageForm from "layouts/DeliveryManUsage/DeliveryManUsageForm";
import DeliveryManUsage from "layouts/DeliveryManUsage";
import PlaceAndPriceForm from "layouts/PlaceAndPrice/PlaceAndPriceForm";
import DeliveryManUsageDetail from "layouts/DeliveryManUsage/DeliveryManUsageDetail";
import HomePage from "layouts/HomePage";
import PaymentMethodForm from "layouts/PaymentMethod/PaymentMethodForm";
import UserAddressPlaces from "layouts/userAddressPlaces";
import UserAddressPlacesForm from "layouts/userAddressPlaces/UserAddressPlacesForm";
import Wards from "layouts/Ward";
import WardsForm from "layouts/Ward/WardForm";
import Banners from "layouts/UserSite/Banners";
import Sections from "layouts/UserSite/Sections";
import Posts from "layouts/UserSite/Posts";
import HeaderAds from "layouts/HeaderAds";
import HeaderAdsForm from "layouts/HeaderAds/HeaderAdsForm";
import SpecialAds from "layouts/SpecialAds";
import SpecialAdsForm from "layouts/SpecialAds/SpecialAdsForm";

import ConfirmLeaves from "layouts/DeliveryMan/ConfrimLeaves";

import MapContainer from "layouts/map";
import Dashboard from "layouts/dashboard";
import ChatHome from "layouts/chat";
import RestaurantMenuCategoryForm from "layouts/RestaruatnMenuCategory/restaurantMenuCategoryForm";
import ExpenseForm from "layouts/Expense/expenseForm";
import Expense from "layouts/Expense";
import IncomeList from "layouts/Income";
import IncomeDetail from "layouts/Income/IncomeDetail";
import Ads from "layouts/UserSite/Ads";
import DeliveryManSalary from "layouts/DeliveryManUsage/DeliverySalary";
import addNotification from "react-push-notification";
import useAlertBox from "lib/hooks/useAlertBox";
import noti from "./assets/audio/noti.mp3";
import logo from "./assets/images/logos/myenda-logo.png";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "lib/firebase";
import AdditionalIncome from "layouts/AdditionalIncome";
import AdditionalIncomeForm from "layouts/AdditionalIncome/AdditionalIncomeForm";
import Setting from "layouts/setting";
import Users from "layouts/users";
import Profile from "layouts/Profile";
const queryClient = new QueryClient();

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, openConfigurator, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [firstMount, setFirstMount] = useState(false);
  const [chat, setChat] = useState(0);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  const showBrowserNotification = (data) => {
    const notification = new Notification(
      `Messages Recieved from  ${data?.username ?? "SomeOne"} `,
      {
        icon: logo,
        body: `${data?.lastMessage > 20 ? data?.message?.slice(0, 20) + "..." : data?.lastMessage}`,
        tag: "unique-tag-for-notification",
      }
    );

    notification.onclick = () => navigate("/chat");
    notification.onshow = () => {
      playSong();
    };
  };

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
              // You can now show notifications.
            } else if (permission === "denied") {
              console.log("Notification permission denied.");
            }
          })
          .catch((error) => {
            console.error("Error while requesting notification permission:", error);
          });
      } else {
        console.log("Notifications are not supported in this browser.");
      }
    }
  }, []);

  useEffect(() => {
    let isMounted = false;
    const chatCollectionRef = collection(db, "admin_chat");

    const q = query(
      chatCollectionRef,
      where("isAdminRead", "==", false),
      orderBy("lastMessageTime", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chatData = [];
      querySnapshot.forEach((doc) => {
        chatData.push({ ...doc.data(), id: doc.id });
      });

      if (chatData.length > chat && isMounted) {
        console.log("jfiwjfiowfjwioefjo");
        handleNotification(chatData[0]);
      } else {
        if (!isMounted) {
          isMounted = true;
        }
      }
      setChat(chatData.length);
    });

    return () => {
      unsubscribe();
    };
  }, [firstMount]);

  const handleNotification = (data) => {
    if ("Notification" in window && Notification.permission === "granted") {
      showBrowserNotification(data);
    } else if ("Notification" in window && Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showBrowserNotification(data);
        }
      });
    } else {
      console.log("DDDDDD");
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
      
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }
  //     return null;
  //   });

  const { openAlertBox } = useAlertBox();
  const navigate = useNavigate();
  // const buttonClick = () => {
  //   addNotification({
  //     title: "Alert",
  //     subtitle: "New Message Recieved",
  //     duration: 9000,
  //     onClick: function () {
  //       navigate("/chat");
  //     },
  //     message: "This is a very long message",
  //     theme: "darkblue",
  //     native: true, // when using native, your OS will handle theming.
  //   });
  // };

  const playSong = () => {
    console.log("play");
    const audio = new Audio(noti);
    var resp = audio.play();
    if (resp !== undefined) {
      resp.then(_ => {
        // autoplay starts!
      }).catch(error => {
        alert('message accepted and go to chat')
      });
    }
  };

  return (
    <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
      <GlobalLayout>
        <CssBaseline />
        <Notifications />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={"primary"}
              brand={brandDark}
              brandName="Myenda"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {/* {layout === "vr" && <Configurator />} */}
        <Routes>
          {/* {getRoutes(routes)} */}

          {/* Start For Delivery Man Routes*/}
          <Route path="/" element={<HomePage />} key="static" />
          <Route path="/authentication/sign-in" element={<SignIn />} key="static" />
          <Route
            path="/delivery-men-detail"
            element={<DeliveryManDetail />}
            key="delivery-men-detail"
          />
          <Route
            path="/delivery-men-salary/:id"
            element={<DeliveryManSalary />}
            key="delivery-men-salary"
          />
          <Route path="/delivery-men-edit" element={<DeliveryManForm />} key="delivery-men-edit" />
          <Route path="/delivery-men-form" element={<DeliveryManForm />} key="delivery-men-form" />
          {/* End For Delivery Man Routes */}

          {/* Start For Delivery Man Usage Routes*/}
          <Route
            path="/delivery-men-usage/:id"
            element={<DeliveryManUsage />}
            key="delivery-men-usage"
          />

          <Route
            path="/delivery-men-usage-edit/:id"
            element={<DeliveryManUsageForm />}
            key="delivery-men-usage-edit"
          />

          <Route
            path="/delivery-men-usage-form/:id"
            element={<DeliveryManUsageForm />}
            key="delivery-men-usage-form"
          />

          <Route
            path="/delivery-men-usagee-detail"
            element={<DeliveryManUsageDetail />}
            key="delivery-men-usage-detail"
          />
          <Route path="/profile" element={<Profile />} key="profile" />
          <Route path="/delivery-men" element={<DeliveryMan />} key="delivery-men" />
          <Route path="/deli-leaves" element={<ConfirmLeaves />} key="deli-leaves" />
          <Route path="/way-records/:id" element={<WayRecords />} key="way-records" />

          {/* End For Delivery Man Usage Routes */}

          <Route
            path="/restaruant-management"
            element={<RestaruantManagement />}
            key="restaruant-management"
          />
          <Route path="/restaruant-form" element={<RestaurantCreateForm />} key="restaruant-form" />
          <Route
            path="/restaruant-category"
            element={<RestaruantCategory />}
            key="restaruant-category"
          />
          <Route
            path="/restaruant-menu-category"
            element={<RestaruantMenuCategory />}
            key="restaruant-menu-category"
          />
          <Route
            path="/restaruant-menu-category/create"
            element={<RestaurantMenuCategoryForm />}
            key="restaruant-menu-category"
          />
          <Route
            path="/restaruant-menu-category/update"
            element={<RestaurantMenuCategoryForm />}
            key="restaruant-menu"
          />

          <Route
            path="/restaruant-management/detail/:id"
            element={<RestaruantDetail />}
            key="restaruant-management-detail"
          />
          <Route
            path="/restaruant-category/update"
            element={<RestaruantCategoryForm />}
            key="restaruant-management"
          />
          <Route
            path="/restaruant-category/create"
            element={<RestaruantCategoryForm />}
            key="restaruant-management"
          />
          {/* End For Restaurant Routes */}
          {/* Start For Recommends Routes*/}
          <Route path="/recommends/update" element={<RecommendForm />} key="recommends-update" />
          <Route path="/recommends-form" element={<RecommendForm />} key="recommends-form" />
          <Route path="/recommends/detail" element={<RecommendDetail />} key="recommends-detail" />
          {/* End For Recommends Routes */}
          {/* Start For Promo-Codes Routes*/}
          <Route path="/promo-codes-form" element={<PromoCodeForm />} key="promo-codes-form" />
          <Route path="/promo-codes/update" element={<PromoCodeForm />} key="promo-codes-update" />
          <Route
            path="/promo-codes/detail"
            element={<PromoCodeDetail />}
            key="promo-codes-detail"
          />
          {/* End For Promo-Codes Routes */}
          {/* Start For Expense Routes*/}
          <Route path="/expense/create" element={<ExpenseForm />} key="promo-codes-form" />
          <Route path="/expense/update" element={<ExpenseForm />} key="promo-codes-update" />
          <Route path="/expense" element={<Expense />} key="promo-codes-detail" />
          {/* End For Expense Routes */}

          {/* Start For Way Records Routes*/}
          <Route
            path="/way-records-detail"
            element={<WayRecordDetail />}
            key="way-records-detail"
          />
          {/* End For Way Records Routes */}

          <Route
            path="/restaruant-management/update"
            element={<RestaruantForm />}
            key="restaruant-management"
          />
          <Route
            path="/restaruant-management/create"
            element={<RestaruantForm />}
            key="restaruant-management"
          />
          <Route
            path="/restaruant-management/detail/:id/res-menu"
            element={<RestaurantMenuDetail />}
            key="restaruant-management"
          />
          <Route
            path="/restaruant-management/:id"
            element={<RestaruantForm />}
            key="restaruant-management"
          />
          <Route
            path="/payment-method/update"
            element={<PaymentMethodForm />}
            key="payment-method"
          />

          <Route path="/order-form/create" element={<OrderForm />} key="payment-method" />
          <Route path="/order-form/edit" element={<OrderForm />} key="payment-method" />

          <Route path="/office-order" element={<OfficeOrder />} key="office-order" />
          <Route path="/office-order/create" element={<OfficeOrderForm />} key="office-order" />
          <Route
            path="/office-order/:id"
            element={<OfficeOrderDetail />}
            key="office-order-detail"
          />
          <Route path="/food-order" element={<FoodOrder />} key="food-order" />
          <Route path="/food-order/:id" element={<FoodOrderDetail />} key="food-order-detail" />
          <Route
            path="/parcel-delivery/"
            element={<ParcelDelivery />}
            key="parcel-delivery-detail"
          />
          <Route
            path="/parcel-delivery/:id"
            element={<ParcelDeliveryDetail />}
            key="parcel-delivery"
          />
          <Route path="/office-order/update" element={<OfficeOrderForm />} key="office-order" />
          <Route path="/places/create" element={<PlacesForm />} key="places" />
          <Route path="/places/update" element={<PlacesForm />} key="places" />
          <Route path="/additionalIncome" element={<AdditionalIncome />} key="places" />
          <Route path="/additionalIncome/create" element={<AdditionalIncomeForm />} key="places" />
          <Route path="/additionalIncome/update" element={<AdditionalIncomeForm />} key="places" />
          <Route path="/places/map" element={<MapContainer />} key="places-map" />

          <Route
            path="/place-and-prices/create"
            element={<PlaceAndPriceForm />}
            key="place-and-prices"
          />
          <Route path="/place-and-prices/update" element={<PlaceAndPriceForm />} key="income" />
          <Route path="/income" element={<IncomeList />} key="place-and-prices" />
          <Route path="/income/detail" element={<IncomeDetail />} key="income" />

          {/* genearal route */}
          <Route path="/payment-method" element={<PaymentMethod />} key="payment-method" />
          <Route path="/order-from" element={<Order />} key="order-from" />
          <Route path="/recommends" element={<Recommend />} key="recommends" />
          <Route path="/promo-code" element={<PromoCode />} key="promo-code" />
          <Route path="/places" element={<Places />} key="places" />
          <Route path="/place-and-prices" element={<PlaceAndPrices />} key="place-and-prices" />
          <Route
            path="/user-address-places"
            element={<UserAddressPlaces />}
            key="user-address-places"
          />
          <Route path="/map" element={<MapContainer />} key="map" />
          {/* Ads */}
          <Route path="/header-ads" element={<HeaderAds />} key="header-ads" />
          <Route path="/header-ads/create" element={<HeaderAdsForm />} key="header-ads" />
          <Route path="/header-ads/update" element={<HeaderAdsForm />} key="header-ads" />
          <Route path="/special-ads" element={<SpecialAds />} key="header-ads" />
          <Route path="/special-ads/create" element={<SpecialAdsForm />} key="header-ads" />
          <Route path="/special-ads/update" element={<SpecialAdsForm />} key="header-ads" />
          <Route
            path="/user-address-places/create"
            element={<UserAddressPlacesForm />}
            key="user-address-places"
          />
          <Route
            path="/user-address-places/update"
            element={<UserAddressPlacesForm />}
            key="user-address-places"
          />

          <Route path="/wards" element={<Wards />} key="wards" />
          <Route path="/wards/create" element={<WardsForm />} key="wards" />
          <Route path="/wards/update" element={<WardsForm />} key="wards" />
          <Route path="/user-site/banners" element={<Banners />} key="banners" />
          <Route path="/user-site/sections" element={<Sections />} key="sections" />
          <Route path="/user-site/ads" element={<Ads />} key="sections" />
          <Route path="/user-site/posts" element={<Posts />} key="posts" />

          <Route path="/dashboard" element={<Dashboard />} key="dashboard" />
          {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}

          <Route path={"/chat"} element={<ChatHome />} key="chat" />
          <Route path={"/users"} element={<Users />} key="user" />
          <Route path={"/setting"} element={<Setting />} key="setting" />
        </Routes>
      </GlobalLayout>
    </ThemeProvider>
  );
}
