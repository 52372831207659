import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import HeroPhoto from "../assets/images/hero-img.svg";
import Playstore from "../assets/images/playstore.png";
import Appstore from "../assets/images/appstore.png";
import Footer from "components/home/Footer";
import Logo from "assets/images/logos/logo.png";
import Navbar from "components/home/Navbar";
import NewsAndBlogs from "components/home/NewsAndBlogs";
import HomeBanner from "components/home/HomeBanner";
import HomeSections from "components/home/HomeSections";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "lib/firebase";

const Container = styled("section")`
  height: calc(100vh - 3rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem;
  background-color: #17aa6b;
  margin-top: -1rem;
  h2 {
    color: #fff;
    font-size: 6rem;
  }
  .hero-text {
    color: ${({ theme }) => theme.palette.grey[200]};
    font-size: 1.5rem;
  }
  img {
    width: 100%;
    max-width: 20rem;
  }
  @media screen and (max-width: 1024px) {
    height: max-content;
    .hero-img {
      display: none;
    }
  }
`;
const Downloads = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 5rem;
  a {
    max-width: 15rem;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
`;

const MyendaHomePage = () => {
  const [downLoadLink, setDownLoadLink] = useState([]);
  async function getsections() {
    try {
      const q = query(collection(db, "downloadLink"));
      const res = await getDocs(q);
      const arr = [];

      res.forEach((doc) => arr.push(doc.data()));
      console.log(arr);
      setDownLoadLink(arr);
    } catch (error) {
      openAlertBox("error", error.message || "Unexpected error");
    }
  }
  useEffect(() => {
    getsections();
  }, []);

  return (
    <>
      <Navbar />
      <HomeBanner />
      <HomeSections />
      <Container>
        <div>
          <img src={Logo} alt="" />
          <Downloads>
            <a href={downLoadLink[0]?.link}>
              <img src={Playstore} alt="Get It On Playstore" />
            </a>
            <a href="">
              <img src={Appstore} alt="Get It ON Playstore" />
            </a>
          </Downloads>
        </div>
        <img src={HeroPhoto} alt="Home" className="hero-img" />
      </Container>
      <NewsAndBlogs />
      <Footer />
    </>
  );
};

export default MyendaHomePage;
