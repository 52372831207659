// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import { Icon, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getCurrentDate } from "lib/helper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { formatDate } from "lib/helper";

function DeliveryManUsage() {
  const navigate = useNavigate();
  const { id: delivery_man_id } = useParams();

  // console.log("s", state)
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();
  const [selectedDate, setSelectedDate] = useState(null);

  const { data: deliveries, isLoading } = useQuery(
    ["delivery-man-usage", { selectedDate, delivery_man_id }],
    () =>
      getData(
        `admin-app/delivery-man-usage?date=${
          selectedDate ? selectedDate : getCurrentDate()
        }&delivery_man_id=${parseInt(delivery_man_id) ? parseInt(delivery_man_id) : 1}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleDateChange = (e) => {
    let date = new Date(e.$d);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let payload = year + "-" + month + "-" + day;
    setSelectedDate(payload);
    queryClient.invalidateQueries("delivery-man-usage");
  };

  const columns = [
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Price", accessor: "price", align: "left" },
    { Header: "Date", accessor: "date", align: "left" },
    { Header: "Usage", accessor: "usage_for", align: "left" },
    { Header: "Reason", accessor: "reason", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );
  const ActionField = ({ valueId }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        variant="text"
        color={"dark"}
        onClick={() => {
          navigate("/delivery-men-usagee-detail", { state: valueId });
        }}
      >
        <Icon>info</Icon>&nbsp;detail
      </MDButton>
      <MDButton
        variant="text"
        color={"dark"}
        onClick={() => {
          navigate(`/delivery-men-usage-edit/${delivery_man_id}`, { state: valueId });
        }}
      >
        <Icon>edit</Icon>&nbsp;Edit
      </MDButton>
      <MDBox mr={1}>
        <MDButton
          variant="text"
          onClick={() => {
            handleDelete(valueId);
          }}
          color="error"
        >
          <Icon>delete</Icon>&nbsp;delete
        </MDButton>
      </MDBox>
    </MDBox>
  );

  deliveries?.result?.data?.map((value) => {
    rows.push({
      name: <TableRowField align="left" title={value.delivery_man.name} />,
      price: <TableRowField align="left" title={value.value} />,
      date: <TableRowField align="left" title={formatDate(value.date)} />,
      usage_for: <TableRowField align="left" title={value.usage_for} />,
      reason: <TableRowField align="left" title={value.reason} />,
      action: <ActionField align="left" valueId={value} />,
    });
  });

  const DeleteApiHandler = async (value) => {
    try {
      await deleteData("admin-app/delivery-man-usage/" + value?.id);
      openAlertBox("success", "Delete Success", "You have successfully deleted the Delivery Usage");
      queryClient.invalidateQueries("delivery-man-usage");
    } catch (error) {
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this Delivery Usage', id);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  Delivery Man Usage Table
                </MDTypography>

                <NavLink
                  key={"delivery-men-usage-form"}
                  to={`/delivery-men-usage-form/${delivery_man_id}`}
                >
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox mt={2} mb={1} display="flex" alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    sx={{ m: 2 }}
                    value={selectedDate ? selectedDate : getCurrentDate()}
                    label="Date"
                    inputFormat="YYYY-MM-DD"
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} sx={{ mx: 2 }} size="small" />}
                  />
                </LocalizationProvider>
              </MDBox>
              <MDBox mb={2}></MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeliveryManUsage;
