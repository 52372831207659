import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertBox: {
    show: false,
    title: "",
    content: "",
    type: "success",
  },
  confirmBox: {
    show: false,
    content: "",
  },
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    toggleAlertBox: (state, action) => {
      if (action.payload.close) {
        state.alertBox = {
          show: false,
          title: "",
          content: "",
          type: "success",
        };
        return;
      }

      state.alertBox = action.payload.alertBox;
    },
    toggleConfirmBox: (state, action) => {
      if (action.payload.close) {
        state.confirmBox = { show: false, content: "" };
        return;
      }
      state.confirmBox = action.payload.confirmBox;
    },
  },
});

export const { toggleAlertBox, toggleConfirmBox } = generalSlice.actions;

export default generalSlice.reducer;
