import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useConfirmBox from "lib/hooks/useConfirmBox";

export default function ConfirmBox() {
  const { open, closeConfirmBox, onConfirm, content, id } = useConfirmBox();
  return (
    <Modal
      open={open}
      onClose={closeConfirmBox}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          minHeight: "10rem",
          width: "100%",
          maxWidth: "25rem",
          outline: "none",
          borderRadius: "0.5rem",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <MDTypography id="Are you sure ?" color="warning" variant="h4">
          Are you sure ?
        </MDTypography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {content ? content : 'Click "Confirm" button to proceed'}
        </Typography>
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "4rem" }}>
          <Button onClick={closeConfirmBox} variant="text" size="medium" color="success">
            Cancel
          </Button>
          <MDButton
            onClick={() => {
              id ? onConfirm(id) : onConfirm();
              closeConfirmBox();
            }}
            variant="contained"
            color="warning"
            size="medium"
          >
            Confirm
          </MDButton>
        </Stack>
      </Box>
    </Modal>
  );
}
