// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { postData, getData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const initalData = {
    restaurant_id: "",
    order: "",
    start_date: '',
    end_date: '',
};

const RecommendForm = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log(state);
    const { openAlertBox } = useAlertBox();
    const queryClient = useQueryClient();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [stateCategory, setStateCategory] = useState('');
    //   const [data, setData] = React.useState(state ? state : initalData);

    const [inputValue, setInputValue] = useState(state ? state : initalData);

    const inputChangeHandler = (event) => {
        const { name, value } = event.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const { data: restaurants, isLoading, error, isError, isSuccess } = useQuery(
        "restaurants",
        () => getData("admin-app/restaurants"),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { mutate, isLoading: updateLoading } = useMutation({
        mutationFn: async (body) => {
            if (state?.id) {
                 postData(`admin-app/recommends/${state?.id}`, {...body, _method: "put"});
            } else {
               postData("admin-app/recommends", {...body});
            }
        },
        
        onSuccess: () => {
            openAlertBox("success", " Success", "You have successfully create the Recommends");
            queryClient.invalidateQueries("restaruant");
            navigate("/recommends");
        },
        onError: (error) => {
            console.log(error, "error");
            openAlertBox(
                "error",
                " Something went wrong",
                error?.message || "Something went wrong"
            );
        },
    });

    let nullDate;
    let date = new Date();
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        date
    );
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
        date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let payload = year + "-" + month + "-" + day;
    nullDate = payload;

    const handleSubmit = (e) => {
        // console.log("inputValue", inputValue)
        e.preventDefault();
        if (inputValue.start_date === '') {
            inputValue.start_date = nullDate
        } else if (inputValue.end_date === '') {
            inputValue.end_date = nullDate
        }
        mutate({
            restaurant_id: inputValue.restaurant_id ?? stateCategory,
            order: inputValue.order,
            start_date: inputValue.start_date,
            end_date: inputValue.end_date,
        });
    };

    const handleChangeStartDate = (e) => {
        // console.log("st", dayjs(e))
        setStartDate(e?.$d);
        let date = new Date(e.$d);
        let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
            date
        );
        let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
            date
        );
        let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
        let payload = year + "-" + month + "-" + day;
        setInputValue({ ...inputValue, start_date: payload })
    };

    const handleChangeEndDate = (e) => {
        setEndDate(e?.$d);
        let date = new Date(e.$d);
        let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
            date
        );
        let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
            date
        );
        let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
        let payload = year + "-" + month + "-" + day;
        setInputValue({ ...inputValue, end_date: payload })

    };


    const handleChange = (event) => {
        setInputValue({ ...inputValue, restaurant_id: event.target.value })
    };

    useEffect(() => {
        const data = restaurants?.result?.data?.filter((res) => res?.name == state?.name)
        setStateCategory(data?.shift()?.id)
    }, [restaurants, state])


    // console.log("startDate", startDate)
    // console.log("inputValue", inputValue)
    // console.log("inputValue.restaurant_id", inputValue?.restaurant_id)

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}
            >
                <Grid container spacing={6}
                    sx={{ display: "flex" }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={6}>
                        <MDBox mb={2}>
                            <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label">Restaurants</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inputValue.restaurant_id ?? stateCategory}
                                    label="Restaurants"
                                    onChange={handleChange}
                                    sx={{ padding: "13px" }}
                                >
                                    {
                                        restaurants?.result?.data?.map((rest) => (
                                            <MenuItem required key={rest.id} value={rest?.id}>{rest?.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                value={inputValue.order}
                                onChange={inputChangeHandler}
                                type="text"
                                label="Order"
                                name="order"
                                fullWidth
                                required
                            />
                        </MDBox>
                        <MDBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label='Start Date'
                                    inputFormat="YYYY-MM-DD"
                                    value={startDate === '' ? state?.start_date : startDate}
                                    onChange={handleChangeStartDate}
                                    renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} size="small" />}
                                />
                                <DesktopDatePicker
                                    label='End Date'
                                    inputFormat="YYYY-MM-DD"
                                    value={endDate === '' ? state?.end_date : endDate}
                                    onChange={handleChangeEndDate}
                                    renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} size="small" />}
                                />
                            </LocalizationProvider>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton
                                // disabled={isLoading}
                                onClick={handleSubmit}
                                variant="gradient"
                                color="info"
                                fullWidth
                            >
                                {state ? 'Updated' : 'Create'}
                            </MDButton>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default RecommendForm