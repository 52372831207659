/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Box, Icon, Pagination, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlertBox from "lib/hooks/useAlertBox";
import { useQuery, useQueryClient } from "react-query";
import { getData, deleteData } from "lib/fetcher";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import useConfirmBox from "lib/hooks/useConfirmBox";
import { useState } from "react";

function Wards() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openAlertBox } = useAlertBox();
  const { openConfirmBox } = useConfirmBox();
  const [paginationPage, setPaginationPage] = useState(1);
  const {
    data: categories,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery(
    ["wards", paginationPage || 1],
    () => getData(`/admin-app/wards?page=${paginationPage || 1}`),
    {
      refetchOnWindowFocus: false,
    }
  );
  const columns = [
    { Header: "Name", accessor: "name", align: "center" },
    { Header: "Places", accessor: "places", align: "center" },

    { Header: "Action", accessor: "action", align: "center" },
  ];
  const rows = [];
  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const PlacesRow = ({ places }) =>
    places.map((p) => (
      <MDTypography
        key={p.id}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        display="block"
      >
        {p.name}
      </MDTypography>
    ));

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          navigate(`/wards/update`, { state: value });
        }}
        iconOnly
      >
        <Icon>edit</Icon>
      </MDButton>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="error"
        iconOnly
        onClick={() => {
          handleDelete(value.id);
        }}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );
  categories?.result?.data?.map((value) => {
    console.log("value", value);
    rows.push({
      name: <TableRowField align="left" title={value.name} />,
      places: <PlacesRow align="left" places={value.places} />,
      action: <ActionField align="left" value={value} />,
    });
  });

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("/admin-app/wards/" + id);
      openAlertBox("success", "Delete Success", "You have successfully deleted the Order Form");
      queryClient.invalidateQueries("wards");
    } catch (error) {
      openAlertBox("error", " Something went wrong", error?.message || "Something went wrong");
      console.log(error);
    }
  };
  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this Order Form', id);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  Wards
                </MDTypography>
                <NavLink key={"wards"} to={"/wards/create"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {!isError && categories?.result && (
          <Pagination
            count={categories?.result?.pagination?.last_page}
            color="info"
            sx={{ float: "right" }}
            defaultPage={paginationPage}
            variant="outlined"
            onChange={(_, page) => setPaginationPage(page)}
          />
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Wards;
