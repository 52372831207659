import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Modal from "components/Modal/modal";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

import { useQuery } from "react-query";
import { getCurrentDate } from "lib/helper";
import { getData } from "lib/fetcher";
import MDButton from "components/MDButton";
import { Divider, TextField, Typography } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState, useTransition } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Avatar from "assets/images/team-4.jpg";
import { Link } from "react-router-dom";
import { ChangeMuiDate } from "lib/helper";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [fiveFreeRiders, setFiveFreeRider] = useState([]);

  const {
    data: foodOrderCount,
    isLoading,
    error,
    isError,
  } = useQuery(
    ["food-order-count", { selectedDate }],
    async () => await getData(`/admin-app/get-food-order-count?date=${selectedDate}`),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log(foodOrderCount);


  const { data: percelDeliveryCount } = useQuery(
    ["percel-delivery-count", { selectedDate }],
    () => getData(`/admin-app/get-parcel-delivery-count?date=${selectedDate}`),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: officeOrdercount } = useQuery(
    ["get-give-way-order-count", { selectedDate }],
    async () => await getData(`/admin-app/get-give-way-order-count?date=${selectedDate}`),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: riders, isSuccess } = useQuery(
    ["delivery-men"],
    () => getData("admin-app/today-current-free-delivery-men"),
    {
      refetchOnWindowFocus: false,
    }
  );

  // useEffect(() => {
  //   getFreeDelivery();
  // }, [riders]);
  // const getFreeDelivery = () => {
  //   if (riders?.result?.data) {
  //     console.log(riders);
  //     const filteredArray = riders?.result?.data?.filter(
  //       (obj) => obj.status === "DELIVERY_IS_FREE"
  //     );
  //     console.log(filteredArray);
  //     const fiveFreeRiders = filteredArray.slice(-10);
  //     setFiveFreeRider(fiveFreeRiders);
  //   }
  // };

  const handleDateChange = (e) => {
    setSelectedDate(ChangeMuiDate(e));
  };

  const { t } = useTranslation();

  const DeliMan = ({ id, name, number, status, phone }) => (
    <MDBox
      display="flex"
      marginRight="1rem"
      padding={1}
      alignItems="center"
      sx={{ borderRadius: "20px", backgroundColor: "#fff", cursor: "pointer" }}
      lineHeight={1}
    >
      <MDBox>
        <MDAvatar src={Avatar} name={name} size="sm" />
        <MDTypography display="block" color="primary" variant="button" fontWeight="medium">
          {status === "DELIVERY_IS_FREE" ? t("free") : ""}
        </MDTypography>
      </MDBox>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" color="inherit" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption" color="inherit"  display="block">
          {number}
        </MDTypography>
        <MDTypography variant="caption" color="inherit">{phone}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox sx={{ marginBottom: "3rem" }} display="flex" justifyContent="space-between">
          <Link to={"/office-order/create"} color="primary">
            {" "}
            <MDButton color="primary">+ {t("add_office_order")}</MDButton>
          </Link>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              sx={{ m: 2 }}
              value={selectedDate ? selectedDate : getCurrentDate()}
              label={t("date")}
              inputFormat="YYYY-MM-DD"
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} sx={{ mx: 2 }} size="small" />}
            />
          </LocalizationProvider>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Link to={"/office-order"}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title={t("office_orders")}
                  count={officeOrdercount?.result ?? 0}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Link to={"/food-order"}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title={t("food_orders")}
                  count={foodOrderCount?.result?.order_with_deli_man_count ?? 0}
                  percentage={{
                    color: "error",
                    amount: foodOrderCount?.result?.order_with_without_deli_man_count ?? 0,
                    label: " orders without delivery man",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Link to={"/parcel-delivery"}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title={t("parcel_orders")}
                  count={percelDeliveryCount?.result?.order_with_deli_man_count ?? 0}
                  percentage={{
                    color: "error",
                    amount: percelDeliveryCount?.result?.order_without_deli_man_count ?? 0,
                    label: " orders without delivery man",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Divider />

      <MDBox>
        <MDBox display="flex" marginBottom="1rem" justifyContent="space-between">
          <Typography variant="h5">{t("riders")}</Typography>
          <Link to={"/delivery-men"}>
            <Typography color="primary" variant="h5">
              {t("see_all")}
            </Typography>
          </Link>
        </MDBox>
        <Grid padding={5} container spacing={5} style={{ overflowX: "scroll" }}>
          {riders?.result?.data?.length &&
            riders?.result?.data?.map((rider) => (
              <DeliMan
                id={rider.id}
                name={rider.name}
                number={rider.number}
                status={rider.status}
                phone={rider.phone}
              />
            ))}
        </Grid>
      </MDBox>
      <Divider />
      <MDBox sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}>
        <Link to={"/map"}>
          <Grid container>
            <Grid item sm={6} md={6} lg={6} padding={5}>
              <Typography>{t("check_riders_on")}</Typography>
              <Typography color="primary">{t("map")}</Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              lg={6}
              sx={{ backgroundColor: "#04B431", borderRadius: "0 10px 10px 0" }}
            ></Grid>
          </Grid>
        </Link>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
