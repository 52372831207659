import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Switch,
  Typography,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect } from "react";

import MDInput from "components/MDInput";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import RestaruantLayout from "layouts/RestaruantManagement/RestaruantLayout";
import { useTranslation } from "react-i18next";

const initalData = {
  name: "",
};
function WardsForm() {
  const { state } = useLocation();
  console.log(state, "state");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState(state ? { name: state?.name } : initalData);
  const { openAlertBox } = useAlertBox();

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const queryClient = useQueryClient();
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/wards/${state?.id}`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated the Order method");
      queryClient.invalidateQueries("wards");
      navigate("/wards");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/wards`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully created the Order Form");
      queryClient.invalidateQueries("wards");
      navigate("/wards");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    if (state) {
      updateMutate({
        name: inputValue.name,
        _method: "PUT",
      });
    } else {
      createMutate({
        name: inputValue.name,
      });
    }
  };
  const handleCheckInput = (event) => {
    const { name, checked } = event.target;

    setInputValue({ ...inputValue, [name]: checked });
  };
  const { t } = useTranslation();
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state ? `${t("ward")} ${t("edit")}` : `${t("ward")} ${t("add")}`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ p: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.name}
                onChange={inputChangeHandler}
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Name")}
                name="name"
              />
            </Box>
          </Grid>
        </Grid>
        <MDBox mb={1} sx={{ float: "right" }}>
          <MDButton
            disabled={updateLoading || createLoading}
            onClick={handleSubmit}
            variant="gradient"
            color="primary"
            fullWidth
          >
            {updateLoading || createLoading ? "Submiting..." : t("Submit")}
          </MDButton>
        </MDBox>
      </Card>
    </RestaruantLayout>
  );
}
export default WardsForm;
