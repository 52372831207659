import React, { useEffect } from "react";

import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";

const MDSearch = ({ setSearch }) => {
  // State to keep track of the search query
  const [searchQuery, setSearchQuery] = React.useState("");
  const location = useLocation();
  useEffect(() => {
    setSearchQuery("");
  }, [location.pathname]);

  // Function to perform the search (replace this with your actual search implementation)

  // Debounced version of the search function with a delay of 500 milliseconds
  const debouncedSearch = React.useMemo(() => debounce((query) => setSearch(query), 500), []);

  // Handle changes in the search input
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    debouncedSearch(value); // This will trigger the debounced search
  };

  return (
    <TextField
      label="Search"
      value={searchQuery}
      onChange={handleSearchChange}
      variant="outlined"
    />
  );
};

export default MDSearch;
