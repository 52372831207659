import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authed: true,
  info: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    controlAuth: (state, action) => {
      state.authed = action.payload.auth;
      state.info = action.payload.info;
    },
  },
});

export const { controlAuth } = authSlice.actions;

export default authSlice.reducer;
