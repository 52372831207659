import { styled } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "lib/firebase";
import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Container = styled("section")`
  width: 80%;
  margin-inline: auto;
  display: grid;
  padding-bottom: 4rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  .post {
    width: 100%;
    height: 22rem;
    overflow: hidden;
    position: relative;
    transition: all ease 300ms;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform ease 300ms;
    }
  }
  .post::after:not(.active) {
    display: none;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 95%);
  }
  .post:hover::after {
    display: block;
  }
  .post:hover:not(.active) img {
    transform: scale(1.1);
  }
  .post:hover .text {
    transform: translateY(-1rem);
  }
  .post-2 {
    grid-column: 2 / 4;
  }
  .post-3 {
    grid-column: 1 / 3;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
    .post {
      height: 10rem;
    }

    .post-2 {
      grid-column: 2 / 3;
    }
    .post-3 {
      grid-column: 1 / 2;
    }
    .post-3 {
      grid-column: 1 / 3;
    }
  }
  .text {
    position: absolute;
    z-index: 2;
    left: 1rem;
    bottom: 0rem;
    color: white;
    transform: translateY(1.5rem);
    transition: transform ease 300ms;
    button {
      margin-top: 1rem;
      border: none;
      background: none;
      color: white;
      font-size: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .chevron {
      transition: transform ease 200ms;
    }
    button:hover .chevron {
      transform: translateX(0.5rem);
    }
  }
  .backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: fill;
    z-index: 3;
  }
  z-index: 3;
  .active {
    overflow: visible;
    width: 100%;
    max-width: 30rem;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    color: white;
  }
  .active .text {
    display: none;
  }
  .active-text {
    text-align: center;
  }
`;

export default function NewsAndBlogs() {
  const [posts, setPosts] = useState([]);
  const [selected, setSelected] = useState(null);
  async function getposts() {
    try {
      const q = query(collection(db, "posts"), orderBy("order", "asc"));
      const res = await getDocs(q);
      const arr = [];
      res.forEach((doc) => arr.push(doc.data()));
      setPosts(arr);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getposts();
  }, []);
  return (
    <>
      <h2
        style={{
          textAlign: "center",
          marginBlock: "2rem",
          textDecoration: "underline",
          textUnderlineOffset: "0.5rem",
          textDecorationColor: "#17aa6b",
        }}
      >
        NEWS
      </h2>
      <Container>
        {selected && <div className="backdrop" onClick={() => setSelected(null)}></div>}

        {posts?.map((item) => (
          <Post key={item.id} item={item} selected={selected} set={(id) => setSelected(id)} />
        ))}
      </Container>
    </>
  );
}

function Post({ item, selected, set }) {
  return (
    <article
      key={item.id}
      onClick={(e) => e.stopPropagation()}
      className={`${item.id} post ${selected === item.id && "active"}`}
    >
      <img src={item.url} alt="" className="img" />
      <div className="text">
        <p>{item.title}</p>
        <button onClick={() => set(item.id)}>
          See more <ChevronRightIcon sx={{ scale: "2", color: "#17aa6b" }} className="chevron" />
        </button>
      </div>
      <div className="active-text">
        <h3>{item.title}</h3>
        <p>{item.content}</p>
      </div>
    </article>
  );
}
