import MDSnackbar from "components/MDSnackbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAlertBox from "lib/hooks/useAlertBox";
import { controlAuth } from "lib/store/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmBox from "components/Utils/ConfirmBox";

export default function Container({ children }) {
  const { closeAlertBox } = useAlertBox();
  const { alertBox } = useSelector((state) => state.generalReducer);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function checkUserExists() {
    const token = localStorage.getItem("tkn");
    const info = localStorage.getItem("urs");
    if (pathname === "/") {
      navigate("/");
    } else if (token && info) {
      dispatch(controlAuth({ auth: true, info }));
    } else {
      dispatch(controlAuth({ auth: false, info: {} }));
      navigate("/authentication/sign-in");
    }
  }

  useEffect(() => {
    checkUserExists();
  }, []);

  return (
    <>
      <div>{children}</div>
      <ConfirmBox />
      <MDSnackbar
        color={alertBox?.type}
        icon={alertBox?.type === "error" ? "warning" : "check"}
        content={alertBox?.content}
        title={alertBox?.title}
        open={alertBox?.show}
        onClose={closeAlertBox}
        close={closeAlertBox}
        style={{ right: "1rem" }}
        bgWhite
      />
    </>
  );
}
