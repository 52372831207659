import { Box, Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import MDInput from "components/MDInput";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { postData } from "lib/fetcher";
import useAlertBox from "lib/hooks/useAlertBox";
import RestaruantLayout from "layouts/RestaruantManagement/RestaruantLayout";
import { useTranslation } from "react-i18next";

const initalData = {
  title: "",
  amount: "",
};
function ExpenseForm() {
  const { state } = useLocation();
  console.log(state, "state");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState(state ? { ...state } : initalData);
  const theme = useTheme();
  const { openAlertBox } = useAlertBox();

  // const [, setImageUrl] = React.useState(state ? state?.image_url : "");
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const queryClient = useQueryClient();
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/office-expenses/${state?.id}`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully updated ");
      queryClient.invalidateQueries("expense");
      navigate("/expense");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const { mutate: createMutate, isLoading: createLoading } = useMutation({
    mutationFn: (body) => postData(`/admin-app/office-expenses`, body),
    onSuccess: () => {
      openAlertBox("success", " Success", "You have successfully created ");
      queryClient.invalidateQueries("expense");
      navigate("/expense");
    },
    onError: (error) => {
      console.log(error, "error");
      openAlertBox(
        "error",
        " Something went wrong",
        error?.validation ? error?.validation[0] : error?.message || "Something went wrong"
      );
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue);

    if (state) {
      updateMutate({
        title: inputValue.title,
        amount: inputValue.amount,
        _method: "PUT",
      });
    } else {
      createMutate({
        title: inputValue.title,
        amount: inputValue.amount,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <RestaruantLayout>
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {state ? `${t("Expense")} ${t("edit")}` : `${t("Expense")} ${t("add")}`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ p: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.title}
                onChange={inputChangeHandler}
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Title")}
                name="title"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}></Box>
          </Grid>
          <Grid item xs={4} sx={{ p: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center" }}>
              <MDInput
                value={inputValue.amount}
                onChange={inputChangeHandler}
                sx={{ width: "100%" }}
                variant="outlined"
                label={t("Amount")}
                type="number"
                name="amount"
              />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "20px" }}></Box>
          </Grid>
        </Grid>
        <MDBox mb={1} sx={{ float: "right" }}>
          <MDButton
            disabled={updateLoading || createLoading}
            onClick={handleSubmit}
            variant="gradient"
            color="primary"
            fullWidth
          >
            {updateLoading || createLoading ? "Submiting..." : t("submit")}
          </MDButton>
        </MDBox>
      </Card>
    </RestaruantLayout>
  );
}
export default ExpenseForm;
