import axios from "axios";

axios.interceptors.request.use(function (config) {
  let token = localStorage.getItem("tkn");
  // config.headers.Authorization = `Bearer ${token}`;
  config.headers.Authorization = "Bearer " + token;
  config.baseURL = "https://api.myenda.com/api/";
  return config;
});
const message = {
  111: "Success",
  222: "Invalid email or password: The email and/or password you entered is incorrect.",
  333: "Unauthorized access: You do not have the necessary permissions to access this resource.",
  444: "Invalid input: The input you provided is not valid. Please check your input and try again.",
  555: "Server Error",
  666: "Token not found",
};

function throwError(error) {
  if (error.response && error.response.data) {
    const { status_code } = error.response.data;
    if (status_code === 333) {
      localStorage.removeItem("tkn");
      localStorage.removeItem("urs");
      // window.location.href = "/authentication/sign-in";
    }
    if (status_code === 444) {
      throw new Error(
        JSON.stringify({ error: true, message: error.response.data.validation_errors[0] })
      );
    }
    throw new Error(JSON.stringify({ error: true, message: message[status_code] }));
  } else
    throw new Error(
      JSON.stringify({
        error: true,
        message: "Unexpected Error. Check your connection and try again",
      })
    );
}

export async function getData(endpoint) {
  try {
    const { data } = await axios.get(endpoint);
    return data;
  } catch (error) {
    throwError(error);
  }
}
export async function postData(endpoint, body) {
  try {
    const { data } = await axios.post(endpoint, body);
    return data;
  } catch (error) {
    if (error.response && error.response.data) {
      const { status_code } = error.response.data;
      throw {
        error: true,
        message: message[status_code],
        validation: error.response.data.validation_errors,
      };
    } else throw { error: true, message: error.response || "Unexpected Error. Try again later" };
  }
}
export async function postImageData(endpoint, body) {
  try {
    const { data } = await axios.post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data) {
      const { status_code } = error.response.data;
      throw {
        error: true,
        message: message[status_code],
        validation: error.response.data.validation_errors,
      };
    } else throw { error: true, message: error.response || "Unexpected Error. Try again later" };
  }
}
export async function postImageDataRes(endpoint, body) {
  try {
    const { data } = await axios.post(`${endpoint}${body.id}`, body.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data) {
      const { status_code } = error.response.data;
      throw {
        error: true,
        message: message[status_code],
        validation: error.response.data.validation_errors,
      };
    } else throw { error: true, message: error.response || "Unexpected Error. Try again later" };
  }
}
export async function updateData(endpoint, body) {
  try {
    const { data } = await axios.put(endpoint, body);
    return data;
  } catch (error) {
    throwError(error);
  }
}

export async function deleteData(endpoint) {
  try {
    const { data } = await axios.delete(endpoint);
    return { data };
  } catch (error) {
    throwError(error);
  }
}
