import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import MDButton from "components/MDButton";
import { Box, Icon, Pagination, TextField } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getData } from "lib/fetcher";
import { getCurrentDate } from "lib/helper";
import useConfirmBox from "lib/hooks/useConfirmBox";
import useAlertBox from "lib/hooks/useAlertBox";
import { deleteData } from "lib/fetcher";
import { useState } from "react";
import { formatDate } from "lib/helper";
import MDInput from "components/MDInput";
import TableLoadingSkeleton from "components/Utils/TableLoadingSkeleton";
import { useTranslation } from "react-i18next";
function Places() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openConfirmBox } = useConfirmBox();
  const { openAlertBox } = useAlertBox();
  const [paginationPage, setPaginationPage] = useState(1);
  const { data, isLoading, error, isError } = useQuery(
    ["places", paginationPage || 1],
    () => getData(`/admin-app/places?page=${paginationPage || 1}`),
    {
      refetchOnWindowFocus: false,
    }
  );

  const DeleteApiHandler = async (id) => {
    try {
      await deleteData("/admin-app/places/" + id);
      queryClient.invalidateQueries("places");
      openAlertBox("success", "Delete Success", "You have successfully Delete out");
    } catch (error) {
      openAlertBox("error", "Login Failed", error.message);
    }
  };

  const handleDelete = (id) => {
    openConfirmBox(DeleteApiHandler, 'Clik "Confirm" to delete this give way.', id);
  };

  const columns = [
    { Header: "name", accessor: "name", align: "left" },
    { Header: "latitude", accessor: "latitude", align: "left" },
    { Header: "longitude", accessor: "longitude", align: "left" },
    { Header: "detail", accessor: "detail", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [];

  const TableRowField = ({ title }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  );

  const ActionField = ({ value }) => (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="warning"
        onClick={() => {
          navigate(`/places/update`, { state: value });
        }}
        iconOnly
      >
        <Icon>edit</Icon>
      </MDButton>

      <MDButton
        style={{ marginRight: "2px", marginLeft: "2px" }}
        variant="gradient"
        color="error"
        iconOnly
        onClick={() => {
          handleDelete(value.id);
        }}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );

  data?.result?.data.map((value) => {
    rows.push({
      name: <TableRowField title={value.name} />,
      latitude: <TableRowField title={value.latitude} />,
      longitude: <TableRowField title={value.longitude} />,
      detail: <TableRowField title={value.detail} />,
      action: <ActionField value={value} />,
    });
  });
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDTypography variant="h6" color="white">
                  {t("places")}
                </MDTypography>
                <NavLink key={"places"} to={"/places/create"}>
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;{t("add")}
                  </MDButton>
                </NavLink>
              </MDBox>
              <MDBox pt={3}>
                {!isError && (
                  <DataTable
                    table={{
                      columns,
                      rows: isLoading ? TableLoadingSkeleton(columns) : rows,
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, float: "right" }}>
          {!isError && data?.result && (
            <Pagination
              count={data?.result?.pagination?.last_page}
              color="info"
              sx={{ float: "right" }}
              defaultPage={paginationPage}
              variant="outlined"
              onChange={(_, page) => setPaginationPage(page)}
            />
          )}
        </Box>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Places;
