// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Avatar, Box, Button, Card, Grid, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
// import MDInput from "components/MDInput";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MDButton from "components/MDButton";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
// import { useMutation } from "react-query";
// import { getData, updateData } from "lib/fetcher";
import MDBadge from "components/MDBadge";

const DeliveryManUsageDetail = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log("state", state)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #fff',
        borderRadius: "20px",
        boxShadow: 10,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = useState()
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCardClick = (w) => {
        setModalData(w)
        setOpen(true);
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Typography variant="h6" sx={{ width: "150px" }}>
                                Types
                            </Typography>
                            <Typography variant="h6" sx={{ width: "30px" }}>
                                -
                            </Typography>
                            <Typography variant="h6" sx={{ width: "250px" }}>
                                {modalData?.type}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Typography variant="h6" sx={{ width: "150px" }}>
                                Order Number
                            </Typography>
                            <Typography variant="h6" sx={{ width: "30px" }}>
                                -
                            </Typography>
                            <Typography variant="h6" sx={{ width: "250px" }}>
                                {modalData?.order_number}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Typography variant="h6" sx={{ width: "150px" }}>
                                Order Date
                            </Typography>
                            <Typography variant="h6" sx={{ width: "30px" }}>
                                -
                            </Typography>
                            <Typography variant="h6" sx={{ width: "250px" }}>
                                {modalData?.order_date}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Button sx={{ backgroundColor: "#171716", color: "white !important", marginTop: "10px", ":hover": { backgroundColor: "#171716", color: "white !important", } }} onClick={() => setOpen(false)}>Close</Button>
                        </Box>
                    </Box>
                </Modal>

                <Grid container spacing={2} >
                    <Grid item xs={5}  >
                        <Card sx={{ p: 3, backgroundColor: "#f0f7f7" }} >
                            <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                                <Avatar
                                    alt="Remy Sharp"
                                    src="/static/images/avatar/1.jpg"
                                    sx={{ width: 100, height: 100, marginBottom: "15px" }}
                                />
                            </Stack>

                            <Grid container spacing={6} >
                                <Grid item xs={12} md={12} lg={12} >
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px", }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Name{" "}
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px" }}>
                                            {state?.delivery_man?.name}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Phone{" "}
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px" }}>
                                            +{state?.delivery_man?.phone}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Salary
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px" }}>
                                            {state?.delivery_man?.base_salary} MMK
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Email
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px" }}>
                                            {state?.delivery_man?.email}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Salary By Way
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px" }}>
                                            {state?.delivery_man?.salary_by_way} MMK
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "25px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Status
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px" }}>
                                            {(
                                                <MDBadge badgeContent={state?.delivery_man?.status} variant="contained" container />
                                            )}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={7} sx={{ p: 3 }}>
                        <Card sx={{ p: 3, backgroundColor: "#f0f7f7" }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" textAlign="center">
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    Recent Way Record
                                </Typography>
                                <NavLink key={"way-records"} to={"/way-records"}>
                                    <MDButton sx={{ mb: 4 }} variant="gradient" color="dark">
                                        All Records
                                    </MDButton>
                                </NavLink>
                            </Box>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12} lg={12} >
                                    {
                                        state?.recent_way_records?.map((way) => (
                                            <Card key={way?.id} sx={{ margin: "10px", cursor: "pointer", backgroundColor: "#ebf5ee" }} onClick={() => handleCardClick(way)}>
                                                <Box sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                                    <Typography variant="h6" sx={{ width: "150px" }}>
                                                        {way?.type}
                                                    </Typography>
                                                    <Typography variant="h6" sx={{ width: "30px" }}>
                                                        -
                                                    </Typography>
                                                    <Typography variant="h6" sx={{ width: "250px" }}>
                                                        {way?.order_date}
                                                    </Typography>
                                                    <ArrowForwardIosIcon color="white" sx={{ backgroundColor: "green", borderRadius: "50px", fontSize: "15px !important" }} />
                                                </Box>
                                            </Card>
                                        )

                                        )
                                    }
                                </Grid>

                            </Grid>
                        </Card>

                        <Card sx={{ p: 3, marginTop: "10px", backgroundColor: "#f0f7f7" }}>
                            <Box display="flex" justifyContent="space-around" alignItems="center" textAlign="center">
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    Usage
                                </Typography>
                                <MDButton sx={{ mb: 4 }} variant="gradient" color="dark" onClick={() => navigate(`/delivery-men-usage`, { state: state })}>
                                    All Usage
                                </MDButton>
                            </Box>

                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12} lg={12} >
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "100px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Petroleum
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px", color: "red" }}>
                                            {state?.delivery_man?.petroleum_usage} MMK
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "100px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Phone Bill
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px", color: "green" }}>
                                            {state?.delivery_man?.phone_bill_usage} MMK
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginBottom: "20px", marginLeft: "100px" }} justifyContent="center" alignItems="center">
                                        <Typography variant="h6" sx={{ width: "150px" }}>
                                            Pre Advanced
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "30px" }}>
                                            -
                                        </Typography>
                                        <Typography variant="h6" sx={{ width: "250px", color: "red" }}>
                                            {state?.delivery_man?.pre_advance_usage} MMK
                                        </Typography>
                                    </Box>
                                    
                                </Grid>

                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default DeliveryManUsageDetail