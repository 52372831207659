import * as React from "react";
import { styled, alpha } from "@mui/material/styles";

import Logo from "../../assets/images/logos/myenda-logo.png";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";

const Search = styled("div")`
  position: "relative";
  border-radius: 0.25rem;
  width: 100%;
  max-width: 10rem;
  border: solid 1px #333;
  display: flex;
  padding-block: 0.2rem;
  align-items: center;
  transition: all 300ms ease;
  :focus-within {
    max-width: 15rem;
  }
  @media screen and (max-width: 1024px) {
    transition: all 500ms ease;
    :focus-within {
      max-width: 15rem;
    }
    :not(:focus-within) {
      max-width: 2.25rem;
    }
  }
`;

const Navlink = styled(Typography)`
  color: #333;
  font-size: 1rem;
  :hover {
    color: #17aa6b;
  }
  @media screen and (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const LinkContainer = styled(Stack)`
  gap: 1rem;
  @media screen and (max-width: 1024px) {
    gap: 0.25rem;
  }
`;

export default function Navbar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="white">
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <img
              src={Logo}
              alt="Myenda"
              style={{ height: "100%", maxWidth: "6rem", marginTop: "0.5rem" }}
            />
          </Typography>
          <LinkContainer flexDirection="row" gap="1rem">
            <Link to="/">
              <Navlink>HOME</Navlink>
            </Link>
            <Link to="/">
              <Navlink>ABOUT</Navlink>
            </Link>
          </LinkContainer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
